import React, { FC } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import Icon from "../Icon";

type TProps = {
  className: string;
  onSubmit: () => void;
  placeholder: string;
  value: string;
  setValue: (value: string) => void;
  type: string;
  name: string;
  icon?: string;
  readOnly?: boolean;
}
const Form: FC<TProps> = ({
  className,
  onSubmit,
  placeholder,
  value,
  setValue,
  type,
  name,
  icon,
  readOnly,
}) => {
  return (
    <form className={cn(className, styles.form)} action="" onSubmit={onSubmit}>
      <input
        readOnly={readOnly}
        className={styles.input}
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        name={name}
        placeholder={placeholder}
        required
      />
      {icon &&
        <button className={styles.result}>
          <Icon name={icon} size="24" />
        </button>
      }
    </form>
  );
};

export default Form;
