import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { gaId } from "../../constants";

declare global {
  interface Window {
    dataLayer: any[];
    sc_project: string;
    sc_invisible: number;
    sc_security: string;
    gtag?: (...args: any[]) => void;
    google: any;
  }
}

const pushToGoogleDataLayer = function gtag(...unusedArgs: unknown[]) {
  window.dataLayer.push(arguments);
};

function setupSendEvent() {
  window.gtag = function gtag() {
    window.dataLayer.push(arguments);
  };
}

function initGoogleMetrics(id: string) {
  window.dataLayer = window.dataLayer || [];
  pushToGoogleDataLayer("js", new Date());
  pushToGoogleDataLayer("config", id);
  setupSendEvent();
}

const GoogleMetrics = () => {
  useEffect(() => {
    if (gaId) {
      initGoogleMetrics(gaId);
    }
  }, []);

  if (!gaId) return null;

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gaId || ""}`}
      />
    </Helmet>
  );
};
export default GoogleMetrics;
