import { FC, useState } from "react";
import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../types";
import { Button, Modal } from "antd";
import cn from "classnames";
import "./SubscriptionSettingsModal.sass";
import formatMessage from "../../utils/formatMessage";
import { ReactComponent as ButtonClose } from "../../resources/images/close_transparent_border.svg";
import { FEATURE_UPDATE_PAYMENT } from "../../constants";

type TProps = {
  visible: boolean;
  onClose: VoidFunction;
  disableCancel?: boolean;
  disableUpdate?: boolean;
  disableRetry?: boolean;
  onCancelSubscription: VoidFunction;
  onUpdateSubscription: VoidFunction;
} & IBaseComponentProps;

// const SUBSCRIPTION_SETTINGS_MODAL_
const SUBSCRIPTION_SETTINGS_MODAL_SUBSCRIPTION_SETTINGS = {
  id: "subscription-settings-modal.subscription-settings",
};
const SUBSCRIPTION_SETTINGS_MODAL_UPDATE_PAYMENT_DETAILS = {
  id: "subscription-settings-modal.update-payment-details",
};
const SUBSCRIPTION_SETTINGS_MODAL_RETRY_SAME_CARD = {
  id: "subscription-settings-modal.retry-with-the-same-card",
};
const SUBSCRIPTION_SETTINGS_MODAL_CANCEL_SUBSCRIPTION = {
  id: "subscription-settings-modal.cancel-subscription",
};
const SUBSCRIPTION_SETTINGS_MODAL_CANCEL_SCHEDULED_PAYMENT = {
  id: "subscription-settings-modal.cancel-scheduled-payment",
};
const SUBSCRIPTION_SETTINGS_MODAL_YOU_WILL_PERMANENTLY = {
  id: "subscription-settings-modal.you-will-permanently",
};
const SUBSCRIPTION_SETTINGS_MODAL_CANCEL_PAYMENT = {
  id: "subscription-settings-modal.cancel-payment",
};

const SubscriptionSettingsModal: FC<TProps> = ({
  intl,
  onClose,
  onCancelSubscription,
  onUpdateSubscription,
  visible,
  className,
  disableCancel,
  disableUpdate,
  disableRetry,
}) => {
  const [stage, setStage] = useState<"cancellation" | "settings">("settings");

  const cancelSubscriptionClick = () => {
    setStage("cancellation");
  };
  const cancelPaymentClick = () => {
    setStage("settings");
    onCancelSubscription();
    onClose();
  };
  const updatePaymentClick = () => {
    setStage("settings");
    onUpdateSubscription();
    onClose();
  };

  const closeModal = () => {
    onClose();
    setStage("settings");
  };

  return (
    <Modal
      className={cn("subscription-settings-modal", className)}
      transitionName={""}
      open={visible}
      closeIcon={
        <div className="subscription-settings-modal__close">
          {/* <img src={buttonClose} alt="close" /> */}
          <ButtonClose className="subscription-settings-modal__content_background" />
        </div>
      }
      onCancel={closeModal}
      destroyOnClose
      footer={[]}
      rootClassName="subscription-settings-modal__root"
    >
      <div className="subscription-settings-modal__content">
        {stage === "settings" ? (
          <>
            <h2 className="subscription-settings-modal__content_title">
              {formatMessage(
                intl,
                SUBSCRIPTION_SETTINGS_MODAL_SUBSCRIPTION_SETTINGS
              )}
            </h2>
            <div className="subscription-settings-modal__content_actions">
              {FEATURE_UPDATE_PAYMENT === "true" && (
                <>
                  <Button
                    className="subscription-settings-modal__content_actions_button"
                    disabled={disableRetry}
                    onClick={updatePaymentClick}
                  >
                    {formatMessage(
                      intl,
                      SUBSCRIPTION_SETTINGS_MODAL_RETRY_SAME_CARD
                    )}
                  </Button>
                  <Button
                    className="subscription-settings-modal__content_actions_button"
                    disabled={disableUpdate}
                    onClick={updatePaymentClick}
                  >
                    {formatMessage(
                      intl,
                      SUBSCRIPTION_SETTINGS_MODAL_UPDATE_PAYMENT_DETAILS
                    )}
                  </Button>
                </>
              )}
              <Button
                className="subscription-settings-modal__content_actions_button"
                onClick={cancelSubscriptionClick}
                disabled={disableCancel}
              >
                {formatMessage(
                  intl,
                  SUBSCRIPTION_SETTINGS_MODAL_CANCEL_SUBSCRIPTION
                )}
              </Button>
            </div>
          </>
        ) : (
          <>
            <h2 className="subscription-settings-modal__content_title">
              {formatMessage(
                intl,
                SUBSCRIPTION_SETTINGS_MODAL_CANCEL_SCHEDULED_PAYMENT
              )}
            </h2>
            <p className="subscription-settings-modal__content_text">
              {formatMessage(
                intl,
                SUBSCRIPTION_SETTINGS_MODAL_YOU_WILL_PERMANENTLY
              )}
            </p>
            <div className="subscription-settings-modal__content_actions">
              <Button
                className="subscription-settings-modal__redbutton"
                onClick={cancelPaymentClick}
              >
                {formatMessage(
                  intl,
                  SUBSCRIPTION_SETTINGS_MODAL_CANCEL_PAYMENT
                )}
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default injectIntl(SubscriptionSettingsModal);
