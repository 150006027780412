import React, { PropsWithChildren, useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { Outlet, useLocation } from "react-router-dom";
import withUnautorizedRedirect from "../../../hocs/withUnautorizedRedirect";
import DashboardInformationWindow from "../../../components/DashboardInformationWindow";
import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../../types";
import formatMessage from "../../../utils/formatMessage";
import useSubscriptionBadge from "../../../hooks/useSubscriptionBadge";
import {
  useGetCurrentPlanQuery,
  useGetCurrentSubscriptionQuery,
} from "../../../api/reg-service.api";
import SurveyBadge from "../../../components/SurveyBadge";
import usePlanType, { EPlanType } from "../../../hooks/usePlanType";
import useIntercomChat from "../../../hooks/useIntercomChat";
import useGetLandingLinkByAuthorizedUser from "../../../hooks/useGetLandingLinkByAuthorizedUser";

interface IProps extends IBaseComponentProps {
  wide?: boolean;
}

const Page: React.FC<PropsWithChildren<IProps>> = ({ wide, intl }) => {
  const [visible, setVisible] = useState(false);
  useIntercomChat();
  const { landingId } = useGetLandingLinkByAuthorizedUser();

  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [location]);

  const currentPlanQuery = useGetCurrentPlanQuery((() => {})(), {
    pollingInterval: 60000,
  });
  const currentSubscriptionQuery = useGetCurrentSubscriptionQuery(
    (() => {})(),
    {
      pollingInterval: 60000,
    }
  );
  const badge = useSubscriptionBadge(
    currentPlanQuery,
    currentSubscriptionQuery
  );

  const planType = usePlanType(currentPlanQuery.data);

  return (
    <>
      <div className={styles.page}>
        <Sidebar
          className={cn(styles.sidebar, {
            [styles.visible]: visible,
          })}
          onClose={() => setVisible(false)}
        />
        <Header onOpen={() => setVisible(true)} />
        <div className={styles.inner}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >
            {badge && (
              <DashboardInformationWindow
                className="dashboard__info_block"
                text={formatMessage(intl, badge.message)}
                type={badge.type}
              />
            )}
            {planType === EPlanType.DEFAULT &&
              landingId === 1 && ( // only for landing without discount
                <SurveyBadge className={styles.survey} />
              )}
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default withUnautorizedRedirect(injectIntl(Page));
