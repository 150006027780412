/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from "react"
import "./ApiKeys.sass"
import { injectIntl } from "react-intl"
import { IBaseComponentProps } from "../../types"
import Card from "../../ui-kit/components/Card"
import cn from "classnames";
// import Tooltip from "../../ui-kit/components/Tooltip"
import Form from "../../ui-kit/components/Form"
import apiKeyOptions from "../../resources/images/api-key-options.svg"
import Dropdown from "../../ui-kit/components/Dropdown"
import { useCreateApiKeyMutation, useDeleteApiKeyMutation, useGetApiKeysQuery } from "../../api/reg-service.api"

type TProps = {

} & IBaseComponentProps

export type TDropdownEvent = "Delete";

const options = [
  "Delete",
];

// const mapOptionToPeriod: Record<string, TDropdownEvent> = {
//   delete: "delete",
// };

const maDropdownValueOption: Record<TDropdownEvent, TDropdownEvent> = {
  Delete: "Delete",
};

const ApiKeys: FC<TProps> = ({ intl, className }) => {
  const { data: apiKeys } = useGetApiKeysQuery();
  const [createApiKey, { isLoading }] = useCreateApiKeyMutation();
  const [deleteApiKey] = useDeleteApiKeyMutation();

  const createKey = () => {
    return createApiKey();
  };
  const deleteKey = (key: string) => {
    return deleteApiKey({
      key,
    });
  };

  const [search, setSearch] = useState("");
  const handleSubmit = () => {
    alert();
  };
  const [dropdownValue, setDropdownValue] = useState<TDropdownEvent>("Delete");

  const dropdownEvent = (action: string, key: string) => {
    if (action === "Delete") deleteKey(key)
  }

  return (
    <Card className={cn("api-keys", className)} title="API Keys"
      classTitle="title-purple"
      head={!apiKeys?.length ?
        <button
          onClick={createKey}
          className={cn("button", "api-keys__button")}
        // onClick={() => setVisibleModal(true)}
        >
          Add New Key
        </button> : null
      }>
      {apiKeys?.length ? <div>
        <div className="api-keys__title">
          Your API key
          {/* <Tooltip
            className="api-keys__tooltip"
            title={"title"}
            icon="info"
            place="top"
          /> */}
        </div>
        {apiKeys.map((el: string, index: number) => (
          <div style={{ display: "flex", gap: "12px" }}>
            <Form
              readOnly
              className={"api-keys__form"}
              value={el}
              setValue={setSearch}
              onSubmit={() => handleSubmit()}
              placeholder=""
              type="text"
              name="yourApiKey"
            />
            <Dropdown
              className="api-keys__options"
              // classDropdownHead={styles.dropdownHead}
              value={maDropdownValueOption[dropdownValue]}
              setValue={(value) => {
                console.log(value)
                // const nextPeriod = mapOptionToPeriod[value];
                // if (nextPeriod) {
                //   setPeriod(nextPeriod);
                // }
                dropdownEvent(value, el)
              }}
              options={options}
              small
            />
            {/* <img src={apiKeyOptions} className="api-keys__options" alt="" /> */}
          </div>
        ))}
      </div> : null
      }
    </Card>
  )
}

export default injectIntl(ApiKeys)