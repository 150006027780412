import "./SetPassword.sass"
import { FC, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../types";
import cn from "classnames";
import Card from "../../ui-kit/components/Card";
import { Button, Form, Input, Space } from "antd";
import { useGetProfileQuery, useSetPasswordMutation } from "../../api/reg-service.api";
import { responsePathOr } from "../../utils/utils";
import { } from "../../constants";
import { ClosedEye, OpenedEye } from "../../resources/icons/password-eye";
import Icon from "@ant-design/icons";

type TProps = {} & IBaseComponentProps
type IFormInput = {
  password: string;
  newPassword: string;
  repeatNewPassword: string;
};

const SetPassword: FC<TProps> = ({ intl, className }) => {
  const { data, isSuccess } = useGetProfileQuery();
  const [changePassword, { isLoading: isLoadingSetPassword }] =
    useSetPasswordMutation();

  const [submitError, setSubmitError] = useState<string>();

  const onSubmit = async (value: IFormInput) => {
    try {
      await changePassword({
        newPassword: value.newPassword,
        password: value.password,
      }).unwrap();
    } catch (err) {
      setSubmitError(responsePathOr(err));
    }
  };
  const [form] = Form.useForm();

  useEffect(() => {
    if (isSuccess) form.setFieldValue("email", data.email);
  }, [data, form, isSuccess]);

  const [formValid, setFormValid] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const openCloseIcons = (visible: boolean) =>
    visible ? <Icon component={OpenedEye} /> : <Icon component={ClosedEye} />;

  const disabled = formValid || !isFocused || isLoadingSetPassword;


  return (
    <Card
      className={cn(className, "set-password")}
      title="Password"
      classTitle={cn("title-red")}
    >
      <div className={"set-password__content"}>
        <Form
          form={form}
          scrollToFirstError
          className={cn("set-password__form", className)}
          onFinish={onSubmit}
          onFieldsChange={() => {
            if (submitError) setSubmitError("");
          }}
          layout="vertical"
          autoComplete="off"
          onValuesChange={() => {
            setIsFocused(true);
            form
              .validateFields()
              .then((values) => {
                const valuesAvailable = Object.values(values).every(
                  (value) => value
                );
                setFormValid(!valuesAvailable);
              })
              .catch((errorInfo) => {
                const valuesAvailable = Object.values(errorInfo.values).every(
                  (value) => value
                );
                if (valuesAvailable && !errorInfo.errorFields.length) {
                  setFormValid(false);
                } else {
                  setFormValid(true);
                }
              });
          }}
        >
          <Form.Item
            className="set-password__label"
            label="Old password"
            rules={[
              {
                min: 8,
              },
            ]}
            name={"password"}
          >
            <Input.Password
              className="set-email__password set-email__input"
              autoComplete="new-password"
              iconRender={openCloseIcons} />
          </Form.Item>
          <div className="set-password__password-inputs">
            <Form.Item
              className="set-password__label"
              label="New password"
              rules={[
                {
                  min: 8,
                },
              ]}
              name={"newPassword"}
            >
              <Input.Password
                className="set-email__password set-email__input"
                autoComplete="new-password"
                iconRender={openCloseIcons} />
            </Form.Item>
            <Form.Item
              className="set-password__label"
              label={"New password"}
              rules={[
                {
                  min: 8,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords don't match"));
                  },
                }),
              ]}
              name={"repeatNewPassword"}
            >
              <Input.Password className="set-email__password set-email__input"
                autoComplete="new-password" iconRender={openCloseIcons} />
            </Form.Item>
          </div>
          <Form.Item className="set-password__button">
            <Space>
              <Button disabled={disabled} htmlType="submit" type="primary">
                Update password
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Card>
  )
}

export default injectIntl(SetPassword)