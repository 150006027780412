import { useSelector } from "react-redux";
import { IGetCurrentPlanResponse } from "../api/types/get-current-plan";
import { IGetCurrentSubscriptionResponse } from "../api/types/get-subscription";
import { selectPending } from "../reducers/pendings.slice";
import { PLAN_TS_STORAGE_KEY, SUBSCRIPTION_TS_STORAGE_KEY } from "../constants";
import usePlanType, { EPlanType } from "./usePlanType";
import { useMemo } from "react";
import { MessageDescriptor } from "../utils/formatMessage";
import {
  checkPendingPayment,
  checkTooLongPaymentPending,
  getExpireSubscriptionDate,
} from "./useSubscriptionDetails";
const SUBSCRIPTION_PENDING_PAYMENT = { id: "home-page.payment-pending" };
const SUBSCRIPTION_ERROR_PAYMENT = { id: "home-page.payment-error" };
const SUBSCRIPTION_ERROR_PAYMENT_OVERDUE = { id: "home-page.payment-overdue" };

const useSubscriptionBadge = (
  currentPlanQuery: {
    data?: IGetCurrentPlanResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  }
) => {
  const {
    error: errorPlan,
    isLoading: isLoadingPlan,
    data: currentPlan,
  } = currentPlanQuery;
  const {
    error: errorSubscription,
    isLoading: isLoadingSubscription,
    data: currentSubscription,
  } = currentSubscriptionQuery;

  const planPendingData = useSelector(selectPending(PLAN_TS_STORAGE_KEY));
  const subscriptionPendingData = useSelector(
    selectPending(SUBSCRIPTION_TS_STORAGE_KEY)
  );
  const planType = usePlanType(currentPlan);
  return useMemo<
    | { type: "success" | "warning" | "error"; message: MessageDescriptor }
    | undefined
  >(() => {
    if (
      !currentPlan ||
      (!currentPlan && !errorPlan && isLoadingPlan) ||
      (!currentSubscription && !errorSubscription && isLoadingSubscription)
    )
      return undefined;

    if (
      (planType === EPlanType.STANDARD ||
        planType === EPlanType.NEW_STANDARD ||
        planType === EPlanType.TAILORED) &&
      currentSubscription &&
      (currentSubscription.status === "canceled" ||
        !currentSubscription.nextBilledAt)
    ) {
      const nextPaymentMs = currentPlan.validUntil
        ? new Date(currentPlan.validUntil).getTime()
        : getExpireSubscriptionDate(currentSubscription);
      const alreadyCanceled = new Date() > new Date(nextPaymentMs);

      if (alreadyCanceled) return undefined;

      return {
        type: "warning",
        message: {
          id: "home-page.your-cancelled-plan",
          params: {
            date: new Date(nextPaymentMs).toLocaleDateString(),
          },
        },
      };
    }

    if (
      checkPendingPayment(planPendingData) ||
      checkPendingPayment(subscriptionPendingData)
    ) {
      return {
        type: "warning",
        message: SUBSCRIPTION_PENDING_PAYMENT,
      };
    }

    if (
      checkTooLongPaymentPending(planPendingData) ||
      checkTooLongPaymentPending(subscriptionPendingData)
    ) {
      return {
        type: "error",
        message: SUBSCRIPTION_ERROR_PAYMENT,
      };
    }

    if (
      currentSubscription &&
      currentSubscription.status === "past_due" &&
      planType !== EPlanType.TAILORED
    ) {
      const nextBilledAt = new Date(
        currentSubscription?.nextBilledAt || ""
      ).toLocaleDateString();
      return {
        type: "error",
        message: {
          id: SUBSCRIPTION_ERROR_PAYMENT_OVERDUE.id,
          params: {
            date: nextBilledAt,
          },
        },
      };
    }
    return undefined;
  }, [
    currentPlan,
    currentSubscription,
    errorPlan,
    errorSubscription,
    isLoadingPlan,
    isLoadingSubscription,
    planPendingData,
    planType,
    subscriptionPendingData,
  ]);
};

export default useSubscriptionBadge;
