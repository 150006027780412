import { useEffect, useMemo, useState } from "react";
import useAvailablePlans from "./useAvailablePlans";
import { ButtonGroupsSmoothOption } from "../components/ButtonGroupsSmooth/ButtonGroupsSmooth";

const useGetSelectedPlan = () => {
  const { paidTariffs } = useAvailablePlans({});
  const [selectedPaidTariffOption, setSelectedPaidTariffOption] =
    useState<ButtonGroupsSmoothOption>({ title: "0", value: "0" });


  const selectedPlan = useMemo(() => {
    return (
      paidTariffs.find((el) => el?.name === selectedPaidTariffOption.title) ||
      paidTariffs[0]
    );
  }, [paidTariffs, selectedPaidTariffOption.title]);

  useEffect(() => {
    setSelectedPaidTariffOption({
      title: paidTariffs[0]?.name || "0",
      value: paidTariffs[0]?.name || "0",
    });
  }, [paidTariffs]);

  return { selectedPlan, selectedPaidTariffOption, setSelectedPaidTariffOption }
}

export default useGetSelectedPlan;