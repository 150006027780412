import { useMemo } from "react";
import { useGetCurrentPlanQuery } from "../api/reg-service.api";
import usePlanType, { EPlanType } from "./usePlanType";

type TLimits = { dayLimit: number; monthLimit: number; dataTransferBt: number };

const limitsByPlanType: Record<
  EPlanType,
  (name: string) => TLimits | undefined
> = {
  [EPlanType.DEFAULT]: (name: string) => ({
    dayLimit: 50e3,
    monthLimit: 2e6,
    dataTransferBt: 10.7e10,
  }),
  [EPlanType.NEW_STANDARD]: (name: string) => {
    let result;
    switch (name) {
      case "HybridN125":
        result = {
          dayLimit: 375e3,
          monthLimit: 11.25e6,
          dataTransferBt: 1.1e12,
        };
        break;
      case "HybridN225":
        result = {
          dayLimit: 4e6,
          monthLimit: 50e6,
          dataTransferBt: 5.5e12,
        };
        break;
      case "HybridN425":
        result = {
          dayLimit: 9e6,
          monthLimit: 100e6,
          dataTransferBt: 2.2e13,
        };
        break;
      case "HybridN1500":
        result = {
          dayLimit: 17.5e6,
          monthLimit: 500e6,
          dataTransferBt: 4.4e13,
        };
        break;
    }

    return result;
  },
  [EPlanType.STANDARD]: (name: string) => ({
    dayLimit: 375e3,
    monthLimit: 11.25e6,
    dataTransferBt: 1.1e12,
  }),
  [EPlanType.TAILORED]: () => undefined,
  [EPlanType.UNKNOWN]: () => undefined,
};

const useLimits = () => {
  const { data: currentPlan } = useGetCurrentPlanQuery();
  const planType = usePlanType(currentPlan);

  const limits = useMemo<Partial<TLimits>>(() => {
    if (!currentPlan || !currentPlan.name) return {};
    const _limits = limitsByPlanType[planType](currentPlan.name);
    if (!_limits) return {};
    return _limits;
  }, [currentPlan, planType]);

  return {
    rps: currentPlan?.rps || 0,
    ...limits,
  };
};

export default useLimits;
