import { FC } from "react";
import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../types";
import Card from "../../ui-kit/components/Card";
import cn from "classnames";
// import Tooltip from "../../ui-kit/components/Tooltip";
import "./Security.sass";
import Dropdown from "../../ui-kit/components/Dropdown";

type TProps = {} & IBaseComponentProps


const Security: FC<TProps> = ({ intl, className }) => {

  return (
    <Card className={cn("security", className)} title="Security"
      classTitle="title-purple"
    >
      <div className="security__content">
        <div className="security__item">
          <p className="security__title">
            Allowed Domains
            {/* <Tooltip
              className="security__tooltip"
              title={"title"}
              icon="info"
              place="top"
            /> */}
          </p>
          <Dropdown
            className="security__dropdown"
            value="Empty"
            setValue={() => { }}
            options={[]}
            small
          />
        </div>
        <div className="security__item">
          <p className="security__title">
            Allowed IPs
            {/* <Tooltip
              className="security__tooltip"
              title={"title"}
              icon="info"
              place="top"
            /> */}
          </p>
          <Dropdown
            className="security__dropdown"
            value="Empty"
            setValue={() => { }}
            options={[]}
            small
          />
        </div>
        <div className="security__item">
          <p className="security__title">
            Allowed CIDRs
            {/* <Tooltip
              className="security__tooltip"
              title={"title"}
              icon="info"
              place="top"
            /> */}
          </p>
          <Dropdown
            className="security__dropdown"
            value="Empty"
            setValue={() => { }}
            options={[]}
            small
          />
        </div>
      </div>
    </Card>
  )
}

export default injectIntl(Security)