import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import { isSnapshot } from "../../constants";

interface IProps {
  clientId: string;
}
interface IGoogleOAuthProviderProps {
  clientId: string;
  isScriptLoaded: boolean;
}

export const GoogleOAuthContext = createContext<IGoogleOAuthProviderProps>(
  null!
);

export const GoogleOAuthProvider: React.FC<PropsWithChildren<IProps>> = ({
  clientId,
  children,
}) => {
  const [isScriptLoaded, setScriptLoaded] = useState<boolean>(false);

  useEffect(() => {
    let scriptTag: HTMLScriptElement;
    if (!isSnapshot) {
      scriptTag = document.createElement("script");
      scriptTag.src = "https://accounts.google.com/gsi/client";
      scriptTag.async = true;
      scriptTag.defer = true;
      scriptTag.onload = () => {
        setScriptLoaded(true);
      };

      document.body.appendChild(scriptTag);
    }

    return () => {
      scriptTag && document.body.removeChild(scriptTag);
    };
  }, []);

  const googleOAuthContextValue = useMemo(() => {
    return {
      clientId,
      isScriptLoaded,
    };
  }, [clientId, isScriptLoaded]);

  return (
    <GoogleOAuthContext.Provider value={googleOAuthContextValue}>
      {children}
    </GoogleOAuthContext.Provider>
  );
};

export default GoogleOAuthProvider;
