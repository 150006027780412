import { injectIntl } from "react-intl";
import cn from "classnames";
import styles from "./AcceptModal.module.sass";
import { IBaseComponentProps } from "../../types";
import { useState } from "react";

interface IProps extends IBaseComponentProps {
  handleCancel: () => Promise<void>;
}

const AcceptCancel: React.FC<IProps> = ({ handleCancel, className }) => {
  const [alreadyCancelled, setAlreadyCancelled] = useState(false);
  return (
    <div className={cn(styles.acceptModal, className)}>
      <div className={cn("h2", styles.modalTitle)}>Cancel Subscription</div>
      <div className={styles.modalBody}>
        {alreadyCancelled
          ? "Your subscription has been successfully canceled."
          : "Are you sure? This action will stop all future charges and access to premium features."}
      </div>
      {alreadyCancelled ? null : (
        <button
          className={cn("button", "button-stroke-red", styles.modalButton)}
          onClick={() => handleCancel().then(() => setAlreadyCancelled(true))}
        >
          Cancel Subscription
        </button>
      )}
    </div>
  );
};

export default injectIntl(AcceptCancel);
