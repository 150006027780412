import { useMemo } from "react";
import { IGetCurrentPlanResponse } from "../api/types/get-current-plan";
import { IGetCurrentSubscriptionResponse } from "../api/types/get-subscription";
import usePlanType, { EPlanType } from "./usePlanType";
import {
  HOME_PAGE_BUTTON_SUBSCRIBE,
  HOME_PAGE_YOUR_ACTIVE_SUBSCRIPTION,
  checkPendingPayment,
  checkTooLongPaymentPending,
} from "./useSubscriptionDetails";
import { useSelector } from "react-redux";
import { selectPending } from "../reducers/pendings.slice";
import { PLAN_TS_STORAGE_KEY, SUBSCRIPTION_TS_STORAGE_KEY } from "../constants";

export function useStandardButtonProps(
  currentPlanQuery: {
    data?: IGetCurrentPlanResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  selectedPlanName?: string
) {
  const planType = usePlanType(currentPlanQuery.data);

  const planPendingData = useSelector(selectPending(PLAN_TS_STORAGE_KEY));
  const subscriptionPendingData = useSelector(
    selectPending(SUBSCRIPTION_TS_STORAGE_KEY)
  );
  return useMemo(() => {
    if (
      planType === EPlanType.DEFAULT &&
      (!currentSubscriptionQuery.data ||
        currentSubscriptionQuery.data.status === "canceled") &&
      !planPendingData &&
      !subscriptionPendingData
    ) {
      // ok отмененная
      return {
        disabled: false,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (
      currentSubscriptionQuery.data &&
      currentSubscriptionQuery.data.status !== "canceled" &&
      planType !== EPlanType.DEFAULT &&
      planType !== EPlanType.TAILORED &&
      selectedPlanName === currentPlanQuery.data?.name
    ) {
      // ok !! платная
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_YOUR_ACTIVE_SUBSCRIPTION,
      };
    } else if (
      currentSubscriptionQuery.data &&
      currentSubscriptionQuery.data.status !== "canceled" &&
      planType !== EPlanType.DEFAULT &&
      planType !== EPlanType.TAILORED &&
      selectedPlanName !== currentPlanQuery.data?.name
    ) {
      // ok !! платная
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (
      checkPendingPayment(planPendingData) ||
      checkPendingPayment(subscriptionPendingData)
    ) {
      // ok
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (
      checkTooLongPaymentPending(planPendingData) ||
      checkTooLongPaymentPending(subscriptionPendingData)
    ) {
      // ok
      return {
        disabled: false,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (
      currentSubscriptionQuery.data?.status === "past_due" &&
      selectedPlanName === currentPlanQuery.data?.name
    ) {
      // ok
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_YOUR_ACTIVE_SUBSCRIPTION,
      };
    } else if (
      currentSubscriptionQuery.data?.status === "past_due" &&
      selectedPlanName !== currentPlanQuery.data?.name
    ) {
      // ok
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (
      planType === EPlanType.DEFAULT &&
      currentSubscriptionQuery.data &&
      currentSubscriptionQuery.data.status !== "canceled"
    ) {
      // ok
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (
      currentSubscriptionQuery?.data?.status === "canceled" &&
      selectedPlanName === currentPlanQuery.data?.name
    ) {
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_YOUR_ACTIVE_SUBSCRIPTION,
      };
    } else if (
      currentSubscriptionQuery?.data?.status === "canceled" &&
      selectedPlanName !== currentPlanQuery.data?.name
    ) {
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      };
    } else if (planType === EPlanType.TAILORED)
      return {
        disabled: true,
        buttonTitle: HOME_PAGE_BUTTON_SUBSCRIBE,
      }; // ok    return undefined;
  }, [
    planPendingData,
    planType,
    currentSubscriptionQuery.data,
    subscriptionPendingData,
    selectedPlanName,
    currentPlanQuery.data?.name,
  ]);
}
