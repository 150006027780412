import { useEffect, useRef } from "react";
import { useGetCurrentSubscriptionQuery } from "../api/reg-service.api";

const useSavingPastDueStatus = () => {
  const { data: subscription, isLoading } = useGetCurrentSubscriptionQuery();

  const currentStatus = useRef<string>();

  useEffect(() => {
    if (subscription?.status === "past_due") {
      currentStatus.current = "past_due"
    }

    return () => {
      currentStatus.current = subscription?.status
    }
  }, [subscription?.status])

  return { data: currentStatus.current, isLoading }
}


export default useSavingPastDueStatus;