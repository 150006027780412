import cn from "classnames";
import { IBaseComponentProps } from "../../types";
import { useEffect } from "react";
import { useLoginByGoogleMutation } from "../../api/reg-service.api";
import useGoogleOAuth from "../../hooks/useGoogleOAuth";
import googleIcon from "../../resources/images/google.svg";
import "./GoogleButton.sass"
interface IProps extends Omit<IBaseComponentProps, "intl"> {
  onSuccess: () => void;
  onError: (error: any) => void;
  landingId: number;
}

const GOOGLE_BUTTON_ID = "buttonDiv";

const GoogleButton: React.FC<IProps> = ({
  className,
  onSuccess,
  onError,
  landingId,
}) => {
  const [loginByGoogle] = useLoginByGoogleMutation();
  const { isScriptLoaded, clientId } = useGoogleOAuth();

  useEffect(() => {
    if (isScriptLoaded) {
      window.google?.accounts.id.initialize({
        client_id: clientId,
        callback: (response: any) => {
          loginByGoogle({
            token: response.credential,
            landingId,
          })
            .unwrap()
            .then(onSuccess)
            .catch(onError);
        },
      });
      window.google?.accounts.id.renderButton(
        document.getElementById(GOOGLE_BUTTON_ID),
        { theme: "dark", size: "large", width: "296px", height: "48px"} // customization attributes
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginByGoogle, isScriptLoaded]);

  if (!isScriptLoaded) return null

  return (
    <div className="googleButton__wrapper">
     <div className={cn("google-button googleButton", className)} data-width="296" data-height="48" id={GOOGLE_BUTTON_ID} />
     <div className="googleButton__screen"><img width={24} height={24} src={googleIcon} alt="" />Google</div>
    </div>
  );
};

export default GoogleButton;
