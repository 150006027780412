import React, { useMemo, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Chart from "./Chart";
import { TPeriod } from "../../../../api/types/get-requests-per-period";
import {
  useGetCurrentPlanQuery,
  useGetRequestPerPeriodQuery,
} from "../../../../api/reg-service.api";
// import useDataTransferLimit from "../../../../hooks/useDataTransferLimits";
import BigNumber from "bignumber.js";
import {
  addInterval,
  formatterXMap,
  isEarlerWithStartPeriod,
  TInterval,
} from "../../../../utils/formatDate";
import { capitaliseFirstLetter, EMeasure } from "../../../../utils/utils";
import { last } from "ramda";
import useLimits from "../../../../hooks/useLimits";

// const intervals = ["Last 28 days", "Last 14 days", "Last 7 days"];
const intervals = [
  "24 Hours", // by hour
  "30 Days", // by day
  "12 Months", // by month
];

const mapOptionToPeriod: Record<string, TPeriod> = {
  "24 Hours": "hour",
  "30 Days": "day",
  "12 Months": "month",
};

const mapPeriodToOption: Record<TPeriod, string> = {
  hour: "24 Hours",
  day: "30 Days",
  month: "12 Months",
  minute: "60 Minutes",
};

interface IProps {
  className?: string;
}

const formatterBytesMap = {
  [EMeasure.KILOBYTES]: (bytes: number) => new BigNumber(bytes).dividedBy(1024),
  [EMeasure.MEGABYTES]: (bytes: number) =>
    new BigNumber(bytes).dividedBy(1024).dividedBy(1024),
  [EMeasure.GIGABYTES]: (bytes: number) =>
    new BigNumber(bytes).dividedBy(1024).dividedBy(1024).dividedBy(1024),
  [EMeasure.TERABYTES]: (bytes: number) =>
    new BigNumber(bytes)
      .dividedBy(1024)
      .dividedBy(1024)
      .dividedBy(1024)
      .dividedBy(1024),
};

export const mapPeriod: Record<TPeriod, Partial<TInterval>> = {
  hour: { day: 1 },
  day: { day: 30 },
  month: { month: 12 },
  minute: { hour: 1 },
};

const Overview: React.FC<IProps> = ({ className }) => {
  const [period, setPeriod] = useState<TPeriod>("hour");
  const { data: currentPlan } = useGetCurrentPlanQuery();

  const startPlanDate =
    currentPlan && currentPlan.validFrom ? currentPlan.validFrom : undefined;
  const { dataTransferBt } = useLimits();

  const { data: requestPerPeriod, isLoading: isLoadingRequestsPerPeriod } =
    useGetRequestPerPeriodQuery(
      {
        period,
        startDate: startPlanDate,
      },
      {
        pollingInterval: 60000,
        skip: !currentPlan,
      }
    );

  const activPeriodStartDate = useMemo(() => {
    if (!requestPerPeriod) return new Date();

    const reversedData = [...requestPerPeriod].reverse();
    return getActivePeriodStartDate(
      startPlanDate,
      period,
      last(reversedData)?.date
    );
  }, [period, requestPerPeriod, startPlanDate]);

  const measure = useMemo(() => {
    const maxValue =
      requestPerPeriod
        ?.filter(
          (el) =>
            !isEarlerWithStartPeriod(
              el,
              activPeriodStartDate.toISOString(),
              period
            )
        )
        .reduce((acc, el) => {
          if (acc < el.bytesIn + el.bytesOut) return el.bytesIn + el.bytesOut;
          return acc;
        }, 0) || 0;
    return getMeasureBytes(maxValue);
  }, [activPeriodStartDate, period, requestPerPeriod]);

  const formatter = useMemo(() => {
    return formatterBytesMap[measure];
  }, [measure]);

  const data = useMemo(() => {
    if (!requestPerPeriod) return [];
    const reversedData = [...requestPerPeriod].reverse();
    let cumulativeGb = new BigNumber(0);

    return reversedData.map((el) => {
      if (
        isEarlerWithStartPeriod(el, activPeriodStartDate.toISOString(), period)
      ) {
        return {
          name: formatterXMap[period](el.date),
          totalDataTransferred: cumulativeGb.toNumber(),
          date: el.date,
        };
      }
      cumulativeGb = cumulativeGb
        .plus(formatter(el.bytesIn))
        .plus(formatter(el.bytesOut));

      return {
        name: formatterXMap[period](el.date),
        totalDataTransferred: cumulativeGb.toNumber(),
        date: el.date,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPerPeriod, measure, activPeriodStartDate]);

  const limit = useMemo(() => {
    let _limit;
    switch (period) {
      case "day":
        _limit = dataTransferBt;
        break;
      case "hour":
        _limit = 0;
        break;
      case "month":
        _limit = 0;
        break;
      default:
        _limit = 0;
    }

    return _limit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPerPeriod]);

  const legend = useMemo(() => {
    if (!limit) return [];
    const bytesMeasure = getMeasureBytes(limit);
    return !data.length && !isLoadingRequestsPerPeriod
      ? []
      : [
          {
            title: "Traffic",
            color: "#007AFF",
          },
          {
            title: `Your Limit: ${Math.round(
              formatterBytesMap[bytesMeasure](limit).toNumber()
            )}${bytesMeasure} / ${capitaliseFirstLetter("month")}`,
            color: "#FF3B30",
          },
        ];
  }, [data.length, isLoadingRequestsPerPeriod, limit]);
  return (
    <Card
      className={cn(styles.card, className)}
      title="Total data transferred"
      classTitle={cn("title-purple", styles.cardTitle)}
      classCardHead={styles.cardHead}
      head={
        !data.length && !isLoadingRequestsPerPeriod ? null : (
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={mapPeriodToOption[period]}
            setValue={(value) => {
              const nextPeriod = mapOptionToPeriod[value];
              if (nextPeriod) {
                setPeriod(nextPeriod);
              }
            }}
            options={intervals}
            small
          />
        )
      }
    >
      <div className={styles.overview}>
        <div className={styles.details}>
          {/* <div className={cn("h4", styles.title)}>1,509 customers</div> */}
          {/* <div className={styles.line}>
            <Balance className={styles.balance} value="37.8" background /> vs.
            Sep 8, 2021
          </div> */}
        </div>
        <Chart
          data={data}
          limit={limit}
          isLoading={isLoadingRequestsPerPeriod}
          measure={measure}
          formatter={formatter}
          period={period}
          startPlanDate={startPlanDate}
          className={styles.chart}
        />
        {/* <Users className={styles.users} /> */}
      </div>
      {!data.length && !isLoadingRequestsPerPeriod ? null : (
        <div className={styles.legend}>
          {legend.map((x, index) => (
            <div className={styles.indicator} key={index}>
              <div
                className={styles.color}
                style={{ backgroundColor: x.color }}
              ></div>
              {x.title}
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};

export default Overview;

function getActivePeriodStartDate(
  startPlanDate: string | undefined,
  period: TPeriod,
  lastDate?: string
) {
  let activPeriodStartDate = new Date(startPlanDate || 0);
  const nextPeriodStartDate = startPlanDate
    ? addInterval(startPlanDate, mapPeriod[period])
    : new Date();
  if (startPlanDate && new Date(lastDate || 0) > nextPeriodStartDate)
    activPeriodStartDate = nextPeriodStartDate;
  return activPeriodStartDate;
}

function getMeasureBytes(bytes: number) {
  let measure = EMeasure.KILOBYTES;
  if (bytes * 1e-6 > 1) measure = EMeasure.MEGABYTES;
  if (bytes * 1e-9 > 1) measure = EMeasure.GIGABYTES;
  if (bytes * 1e-12 > 1) measure = EMeasure.TERABYTES;

  return measure;
}
