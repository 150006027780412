import React, { useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import {
  useGetProfileQuery,
  useLogoutMutation,
} from "../../../../api/reg-service.api";
import Modal from "../../Modal";
import SetEmail from "../../../../components/SetEmail/SetEmail";
import SetPassword from "../../../../components/SetPassword/SetPassword";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";

interface IProps {
  className?: string;
}

interface IItem {
  title: string;
  url?: string;
  color?: string;
  icon?: string;
  onClick?: VoidFunction;
}

const User: React.FC<IProps> = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();
  const [logout] = useLogoutMutation();
  const { data: profile } = useGetProfileQuery();
  const [visibleModalSetEmail, setVisibleModalEmail] = useState(false);
  const [visibleModalSetPassword, setVisibleModalPassword] = useState(false);

  const ref = useRef(null);
  useOutsideClick(ref, () => setVisible(false));

  const items: {
    menu: IItem[];
  }[] = profile?.oauthSub
    ? [
        {
          menu: [
            {
              title: profile?.email || "",
              // url: ROUTE_PATH.PROFILE,
            },
          ],
        },
        {
          menu: [
            {
              title: "Log out",
              onClick: () => {
                logout();
              },
            },
          ],
        },
      ]
    : [
        {
          menu: [
            {
              title: profile?.email || "",
              // url: ROUTE_PATH.PROFILE,
            },
          ],
        },
        {
          menu: [
            {
              title: "Set email",
              onClick: () => setVisibleModalEmail(true),
              // url: ROUTE_PATH.PROFILE,
            },
          ],
        },
        {
          menu: [
            {
              title: "Set password",
              onClick: () => setVisibleModalPassword(true),
              // url: ROUTE_PATH.PROFILE,
            },
          ],
        },
        {
          menu: [
            {
              title: "Log out",
              onClick: () => {
                logout();
              },
            },
          ],
        },
      ];

  return (
    <>
      <div
        className={cn(styles.user, className, {
          [styles.active]: visible,
        })}
        ref={ref}
      >
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img src="/images/content/avatar.svg" alt="Avatar" />
        </button>
        <div className={styles.body}>
          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map((x, index) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.color]: x.color,
                      [styles.active]: pathname === x.url,
                    })}
                    to={x.url}
                    onClick={() => setVisible(false)}
                    key={index}
                  >
                    {x.icon && <Icon name={x.icon} size="24" />}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    onClick={() => {
                      if (x.onClick) x.onClick();
                      setVisible(false);
                    }}
                    key={index}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
      <Modal
        visible={visibleModalSetEmail}
        onClose={() => setVisibleModalEmail(false)}
        outerClassName={undefined}
      >
        <SetEmail />
      </Modal>
      <Modal
        visible={visibleModalSetPassword}
        onClose={() => setVisibleModalPassword(false)}
        outerClassName={undefined}
      >
        <SetPassword />
      </Modal>
    </>
  );
};

export default User;
