import React, { FC, useEffect, useState } from "react";
import "./Profile.sass";
import useToken from "antd/es/theme/useToken";
import { Button, Input, Form, Space } from "antd";
import cn from "classnames";
import { ReactComponent as Pencil } from "../../resources/images/pencil.svg";
import Icon from "@ant-design/icons";
import formatMessage from "../../utils/formatMessage";
import { injectIntl } from "react-intl";
import { ClosedEye, OpenedEye } from "../../resources/icons/password-eye";
import { Link } from "react-router-dom";
import InputPassword from "../InputPassword/InputPassword";
import {
  useGetProfileQuery,
  useSetPasswordMutation,
} from "../../api/reg-service.api";
import { responsePathOr } from "../../utils/utils";
import { ROUTE_PATH } from "../../constants";
import { IBaseComponentProps } from "../../types";
import Title from "../Title";

type TProps = {} & IBaseComponentProps;

type IFormInput = {
  email: string;
  password: string;
  newPassword: string;
  repeatNewPassword: string;
};

const PROFILE_TITLE = { id: "profile.title" };
const PROFILE_LABEL_EMAIL = { id: "profile.label-email" };
const PROFILE_PASSWORD = { id: "profile.label-password" };
const PROFILE_NEW_PASSWORD = { id: "profile.label-new-password" };
const PROFILE_REPEAT_NEW_PASSWORD = { id: "profile.label-repeat-new-password" };
const PROFILE_BUTTON = { id: "profile.button" };

const Profile: FC<TProps> = ({ intl, className }) => {
  const { data, isSuccess, isLoading, isUninitialized } = useGetProfileQuery();
  const [changePassword, { isLoading: isLoadingSetPassword }] =
    useSetPasswordMutation();

  const [, token] = useToken();
  const [submitError, setSubmitError] = useState<string>();

  const onSubmit = async (value: IFormInput) => {
    try {
      await changePassword({
        newPassword: value.newPassword,
        password: value.password,
      }).unwrap();
    } catch (err) {
      setSubmitError(responsePathOr(err));
    }
  };
  const [form] = Form.useForm();

  useEffect(() => {
    if (isSuccess) form.setFieldValue("email", data.email);
  }, [data, form, isSuccess]);

  const [formValid, setFormValid] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const openCloseIcons = (visible: boolean) =>
    visible ? <Icon component={OpenedEye} /> : <Icon component={ClosedEye} />;

  const disabled = formValid || !isFocused || isLoadingSetPassword;

  if (isUninitialized && isLoading) return null;

  return (
    <div
      style={{
        // backgroundColor: token.colorPrimaryBg,
        color: token.colorPrimary,
      }}
      className="profile"
    >
      <Title text={PROFILE_TITLE} />
      <Form
        form={form}
        scrollToFirstError
        className={cn("profile-form", className)}
        onFinish={onSubmit}
        onFieldsChange={() => {
          if (submitError) setSubmitError("");
        }}
        layout="vertical"
        autoComplete="off"
        onValuesChange={() => {
          setIsFocused(true);
          form
            .validateFields()
            .then((values) => {
              const valuesAvailable = Object.values(values).every(
                (value) => value
              );
              setFormValid(!valuesAvailable);
            })
            .catch((errorInfo) => {
              const valuesAvailable = Object.values(errorInfo.values).every(
                (value) => value
              );
              if (valuesAvailable && !errorInfo.errorFields.length) {
                setFormValid(false);
              } else {
                setFormValid(true);
              }
            });
        }}
      >
        <Form.Item
          label={formatMessage(intl, PROFILE_LABEL_EMAIL)}
          name={"email"}
          rules={[
            {
              // required: true,
              type: "email",
            },
          ]}
        >
          <Input
            style={{
              height: (token?.Input as any)?.controlHeight,
              padding: token.Input?.paddingBlock,
            }}
            readOnly
            className="profile-form_input_type_email"
            suffix={
              <Link style={{ height: "20px" }} to={ROUTE_PATH.CHANGE_EMAIL}>
                <Pencil className="profile_img" />
                {/* <img className="profile_img" src={pencil} alt="" /> */}
              </Link>
            }
          />
        </Form.Item>
        <Form.Item
          label={formatMessage(intl, PROFILE_PASSWORD)}
          rules={[
            {
              min: 8,
            },
          ]}
          name={"password"}
        >
          <InputPassword
            autoComplete="new-password"
            iconRender={openCloseIcons}
          />
        </Form.Item>
        <Form.Item
          label={formatMessage(intl, PROFILE_NEW_PASSWORD)}
          rules={[
            {
              min: 8,
            },
          ]}
          name={"newPassword"}
        >
          <Input.Password iconRender={openCloseIcons} />
        </Form.Item>
        <Form.Item
          label={formatMessage(intl, PROFILE_REPEAT_NEW_PASSWORD)}
          rules={[
            {
              min: 8,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords don't match"));
              },
            }),
          ]}
          name={"repeatNewPassword"}
        >
          <Input.Password iconRender={openCloseIcons} />
        </Form.Item>
        <Form.Item className="profile-form_button">
          <Space>
            <Button disabled={disabled} htmlType="submit" type="primary">
              {formatMessage(intl, PROFILE_BUTTON)}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default injectIntl(Profile);
