import { useMemo } from "react";
import { IGetCurrentPlanResponse } from "../api/types/get-current-plan";
import { IGetCurrentSubscriptionResponse } from "../api/types/get-subscription";
import usePlanType, { EPlanType } from "./usePlanType";
import {
  isDefaultPlan,
  isStandardOrNewStandard,
  isTailoredPlan,
} from "../utils/subscriptions";

const canEdit = {
  canEdit: true,
  disabledUpdate: false,
  disabledCancel: false,
};
const cannotEdit = {
  canEdit: false,
  disabledUpdate: true,
  disabledCancel: true,
};
const useSubscriptionEdit = (
  currentPlanQuery: {
    data?: IGetCurrentPlanResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  }
) => {
  const planType = usePlanType(currentPlanQuery.data);
  return useMemo(() => {
    if (isTailoredCancelled(planType, currentSubscriptionQuery)) {
      return cannotEdit;
    } else if (!currentSubscriptionQuery.data) {
      return cannotEdit;
    } else if (isPastDuedDefault(planType, currentSubscriptionQuery)) {
      return cannotEdit;
    } else if (isStandardCancelled(planType, currentSubscriptionQuery)) {
      return cannotEdit;
    }

    return canEdit;
  }, [currentSubscriptionQuery, planType]);
};

export default useSubscriptionEdit;

function isStandardCancelled(
  planType: EPlanType,
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  }
) {
  if (!currentSubscriptionQuery.data) return false;
  return (
    isStandardOrNewStandard(planType) &&
    (currentSubscriptionQuery.data.status === "canceled" ||
      !currentSubscriptionQuery.data.nextBilledAt)
  );
}

function isPastDuedDefault(
  planType: EPlanType,
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  }
) {
  return (
    isDefaultPlan(planType) &&
    currentSubscriptionQuery.data &&
    currentSubscriptionQuery.data.status !== "past_due"
  );
}

function isTailoredCancelled(
  planType: EPlanType,
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  }
) {
  return (
    isTailoredPlan(planType) &&
    currentSubscriptionQuery.data &&
    (currentSubscriptionQuery.data.status === "canceled" ||
      !currentSubscriptionQuery.data.nextBilledAt)
  );
}
