import React from "react";
import cn from "classnames";
import styles from "./MoreCustomers.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import { EXTERNAL_LINKS } from "../../../../constants.ts"
import linkedInIcon from "../../../../resources/images/linkedin.svg";
import telegramIcon from "../../../../resources/images/telegram.svg";

const socials = [
  {
    title: "Linkedin",
    img: linkedInIcon,
    url: EXTERNAL_LINKS.LINKED,
  },
  {
    title: "Twitter",
    icon: "twitter",
    url: EXTERNAL_LINKS.TWITTER,
  },
  {
    title: "Telegram",
    img: telegramIcon,
    url: EXTERNAL_LINKS.TELEGRAM,
  },
];

const MoreCustomers = ({ className }) => {
  return (
    <Card
      className={cn(styles.card, className)}
      title="Partner Program"
      classTitle="title-blue"
    >
      <div className={styles.comments}>
        <div className={styles.info}>
        Want to get a  discount? Subscribe to one of our social networks, leave a comment, and contact our support team to claim your discount.
          {/* Earn 50% of your clients' payments for a full year when they sign up through your referral! Start sharing and watch your rewards grow today!{" "} */}
          <span role="img" aria-label="fire">
            🔥
          </span>
        </div>
        <div className={styles.btns}>
          {socials.map((x, index) => (
            <a
              className={cn("button-stroke", styles.button)}
              href={x.url}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              {x.icon ? <Icon name={x.icon} size="24" /> : <img style={{ marginRight: "8px" }} src={x.img} alt="" />}
              <span className={styles.title}>{x.title}</span>
            </a>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default MoreCustomers;
