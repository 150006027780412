import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import cn from "classnames";
import styles from "./Modal.module.sass";
import Icon from "../Icon";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

interface IProps {
  visible: boolean;
  onClose: VoidFunction;
  outerClassName?: string;
}

const Modal: React.FC<PropsWithChildren<IProps>> = ({
  outerClassName,
  visible,
  onClose,
  children,
}) => {
  const escFunction = useCallback(
    (e: any) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal");
      if (target) disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  const ref = useRef(null);
  useOutsideClick(ref, onClose);

  return createPortal(
    visible && (
      <div id="modal" className={styles.modal}>
        <div className={cn(styles.outer, outerClassName)} ref={ref}>
          {children}
          <button className={styles.close} onClick={onClose}>
            <Icon name="close" size="20" />
          </button>
        </div>
      </div>
    ),
    document.body
  );
};

export default Modal;
