import { PropsWithChildren } from "react";
import "./WrapperForModal.sass";
import LogoBlock from "../LogoBlock/LogoBlock";
import { getYear } from "../../utils/utils";
import formatMessage from "../../utils/formatMessage";
import { LANDING_FOOTER_TERMS_OF_SERVICE } from "../LandingFooter/LandingFooter";
import { injectIntl, IntlShape } from "react-intl";
import { Link } from "react-router-dom";
import useGetLandingLinkByAuthorizedUser from "../../hooks/useGetLandingLinkByAuthorizedUser";
type TProps = {
  intl: IntlShape;
};

const WrapperForModal: React.FC<PropsWithChildren<TProps>> = ({
  children,
  intl,
}) => {
  const { landingLink } = useGetLandingLinkByAuthorizedUser();
  return (
    <div className="wrapper-for-modal">
      <LogoBlock className="wrapper-for-modal__logo" />
      {children}
      <p>
        © {getYear()} Best Architects L.L.C-FZ •{" "}
        <Link
          data-testid={"history-link"}
          to={`${landingLink}/docs/terms`}
          target="_blank"
          rel="noreferrer"
        >
          {formatMessage(intl, LANDING_FOOTER_TERMS_OF_SERVICE)}
        </Link>
      </p>
    </div>
  );
};
export default injectIntl(WrapperForModal);
