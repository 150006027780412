/* eslint-disable no-useless-escape */
import { FC, useMemo, useState } from "react";
import { injectIntl } from "react-intl";
import "./CodeExamplesNew.sass"
import { IBaseComponentProps } from "../../types";
import Card from "../../ui-kit/components/Card";
import cn from "classnames";
import codeExampleLink from "../../resources/images/code-example-link.svg"
import { ReactComponent as CopyIcon } from "../../resources/images/copy.svg"
import { Tooltip } from "antd";
import copyingToClipboard from "../../utils/copyingToClipboard";
import { createRpcUrl } from "../../constants";
import { useGetApiKeysQuery } from "../../api/reg-service.api";

type TExamples = "Accounts" | "Transactions" | "Basic";
type TProps = {
  account?: string;
} & IBaseComponentProps
const CodeExamplesNew: FC<TProps> = ({
  intl,
  className,
  account = "<account-you-are-interested-in>"
}) => {

  const [selectedExample, setSelectedExample] = useState<TExamples>("Basic")
  const { data: apiKeys } = useGetApiKeysQuery();
  const key = apiKeys?.length ? apiKeys[0] : "<your-api-key>"

  const examplesCode = useMemo(() => {

    if (selectedExample === "Basic") return (
      <div className="code-examples-new__value">
        <span>curl</span> {`${createRpcUrl(key)}`} \ <br />
        -X POST \ <br />
        -H “Content-Type: application/json” \ <br />
        -d  <span className="code-examples-new__example"> {` '{ “id”: 1, “jsonrpc”: “2.0”, "method”: “getBlockProduction” }'`}</span>
      </div>
    )

    if (selectedExample === "Accounts") return (
      <div className="code-examples-new__value">
        <span>curl</span>  {`${createRpcUrl(key)}`} \ <br />
        -X POST \  <br />
        -H "Content-Type: application/json" \  <br />
        -d <span className="code-examples-new__example"> {` '
                {
                  "jsonrpc": "2.0",
                  "id": 1,
                  "method": "getAccountInfo",
                  "params": ["
                    ${account}" ,
                    {
                      "encoding": "base58"
                    }
                  ]
                }
          '`}</span>
      </div>
    )
    if (selectedExample === "Transactions") return (
      <div className="code-examples-new__value">
        <span>curl</span> {`${createRpcUrl(key)}`} \ <br />
        -X POST \ <br />
        -H “Content-Type: application/json” \ <br />
        -d <span className="code-examples-new__example"> {`'{ "jsonrpc":"2.0","id":1, "method":"getTransactionCount" }'`}</span>
      </div>
    )
  }, [account, key, selectedExample])

  const examplesCopyCode = useMemo(() => {
    if (selectedExample === "Basic") return `curl ${createRpcUrl(
      key
    )} \-X POST \-H "Content-Type: application/json" \-d '{"id": 1, "jsonrpc": "2.0", "method": "getBlockProduction"}'`
    if (selectedExample === "Accounts") return `curl ${createRpcUrl(
      key
    )} \-X POST \-H "Content-Type: application/json" \-d '{ "jsonrpc": "2.0", "id": 1,  "method": "getAccountInfo",  "params": ["${account}" ,{ "encoding": "base58"}] }' `

    if (selectedExample === "Transactions") return `curl ${createRpcUrl(
      key
    )} \-X POST \-H "Content-Type: application/json" \-d '{ "jsonrpc":"2.0","id":1, "method":"getTransactionCount" }'`

    return ""
  }, [account, key, selectedExample])


  return (
    <Card className={cn("code-examples-new", className)} title="Code examples"
      head={
        <div>
          <a target="_blank"
            href="https://solana.com/docs"
            className="code-examples-new__link" rel="noreferrer">
            See Solana docs for info  <img src={codeExampleLink} alt="" />
          </a>
        </div>
      }
      classTitle="title-purple"
    >
      <div className="code-examples-new__content">
        <div className="code-examples-new__buttons">
          <p
            className={` code-examples-new__button ${selectedExample === "Basic" && "code-examples-new__button_selected"}`}
            onClick={() => { setSelectedExample("Basic") }}
          >Basic</p>
          <p
            className={` code-examples-new__button ${selectedExample === "Accounts" && "code-examples-new__button_selected"}`}
            onClick={() => { setSelectedExample("Accounts") }}
          >Accounts</p>
          <p
            className={` code-examples-new__button ${selectedExample === "Transactions" && "code-examples-new__button_selected"}`}
            onClick={() => { setSelectedExample("Transactions") }}
          >Transactions</p>
        </div>
        <div className="code-examples-new__value_block">
          <p className="code-examples-new__value_title">Code Snippet
            <Tooltip trigger={["click", "focus"]} title="copied">
              <div
                onClick={() =>
                  copyingToClipboard(
                    examplesCopyCode
                  )
                }
                className="code-examples-new__button_wrapper"
              >
                <CopyIcon />
              </div>
            </Tooltip>
          </p>
          {examplesCode}
        </div>
      </div>
    </Card>
  )
}

export default injectIntl(CodeExamplesNew)