import React, { useState } from "react";
import styles from "./Sidebar.module.sass";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
// import Theme from "../Theme";
import Dropdown from "./Dropdown";
// import Help from "./Help";
import Image from "../Image";
import { ROUTE_PATH, DOCUMENTATION_PATH } from "../../../constants";
import useGetLandingLinkByAuthorizedUser from "../../../hooks/useGetLandingLinkByAuthorizedUser";
import hybridEngineLogo from "../../../resources/images/hybridEngineLogo.svg";
interface IProps {
  className?: string;
  onClose: VoidFunction;
}

const Sidebar: React.FC<IProps> = ({ className, onClose }) => {
  // const [visibleHelp, setVisibleHelp] = useState(false);

  const { landingLink } = useGetLandingLinkByAuthorizedUser();
  const [visible, setVisible] = useState(false);

  const navigation = [
    {
      title: "Home",
      icon: "home",
      url: ROUTE_PATH.ROOT,
    },
    {
      title: "Endpoints",
      icon: "diamond",
      url: ROUTE_PATH.RPC_PANEL,
    },
    {
      title: "Plans and packages",
      slug: "plansAndPackages",
      icon: "store",
      dropdown: [
        {
          title: "Pricing Plans",
          url: ROUTE_PATH.PRICING_PLANS,
          noIcon: true,
        },
        // {
        //   title: "Resource Packages",
        //   url: ROUTE_PATH.RESOURCE_PACKAGES,
        // },
      ],
    },
    {
      title: "Billing",
      icon: "pie-chart",
      url: ROUTE_PATH.BILLING,
    },
    {
      title: "Documentation",
      slug: "documentation",
      icon: "promotion",
      url: `${landingLink}${DOCUMENTATION_PATH}` || "/documentation",
    },
  ];

  const { pathname } = useLocation();

  return (
    <>
      <div
        className={cn(styles.sidebar, className, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        {/* <Link className={styles.logo} to="/" onClick={onClose}> */}
        <div className={styles.logoBlock}>
            <Image
              className={styles.logo}
              src="/images/logo-dark.svg"
              srcDark="/images/logo-light.svg"
              alt="Core"
            />
            <img style={ visible ? { display: "block" } : {  }} src={hybridEngineLogo} className={styles.hybridLogo} alt="" />
          </div>
        {/* </Link> */}
        <div className={styles.menu}>
          {navigation.map((x, index) =>
            x.url ? (
              <NavLink
                className={cn(styles.item, {
                  [styles.active]: pathname === x.url,
                })}
                to={x.url}
                key={index}
                onClick={onClose}
              >
                <Icon name={x.icon} size="24" />
                {x.title}
              </NavLink>
            ) : (
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                onClose={onClose}
              />
            )
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        {/* <div className={styles.foot}>
                    <button
                        className={styles.link}
                        onClick={() => setVisibleHelp(true)}
                    >
                        <Icon name="help" size="24" />
                        Help & getting started
                        <div className={styles.counter}>8</div>
                    </button>
                    <Theme className={styles.theme} visibleSidebar={visible} />
                </div> */}
      </div>
      {/* <Help
                visible={visibleHelp}
                setVisible={setVisibleHelp}
                onClose={onClose}
            /> */}
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
