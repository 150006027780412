import { useGetProfileQuery } from "../api/reg-service.api";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { ROUTE_PATH, isSnapshot } from "../constants";

type TComponentProps = any;
interface IHocProps {
  disableAuthRedirect?: boolean;
}

const withGoogleAccountUserRedirect =
  (Component: React.FC) => (props: TComponentProps & IHocProps) => {
    const {
      data: profile,
      error: profileError,
      isLoading,
    } = useGetProfileQuery();

    const navigate = useNavigate();

    const redirectCondition = useMemo(() => {
      return !!(profile && !profileError && !isSnapshot) && profile.oauthSub;
    }, [profile, profileError]);

    useEffect(() => {
      if (redirectCondition) {
        navigate(ROUTE_PATH.ROOT);
      }
    }, [navigate, redirectCondition]);

    if (!isSnapshot && (isLoading || redirectCondition)) {
      return <div className="loader"></div>;
    }
    return <Component {...props} />;
  };

export default withGoogleAccountUserRedirect;
