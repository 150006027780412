import { useCallback, useEffect, useRef, useState } from "react";
import {
  ApiEndpointQuery,
  BaseQueryFn,
  QueryDefinition,
} from "@reduxjs/toolkit/query";
import { QueryHooks } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { checkAvailabilityTs } from "../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  createPending,
  deletePending,
  selectPending,
} from "../reducers/pendings.slice";

const useQueryWithPolling = (
  query: ApiEndpointQuery<QueryDefinition<void, BaseQueryFn, any, any>, {}> &
    QueryHooks<QueryDefinition<void, BaseQueryFn, any, any>>,
  storageKey: string,
  pickData: (data: any) => any,
  compareData: (data: any, prevData: any) => boolean
) => {
  const [isPoll, setIsPoll] = useState(false);
  const { data, error, isLoading, refetch } = query.useQuery();
  const intervalId = useRef<NodeJS.Timer>();
  const dispatch = useDispatch();
  const pending = useSelector(selectPending(storageKey as any));

  useEffect(() => {
    if (
      pending &&
      checkAvailabilityTs(pending.ts, { frequency: 10, interval: "minute" })
    ) {
      setIsPoll(true);
    }
  }, [pending]);

  useEffect(() => {
    if (isPoll)
      intervalId.current = setInterval(() => {
        refetch();
      }, 10000);
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = undefined;
      }
    };
  }, [isPoll, refetch]);

  const startPolling = useCallback(
    (trigger: "creation" | "cancellation", force?: boolean) => {
      setIsPoll(true);

      const savedData = pickData
        ? pickData(data || {})
        : {
            id: data?.id,
          };
      dispatch(
        createPending({
          type: storageKey as any,
          pending: {
            ts: new Date().getTime(),
            trigger,
            ...savedData,
          },
        })
      );
      if (force) refetch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storageKey, data, refetch]
  );

  const stopPolling = useCallback(() => {
    setIsPoll(false);
    dispatch(deletePending(storageKey as any));
  }, [dispatch, storageKey]);

  useEffect(() => {
    if (isLoading && !data && !error) return;
    if (pending && compareData(data, pending)) {
      stopPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading, pending, stopPolling]);

  return {
    startPolling,
    stopPolling,
  };
};

export default useQueryWithPolling;
