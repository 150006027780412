import { FC, ReactNode, useMemo } from "react";
import { injectIntl } from "react-intl";
import "./PlanCard.sass";
import useToken from "antd/es/theme/useToken";
import { Button, Typography } from "antd";
import check from "../../resources/images/circle_check_violet.svg";
import { IBaseComponentProps } from "../../types";
import DependsOnLengthSize from "../DependsOnLengthSize/DependsOnLengthSize";
import formatMessage from "../../utils/formatMessage";
import WindowWithEmailAddress from "../../hocs/windowWithEmailAddress/windowWithEmailAddress";
import { CONTACT_EMAIl, EXTERNAL_LINKS } from "../../constants";

type TProps = {
  title: string;
  description: string;
  checkItems?: string[];
  buttonTitle?: string;
  buttonDisabled?: boolean;
  price?: string;
  renderPrice?: () => ReactNode;
  buttonType?: "default" | "link" | "text" | "primary" | "dashed";
  buttonClick?: () => void;
  isButtonLinkToEmail?: boolean;
  subTitle: string;
  additionalDescription?: string;
  isActiveTailoredPlan?: boolean
} & IBaseComponentProps;

export const PLANCARD_MONTH = { id: "plans-list.plan-card-month" };

const PlanCard: FC<TProps> = ({
  title,
  description,
  className,
  checkItems,
  buttonTitle,
  price,
  buttonType,
  buttonClick,
  buttonDisabled,
  isButtonLinkToEmail,
  subTitle,
  additionalDescription,
  intl,
  renderPrice,
  isActiveTailoredPlan
}) => {
  const [, token] = useToken();

  const button = useMemo(() => {
    if (isButtonLinkToEmail)
      return (
        // <a href={`mailto:${CONTACT_EMAIl}`}>
        <WindowWithEmailAddress>
          <Button
            disabled={buttonDisabled}
            type={buttonType}
            className="plan-card__button"
          >
            {buttonTitle}
          </Button>
        </WindowWithEmailAddress>
        // </a>
      );
    else
      return (
        <Button
          disabled={buttonDisabled}
          onClick={buttonClick}
          type={buttonType}
          className="plan-card__button"
        >
          {buttonTitle}
        </Button>
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonDisabled, buttonTitle, buttonType, isButtonLinkToEmail]);

  return (
    <div
      style={{ backgroundColor: token.colorPrimaryBg }}
      className={`plan-card ${className} `}
    >
      <div className="plan-card__content">
        <div className={`${isActiveTailoredPlan && "plan-card__content_topblock"}`}>
          <Typography className="plan-card__title">{title}</Typography>
          <p className="plan-card__subtitle">{subTitle}</p>
          <p className="plan-card__description">{description}</p>
          {additionalDescription && (
            <p className="plan-card__additional_description">
              {additionalDescription}
            </p>
          )}
        </div>
        {price && (
          <span className="plan-card__price">
            <DependsOnLengthSize
              defaultSize={48}
              numberOfCharactersIsNotAllowed={8}
              ratio={0.67}
            >
              {`${price || ""}$`}
            </DependsOnLengthSize>{" "}
            <span className="plan-card__price_part">
              /{formatMessage(intl, PLANCARD_MONTH)}
            </span>
          </span>
        )}
        {renderPrice && renderPrice()}
        <div className="plan-card__check_blocks">
          {checkItems &&
            checkItems.map((el: string) => {
              return (
                <div key={el} className="plan-card__check_blocks_block">
                  <img src={check} alt="" />
                  <p>{el}</p>
                </div>
              );
            })}
        </div>
      </div>
      <div className="plan-card__button_wrapper">
        {buttonTitle && button}
      </div>
      {isActiveTailoredPlan && <div className="plan-card__support">
        <span className="plan-card__support_title">Get Support</span>
        <span>Telegram:{" "}
          <a href={EXTERNAL_LINKS.TELEGRAM} target="_blank" rel="noreferrer" className="plan-card__support_link">
            @instantnodes
          </a>
        </span>
        <span>Mail:{" "}
          <a href={`mailto:${CONTACT_EMAIl}`} className="plan-card__support_link">
            support@instantnodes.io
          </a>
        </span>
      </div>}
    </div>
  );
};

export default injectIntl(PlanCard);
