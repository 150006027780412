import React from "react";
import useDarkMode from "../../hooks/useDarkMode";

interface IProps {
  className?: string;
  src: string;
  srcDark: string;
  srcSet?: string;
  srcSetDark?: string;
  alt?: string;
}

const Image: React.FC<IProps> = ({
  className,
  src,
  srcDark,
  srcSet,
  srcSetDark,
  alt,
}) => {
  const darkMode = useDarkMode(true, { storageKey: "" });
  return (
    <img
      className={className}
      srcSet={darkMode.value ? srcSetDark : srcSet}
      src={darkMode.value ? srcDark : src}
      alt={alt}
    />
  );
};

export default Image;
