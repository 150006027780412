import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
// import { Link } from "react-router-dom";
import Icon from "../Icon";
import Messages from "./Messages";
import Notification from "./Notification";
import User from "./User";
import { EXTERNAL_LINKS } from '../../../constants'

const socials = [
  {
    title: "Facebook",
    icon: "linked",
    viewBox: "24",
    url: EXTERNAL_LINKS.LINKED,
  },
  {
    title: "Twitter",
    icon: "twitter",
    url: EXTERNAL_LINKS.TWITTER,
  },
  // {
  //   title: "Instagram",
  //   icon: "instagram",
  //   url: "https://www.instagram.com/ui8net/",
  // },
];

const Header = ({ onOpen }) => {
  const [, setVisible] = useState(false);
  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  return (
    <header className={styles.header}>
      <button className={styles.burger} onClick={() => handleClick()}></button>
      {/* <Search className={cn(styles.search, { [styles.visible]: visible })} /> */}
      <div className={styles.btns}>
          {socials.map((x, index) => (
            <a
              className={cn("button-gray", styles.socialButton)}
              href={x.url}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <Icon name={x.icon} size="24" viewBox={x.viewBox || "16"} />
              {/* <span>{x.title}</span> */}
            </a>
          ))}
        </div>
      {/* <button
        className={cn(styles.buttonSearch, { [styles.active]: visible })}
        onClick={() => setVisible(!visible)}
      >
        <Icon name="search" size="24" />
      </button> */}
      <div className={styles.control} onClick={() => setVisible(false)}>
        <a className={cn("button", styles.button)} target="_blank" href={EXTERNAL_LINKS.TELEGRAM} rel="noreferrer">
          <Icon name="add" size="24" />
          <span>Support</span>
        </a>
        <Messages className={styles.messages} />
        <Notification className={styles.notification} />
        <User className={styles.user} />
      </div>
      {/* <div className={styles.btns}>
        <Link className={styles.link} to="/sign-in">
          Sign in
        </Link>
        <Link className={cn("button", styles.button)} to="/sign-up">
          Sign up
        </Link>
      </div> */}
    </header>
  );
};

export default Header;
