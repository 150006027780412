import { useContext } from "react";
import { GoogleOAuthContext } from "../providers/GoogleGsiWrapper";

const useGoogleOAuth = () => {
  const context = useContext(GoogleOAuthContext);
  if (!context) {
    throw new Error("Components must be used within GoogleOAuthProvider");
  }
  return context;
};

export default useGoogleOAuth;
