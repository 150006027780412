import { IGetCurrentPlanResponse } from "../api/types/get-current-plan";
import { IGetCurrentSubscriptionResponse } from "../api/types/get-subscription";
import { TPlan } from "../entities/plan";
import { TSubscription } from "../entities/subscription";
import { EPlanType } from "../hooks/usePlanType";
import { getExpireSubscriptionDate } from "../hooks/useSubscriptionDetails";

export function createFallbackView(
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentPlanFromSubscription: TPlan | undefined
): Omit<TSubscription, "discount"> | undefined {
  if (!currentSubscriptionQuery.data) return undefined;
  return {
    ...currentSubscriptionQuery.data,
    planFromSubscription: currentPlanFromSubscription,
  };
}
export function isCancelledSubscription(
  currentPlanQuery: {
    data?: IGetCurrentPlanResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  }
) {
  return (
    currentSubscriptionQuery.data &&
    (currentSubscriptionQuery.data.status === "canceled" ||
      !currentSubscriptionQuery.data.nextBilledAt) &&
    currentPlanQuery.data?.id === currentSubscriptionQuery.data.planId
  );
}
export function createCancelledView(
  currentPlanQuery: {
    data?: IGetCurrentPlanResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentPlanFromSubscription: TPlan | undefined
) {
  if (!currentSubscriptionQuery.data) return undefined;

  const nextPaymentMs = currentPlanQuery.data?.validUntil // NOTE: if already beginner??
    ? new Date(currentPlanQuery.data.validUntil).getTime()
    : getExpireSubscriptionDate(currentSubscriptionQuery.data);

  const alreadyCanceled = new Date() > new Date(nextPaymentMs);
  return {
    ...currentSubscriptionQuery.data,
    nextBilledAt: "doNotDisplay", //changed
    billingCycle: undefined,
    endDate: nextPaymentMs,
    status: alreadyCanceled ? currentSubscriptionQuery.data.status : "active",
    planFromSubscription: currentPlanFromSubscription,
  };
}
export function createTailoredView(
  currentPlanQuery: {
    data?: IGetCurrentPlanResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentPlanFromSubscription: TPlan | undefined
): Omit<TSubscription, "discount"> | undefined {
  if (!currentPlanQuery.data) return undefined;
  return {
    nextBilledAt: currentPlanQuery.data.validUntil //changed
      ? currentPlanQuery.data.validUntil //changed
      : undefined, //changed
    firstBilledAt: currentPlanQuery.data.validFrom,
    status: "active",
    // endDate: currentPlanQuery.data.validUntil  //changed
    //   ? new Date(currentPlanQuery.data.validUntil).getTime() //changed
    //   : undefined, //changed
    planFromSubscription: currentPlanFromSubscription,
  };
}
export function createDefaultView(
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentPlanQuery: {
    data?: IGetCurrentPlanResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentPlanFromSubscription: TPlan | undefined
): Omit<TSubscription, "discount"> | undefined {
  return {
    billingCycle: currentSubscriptionQuery.data
      ? currentSubscriptionQuery.data.billingCycle
      : {
          frequency: 1,
          interval: "month",
        },
    firstBilledAt:
      currentSubscriptionQuery.data?.status === "past_due"
        ? currentSubscriptionQuery.data?.firstBilledAt
        : currentPlanQuery.data?.validFrom,
    status:
      currentSubscriptionQuery.data &&
      currentSubscriptionQuery.data.status === "past_due"
        ? currentSubscriptionQuery.data.status
        : "active",
    planFromSubscription: currentPlanFromSubscription,
  };
}
export function isTailoredPlan(planType: EPlanType) {
  return planType === EPlanType.TAILORED;
}
export function isDefaultPlan(planType: EPlanType) {
  return planType === EPlanType.DEFAULT;
}

export function isStandardOrNewStandard(planType: EPlanType) {
  return planType === EPlanType.STANDARD || planType === EPlanType.NEW_STANDARD;
}

export function isResponseFirstLoading(query: {
  data?: any;
  error?: unknown;
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
}) {
  return !query.data && !query.error && query.isLoading;
}
