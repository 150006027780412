import { injectIntl } from "react-intl";
import cn from "classnames";
import { IBaseComponentProps } from "../../types";
import "./TailoredCard.sass";
import WindowWithEmailAddress from "../../hocs/windowWithEmailAddress/windowWithEmailAddress";

interface IProps extends IBaseComponentProps { }

const TailoredCard: React.FC<IProps> = ({ className }) => {
  return (
    <div className={cn("tailored-card", className)}>
      <div className="tailored-card__row">
        <div className="tailored-card__title h1">Need More?</div>
        <div className="tailored-card__text">
          Our <span>Tailored plan</span> offers unlimited access to everything
          you need, with powerful features to boost performance. Effortlessly
          handle 60,000+ RPS with a scalable infrastructure designed for
          millions of users.
        </div>
      </div>
      <WindowWithEmailAddress className="tailored-card__row">
        <button className="button tailored-card__button">
          Request a Custom Quote
        </button>
      </WindowWithEmailAddress>
    </div>
  );
};

export default injectIntl(TailoredCard);
