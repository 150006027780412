import { FC, useMemo } from "react";
import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../types";
import Card from "../../ui-kit/components/Card";
import cn from "classnames";
import "./PlanDescription.sass";
import { Typography } from "antd";
import {
  useGetCurrentPlanQuery,
  useGetCurrentSubscriptionQuery,
} from "../../api/reg-service.api";
import useAvailablePlans from "../../hooks/useAvailablePlans";
import useGetPlanFromSubscriptions from "../../hooks/useGetPlanFromSubscriptions";
import useSubscriptionDetails from "../../hooks/useSubscriptionDetails";
import { countLettersBeforeSecondUpper } from "../../utils/utils";
import useGetDataByTariff from "../../hooks/useGetDataByTariff";

type TProps = {} & IBaseComponentProps;

const PlanDescription: FC<TProps> = ({ intl, className }) => {
  const currentPlanQuery = useGetCurrentPlanQuery();
  const planName = currentPlanQuery.data?.name;
  const { defaultPlan } = useAvailablePlans({});
  const currentSubscriptionQuery = useGetCurrentSubscriptionQuery();
  const { currentPlanFromSubscription } = useGetPlanFromSubscriptions();
  const { subscription } = useSubscriptionDetails(
    currentPlanQuery,
    currentSubscriptionQuery,
    currentPlanFromSubscription
  );

  const planNameJsx = useMemo(() => {
    const count = countLettersBeforeSecondUpper(planName || "");
    if (planName && !planName.includes("New Standard"))
      return (
        <Typography className="plan-description__plan-name">
          {planName.slice(0, count)}
          <p className="plan-description__plan-name_chunk">
            {planName.slice(count)}
          </p>
        </Typography>
      );
    else
      return (
        <Typography className="plan-description__plan-name">
          {planName}
        </Typography>
      );
  }, [planName]);

  const { data: { dataTransfer, requestsDay, requestsMonth, sockets } } = useGetDataByTariff()

  const rps = useMemo(() => {
    if (subscription && subscription.status === "past_due") {
      return defaultPlan?.rps;
    } else return currentPlanQuery.data?.rps;
  }, [currentPlanQuery.data?.rps, defaultPlan?.rps, subscription]);

  const items = useMemo(() => {
    return [
      {
        value: planNameJsx,
        title: "Plan",
        tooltip: "",
      },
      {
        isLine: true,
      },
      {
        value: rps,
        title: "RPS",
        tooltip: "",
      },
      {
        isLine: true,
      },
      {
        value: `${requestsMonth?.value
          }${requestsMonth?.currency}`,
        title: "Requests/M",
        tooltip: "",
      },
      {
        isLine: true,
      },
      {
        value: `${requestsDay?.value
          }${requestsDay?.currency}`,
        title: "Requests/D",
        tooltip: "",
      },
      {
        isLine: true,
      },
      {
        value: `${dataTransfer?.value
          }${dataTransfer?.currency}`,
        title: "Data transfer",
        tooltip: "",
      },
      {
        isLine: true,
      },
      {
        value: `${sockets?.value
          }${sockets?.currency}`,
        title: "Sockets",
        tooltip: "",
      },
    ];
  }, [
    planNameJsx,
    rps,
    requestsMonth?.value,
    requestsMonth?.currency,
    requestsDay?.value,
    requestsDay?.currency,
    dataTransfer?.value,
    dataTransfer?.currency,
    sockets?.value,
    sockets?.currency
  ]);
  return (
    <Card
      className={cn("plan-description", className)}
      title={""}
      classTitle={""}
    >
      <div>
        <div className="plan-description__list">
          {items.map((x, index) => {
            if (x.isLine) return <div className="plan-description__line"></div>;
            return (
              <div className="plan-description__item" key={index}>
                <div className="plan-description__title">
                  {x.title}
                  {/* <Tooltip
                    className="plan-description__tooltip"
                    title={x.tooltip}
                    icon="info"
                    place="top"
                  /> */}
                </div>
                <div className="plan-description__label">{x.value}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

export default injectIntl(PlanDescription);
