export type TariffKeys = "HybridN125" | "HybridN225" | "HybridN425" | "HybridN1500" | "Beginner" | "NewStandard";
export type TPlanValues = {
  requestsPerMonth: string,
  requestsPerDay: string,
  requestsPerSecond: string,
  transferIncluded: string,
  transferIncludedBytes: string,
  webSocketsConnections: string,
}
export const PLANS_LIST_FREE = { id: "plans-list.free" };
export const PLANS_LIST_YOUR_ACTIVE_SUBSCRIPTION = {
  id: "plans-list.your-active-subscription",
};
export const PLANS_LIST_PLANCARD_MONTH = { id: "plans-list.plan-card-month" };
export const PLANS_LIST_DISCOUNT = {
  id: "plans-list.tax-discount",
};
export const PLANS_LIST_TAX = {
  id: "plans-list.tax",
};

export const PLANS_LIST_DISCOUNT_FIRST = {
  id: "plans-list.tax-discount-first",
};
export const PLANS_LIST_TAILORED = { id: "plans-list.tailored" };


export const additionalDataAboutPaidTariffs: Record<
  TariffKeys,
  TPlanValues
> = {
  NewStandard: {
    requestsPerMonth: "11.25",
    requestsPerDay: "375",
    requestsPerSecond: "125",
    transferIncluded: "1",
    transferIncludedBytes: "1,1e+12",
    webSocketsConnections: "10",
  },
  Beginner: {
    requestsPerMonth: "2",
    requestsPerDay: "50",
    requestsPerSecond: "10",
    transferIncluded: "100",
    transferIncludedBytes: "107374182400",
    webSocketsConnections: "1",
  },
  HybridN125: {
    requestsPerMonth: "11.25",
    requestsPerDay: "375",
    requestsPerSecond: "125",
    transferIncluded: "1",
    transferIncludedBytes: "1,1e+12",
    webSocketsConnections: "10",
  },
  HybridN225: {
    requestsPerMonth: "50",
    requestsPerDay: "4",
    requestsPerSecond: "225",
    transferIncluded: "5",
    transferIncludedBytes: "5,5e+12",
    webSocketsConnections: "15",
  },
  HybridN425: {
    requestsPerMonth: "100",
    requestsPerDay: "9",
    requestsPerSecond: "425",
    transferIncluded: "20",
    transferIncludedBytes: "2,2e+13",
    webSocketsConnections: "30",
  },
  HybridN1500: {
    requestsPerMonth: "500",
    requestsPerDay: "17.5",
    requestsPerSecond: "1500",
    transferIncluded: "40",
    transferIncludedBytes: "4,4e+13",
    webSocketsConnections: "75",
  },
};

export const beginnerCheckItems = [
  "Solana dedicated nodes",
  "Up to 2 million requests per month",
  "Up to 50 thousand requests per day",
  "Up to 10 requests per second",
  "100 GB data transfer included",
  "1 WebSocket connection",
  "Heavy Requests (x1000 faster)",
  "Medium Request (x10 faster)",
  "Light Requests (x2.5 faster)",
  "Full Historical Archive Data",
  "Auto-failover redirects traffic during issues",
  {
    text: "Geyser Internal Boost",
    hint: "We do not sell Geyser as an extra service. You get even better boost when using our Hybrid solution for free.",
  },
]

export const tailoredCheckItems = [
  "Solana dedicated nodes",
  "Up to unlimited requests per month",
  "Up to unlimited requests per day",
  "Up to unlimited per second",
  "unlimited data transfer included",
  "unlimited WebSocket connections",
  "Heavy Requests (x1000 faster)",
  "Medium Request (x10 faster)",
  "Light Requests (x2.5 faster)",
  "Full Historical Archive Data",
  "Auto-failover redirects traffic during issues",
  {
    text: "Geyser Internal Boost",
    hint: "We do not sell Geyser as an extra service. You get even better boost when using our Hybrid solution for free.",
  },
  "Solana Geyser Plugin upon request",
]
export { default } from "./PlansList";
