import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../types";
import cn from "classnames";
import styles from "./PurchasedPlan.module.sass";
import Parameter from "../../ui-kit/screens/Refunds/Row/Details/Parameter";
import usePlanParameters from "../../hooks/usePlansParameters";
import { TSubscription } from "../../entities/subscription";
import {
  regServiceApi,
  useCancelSubscriptionMutation,
  useGetCurrentPlanQuery,
  useGetCurrentSubscriptionQuery,
  useGetDiscountCodesByLandingIdQuery,
  useGetTransactionIdMutation,
} from "../../api/reg-service.api";
import Icon from "../../ui-kit/components/Icon";
import { useRef, useState } from "react";
import formatMessage from "../../utils/formatMessage";
import useSubscriptionEdit from "../../hooks/useSubscriptionEdit";
import useQueryWithPolling from "../../hooks/useQueryWithPolling";
import {
  FEATURE_UPDATE_PAYMENT,
  PLAN_TS_STORAGE_KEY,
  SUBSCRIPTION_TS_STORAGE_KEY,
} from "../../constants";
import { pick } from "ramda";
import { useDispatch } from "react-redux";
import CheckoutModal from "../CheckoutModal";
import useGetLandingLinkByAuthorizedUser from "../../hooks/useGetLandingLinkByAuthorizedUser";
import notificationView from "../../utils/notification";
import { TPlan } from "../../entities/plan";
import useGetSelectedPlan from "../../hooks/useGetSelectedPlan";
import { countLettersBeforeSecondUpper } from "../../utils/utils";
import Modal from "../../ui-kit/components/Modal";
import AcceptModal from "../AcceptModal";
import { useOutsideClick } from "../../hooks/useOutsideClick";

import iconRetry from "../../resources/images/retry-payment-with-same-card.svg";
import iconUpdate from "../../resources/images/update-payment-method.svg";
import iconCancel from "../../resources/images/cancel-subscription.svg";
interface IProps extends IBaseComponentProps {
  subscription: Omit<TSubscription, "discount"> & {
    endDate?: number;
    planFromSubscription?: TPlan;
  };
  nextBilledPrice: string;
}

interface IItem {
  title: string;
  url?: string;
  color?: string;
  hidden?: boolean;
  icon?: string;
  onClick?: VoidFunction;
}

const SUBSCRIPTION_SETTINGS_MODAL_UPDATE_PAYMENT_DETAILS = {
  id: "subscription-settings-modal.update-payment-details",
};
const SUBSCRIPTION_SETTINGS_MODAL_RETRY_SAME_CARD = {
  id: "subscription-settings-modal.retry-with-the-same-card",
};
const SUBSCRIPTION_SETTINGS_MODAL_CANCEL_SUBSCRIPTION = {
  id: "subscription-settings-modal.cancel-subscription",
};

const PurchasedPlan: React.FC<IProps> = ({
  subscription,
  nextBilledPrice,
  className,
  intl,
}) => {
  const dispatch = useDispatch();
  const [visibleCheckout, setVisibleCheckout] = useState(false);
  const [visibleAcceptModal, setVisibleAcceptModal] = useState(false);
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [visibleModalPaymentUpdates, setVisibleModalPaymentUpdates] =
    useState(false);

  const buttonRef = useRef(null);

  useOutsideClick(buttonRef, () => setVisibleMenu(false));

  const parameters = usePlanParameters(subscription, nextBilledPrice);
  const currentPlanQuery = useGetCurrentPlanQuery();
  const currentSubscriptionQuery = useGetCurrentSubscriptionQuery();
  const { canEdit, disabledCancel, disabledUpdate } = useSubscriptionEdit(
    currentPlanQuery,
    currentSubscriptionQuery
  );

  const [cancelSubscription] = useCancelSubscriptionMutation();
  const [
    getTransactionId,
    { data: getTransactionIdData, isLoading: isGetTransactionIdLoading },
  ] = useGetTransactionIdMutation();

  const transactionId = getTransactionIdData?.transactionId;

  const { selectedPlan } = useGetSelectedPlan();

  const { landingId } = useGetLandingLinkByAuthorizedUser();

  const { data: discountCodes } = useGetDiscountCodesByLandingIdQuery({
    landingId,
  });

  const plan = subscription.planFromSubscription;

  const comparePlan = (data?: TPlan, prevData?: TPlan) => {
    return !!(
      data?.id !== prevData?.id || data?.validUntil !== prevData?.validUntil
    );
  };

  const { startPolling: startPollingCurrentPlan } = useQueryWithPolling(
    regServiceApi.endpoints.getCurrentPlan,
    PLAN_TS_STORAGE_KEY,
    pick(["id", "validUntil"]),
    comparePlan
  );

  const compareSubscription = (
    data: TSubscription,
    prevData: TSubscription
  ) => {
    return !!(
      data?.id !== prevData?.id ||
      data?.status !== prevData?.status ||
      data?.nextBilledAt !== prevData?.nextBilledAt
    );
  };

  const { startPolling: startPollingCurrentSubscription } = useQueryWithPolling(
    regServiceApi.endpoints.getCurrentSubscription,
    SUBSCRIPTION_TS_STORAGE_KEY,
    pick(["id", "status", "nextBilledAt"]),
    compareSubscription
  );

  const onCancelSubscription = async () => {
    await cancelSubscription().then(() =>
      startPollingCurrentSubscription("cancellation", true)
    );
  };

  const showModal = () => {
    dispatch(regServiceApi.util.invalidateTags(["priceItem"]));
    setVisibleCheckout(true);
  };

  const onUpdateSubscription = async () => {
    await getTransactionId().unwrap();
    showModal();
    setVisibleModalPaymentUpdates(true);
  };

  const checkoutModalComplete = () => {
    if (visibleModalPaymentUpdates) {
      notificationView(
        "info",
        "Your payment details have been successfully updated"
      );
      return;
    }
    startPollingCurrentPlan("creation");
    startPollingCurrentSubscription("creation");
  };

  const disableRetry = subscription?.status !== "past_due";

  const items: {
    menu: IItem[];
  }[] =
    FEATURE_UPDATE_PAYMENT === "true"
      ? [
          {
            menu: [
              {
                title: formatMessage(
                  intl,
                  SUBSCRIPTION_SETTINGS_MODAL_RETRY_SAME_CARD
                ),
                hidden: disableRetry,
                onClick: onUpdateSubscription,
                icon: iconRetry,
              },
              {
                title: formatMessage(
                  intl,
                  SUBSCRIPTION_SETTINGS_MODAL_UPDATE_PAYMENT_DETAILS
                ),
                hidden: disabledUpdate,
                onClick: onUpdateSubscription,
                icon: iconUpdate,
              },
              {
                title: formatMessage(
                  intl,
                  SUBSCRIPTION_SETTINGS_MODAL_CANCEL_SUBSCRIPTION
                ),
                hidden: disabledCancel,
                onClick: () => setVisibleAcceptModal(true),
                icon: iconCancel,
              },
            ],
          },
        ]
      : [
          {
            menu: [
              {
                title: formatMessage(
                  intl,
                  SUBSCRIPTION_SETTINGS_MODAL_CANCEL_SUBSCRIPTION
                ),
                hidden: disabledCancel,
                onClick: () => setVisibleAcceptModal(true),
                icon: iconCancel,
              },
            ],
          },
        ];

  const count = countLettersBeforeSecondUpper(plan?.name || "");
  const status = subscription.status;
  return (
    <div
      className={cn(styles.root, className, {
        [styles.activeBody]: visibleMenu,
      })}
    >
      <div className={styles.row}>
        <div className={styles.title}>
          <span>{plan?.name?.slice(0, count)}</span>
          <span className={styles.chunk}>{plan?.name?.slice(count)}</span>
        </div>
        <div className={cn(styles.status, styles[status])}>{status}</div>
      </div>
      <div className={styles.parameters}>
        {parameters.map((el, index) => (
          <Parameter key={index} item={el} />
        ))}
      </div>
      <button
        ref={buttonRef}
        className={cn("button button-gray", styles.button, {
          disabled: !canEdit,
        })}
        onClick={() => setVisibleMenu(true)}
        disabled={!canEdit}
      >
        <span>Settings</span>
        <Icon name="setting" size="24" />
      </button>
      <div className={styles.body}>
        {items.map((item, index) => (
          <div className={styles.menu} key={index}>
            {item.menu.map((x, index) => (
              <button
                className={cn(styles.item, {
                  [styles.disabled]: x.hidden,
                })}
                disabled={x.hidden}
                onClick={() => {
                  if (x.onClick) x.onClick();
                  setVisibleCheckout(false);
                }}
                key={index}
              >
                <img src={x.icon} alt="" />
                {x.title}
              </button>
            ))}
          </div>
        ))}
      </div>
      <Modal
        visible={visibleAcceptModal}
        outerClassName={styles.modal}
        onClose={() => setVisibleAcceptModal(false)}
      >
        <AcceptModal handleCancel={onCancelSubscription} />
      </Modal>
      <CheckoutModal
        isLoading={isGetTransactionIdLoading}
        transactionIdProps={
          visibleModalPaymentUpdates ? transactionId : undefined
        }
        selectedPlan={selectedPlan}
        discountCodes={discountCodes}
        visible={visibleCheckout}
        onClose={() => {
          setVisibleCheckout(false);
          setVisibleModalPaymentUpdates(false);
        }}
        onComplete={checkoutModalComplete}
      />
    </div>
  );
};

export default injectIntl(PurchasedPlan);
