/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form } from "antd";
import "./register-new.page.sass";
import { IBaseComponentProps } from "../../types";
import {
  useGetPlanByIdQuery,
  useRegisterMutation,
} from "../../api/reg-service.api";
import { ReactNode, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTE_PATH } from "../../constants";
import useGetLandingLinkByAuthorizedUser from "../../hooks/useGetLandingLinkByAuthorizedUser";
import formatMessage from "../../utils/formatMessage";
import { responsePathOr } from "../../utils/utils";
import cn from "classnames";
import { injectIntl } from "react-intl";
// import Icon from "../../ui-kit/components/Icon";
// import { ClosedEye, OpenedEye } from "../../resources/icons/password-eye";
import Image from "../../ui-kit/components/Image";
import TextInput from "../../ui-kit/components/TextInput";
// import ReCAPTCHA from "react-google-recaptcha";
// import { register } from "react-scroll/modules/mixins/scroller";
// import InputPassword from "../../components/InputPassword";
import {
  LANDING_FOOTER_TERMS_OF_SERVICE,
  LANDING_FOOTER_PRIVACY_POLICE,
} from "../../components/LandingFooter/LandingFooter";
import { useTimer } from "../../hooks/useTimer";
import Checkbox from "../../ui-kit/components/Checkbox";
import illustration from "../../resources/images/illustration.png";
import geenCheck from "../../resources/images/green-check.svg";
import GoogleButton from "../../components/GoogleButton";


interface IProps extends IBaseComponentProps {
  onSuccessCb?: () => void;
  iswithVerificationStage?: boolean;
}

interface IFormInput {
  email: string;
  password: string;
}
const key =
  (window as Window & typeof globalThis & { env: Record<string, string> }).env
    .SITEKEY_FOR_CAPTCHA || "";
const ACCEPT = { id: "register-form.accept" };

const REGISTRATION_CONFIRM_PAGE_UPDATE_EMAIL = {
  id: "registration-confirm-page.update-email",
};

// const openCloseIcons = (visible: boolean) =>
//   visible ? <Icon component={OpenedEye} /> : <Icon component={ClosedEye} />;

const RegisterPageNew: React.FC<IProps> = ({
  className,
  intl,
  onSuccessCb,
  iswithVerificationStage,
}) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const planId = searchParams.get("plan-id") || "";
  const { formattedTime, start, count, reset } = useTimer(80);
  const [chooseAll, setСhooseAll] = useState(false);
  // const captchaRef = useRef<any>(null); /// captcha is required
  const [register, { isLoading }] = useRegisterMutation();
  const { landingId, landingLink } = useGetLandingLinkByAuthorizedUser();
  const { data: currentPlanFromSubscription, isLoading: isGetPlanByIdLoading } =
    useGetPlanByIdQuery(
      { planId: +planId, landingId: +landingId },
      { skip: !planId }
    );
  const discountAmount = currentPlanFromSubscription?.discountPercentage || 0;
  const [captchaIsPassed, setCaptchaIsPassed] = useState(false);
  const [form] = Form.useForm();
  const [formValid, setFormValid] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [submitError, setSubmitError] = useState<string>();
  const [isRegistrationConfirm, setIsRegistrationConfirm] =
    useState<boolean>(false);

  const hanldeError = (err: any) => {
    setSubmitError(responsePathOr(err));
  };

  const onSubmit = async (values: IFormInput) => {
    try {
      window.gtag && window.gtag("event", "sign_up");
      await register({
        ...values,
        email: values.email.toLowerCase(),
        landingId: landingId,
      }).unwrap();
      handleSuccess();
    } catch (err) {
      hanldeError(err);
    }
  };

  const handleSuccess = () => {
    onSuccessCb && onSuccessCb();
    iswithVerificationStage && setIsRegistrationConfirm(true);
    reset();
    start();
  };

  // const handleChangeCaptcha = (value: string | null) => {      /// captcha is required
  //   setCaptchaIsPassed(true);
  // };
  // const onExpired = () => {     /// captcha is required
  //   setCaptchaIsPassed(false);
  // };

  const onErrored = () => {
    setCaptchaIsPassed(false);
  };

  const updateEmail = () => {
    onErrored();
    setIsRegistrationConfirm(false);
    reset();
  };

  const resendSubmit = () => {
    form.submit();
  };
  const disabled = formValid || !isFocused || isLoading;
  //  || !captchaIsPassed;    /// captcha is required
  const resendButtonDisabled = !!count || isLoading;

  if (planId && isGetPlanByIdLoading) return null;

  return (
    <div className="register-page-new">
      {!discountAmount ? null : (
        <div className="register-page-new__discount-block">
          <div className="register-page-new__discount-block_content">
            <Image
              className="register-page-new__discount-block_logo"
              src="/images/logo-dark.svg"
              srcDark="/images/logo-light.svg"
              alt="Core"
            />
            <img
              className="register-page-new__discount-block_img"
              src={illustration}
              alt=""
            />
            <h3 className="register-page-new__discount-block_title">
              Plan includes
            </h3>
            <div className="register-page-new__discount-block_items">
              <div className="register-page-new__discount-block_item">
                <img src={geenCheck} alt="" />
                <p>{discountAmount}% discount applied</p>
              </div>
              <div className="register-page-new__discount-block_item">
                <img src={geenCheck} alt="" />
                <p>1000x faster for heavy requests</p>
              </div>
              <div className="register-page-new__discount-block_item">
                <img src={geenCheck} alt="" />
                <p>10x faster for medium requests</p>
              </div>
              <div className="register-page-new__discount-block_item">
                <img src={geenCheck} alt="" />
                <p>2.5x faster for light requests</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="register-page-new__content">
        <div className="register-page-new__header">
          <div className="register-page-new__link_block">
            <p className="register-page-new__text">
              Already a member?
              <Link
                className="register-page-new__subtitle"
                data-testid={"history-link"}
                to={`${ROUTE_PATH.LOGIN}?landing-id=${landingId}`}
              >
                {" "}
                Sign in
              </Link>
            </p>
          </div>
        </div>
        <Form
          form={form}
          className={cn("register-page-new__form", className)}
          onFinish={onSubmit}
          onFieldsChange={() => {
            if (submitError) setSubmitError("");
          }}
          layout="vertical"
          autoComplete="off"
          onValuesChange={() => {
            setIsFocused(true);
            form
              .validateFields()
              .then((values) => {
                const valuesAvailable = Object.values(values).every(
                  (value) => value
                );
                setFormValid(!valuesAvailable);
              })
              .catch((errorInfo) => {
                const valuesAvailable = Object.values(errorInfo.values).every(
                  (value) => value
                );
                if (valuesAvailable && !errorInfo.errorFields.length) {
                  setFormValid(false);
                } else {
                  setFormValid(true);
                }
              });
          }}
        >
          <Form.Item style={{ marginBottom: 0 }}>
            <Image
              className={`register-page-new__form_logo ${
                planId &&
                discountAmount &&
                "register-page-new__form_logo_additional"
              }`}
              src="/images/logo-dark.svg"
              srcDark="/images/logo-light.svg"
              alt="Core"
            />
            <h2 className="register-page-new__title">Sign up</h2>
            {isRegistrationConfirm ? (
              <>
                <div className="register-page-new__line"></div>
                <p className="login-page-new__subtitle">We sent you an email</p>
              </>
            ) : (
              <>
                <p className="register-page-new__subtitle">
                  Sign up with Google
                </p>
                <GoogleButton
                  className="register-page-new__google-button"
                  onSuccess={handleSuccess}
                  onError={hanldeError}
                  landingId={landingId}
                />
                <div className="register-page-new__line"></div>
                <p className="login-page-new__subtitle">
                  Or continue with email address
                </p>
              </>
            )}
          </Form.Item>

          <Form.Item
            name={"email"}
            rules={[
              {
                type: "email",
              },
            ]}
          >
            <TextInput
              placeholder="Your email"
              readOnly={isRegistrationConfirm}
              name="Email"
              type="email"
              required
              className="register-page-new__input"
              icon="mail"
              classLabel={undefined}
              classInput={undefined}
              label={undefined}
              copy={undefined}
              currency={undefined}
              tooltip={undefined}
              place={undefined}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                min: 8,
              },
            ]}
            name={"password"}
          >
            <TextInput
              placeholder="Password"
              readOnly={isRegistrationConfirm}
              name="Password"
              type="password"
              required
              className="register-page-new__input"
              classLabel={undefined}
              classInput={undefined}
              label={undefined}
              copy={undefined}
              currency={undefined}
              tooltip={undefined}
              place={undefined}
              icon="openEye"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                min: 8,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords don't match"));
                },
              }),
            ]}
            name={"repeatPassword"}
          >
            <TextInput
              placeholder="Password"
              readOnly={isRegistrationConfirm}
              name="Password"
              type="password"
              required
              className="register-page-new__input"
              classLabel={undefined}
              classInput={undefined}
              label={undefined}
              copy={undefined}
              currency={undefined}
              tooltip={undefined}
              place={undefined}
              icon="lock"
              autoComplete="new-password"
            />
          </Form.Item>
          {/* {key && !isRegistrationConfirm && (    /// captcha is required
          <Form.Item>
            <ReCAPTCHA
              theme="dark"
              className="register-page-new__captcha"
              size="normal"
              ref={captchaRef}
              sitekey={key}
              onChange={(e: string | null) => handleChangeCaptcha(e)}
              onExpired={() => onExpired()}
              onErrored={() => onErrored()}
            />
          </Form.Item>
        )} */}
          <div className="register-page-new__checkbox_block">
            {!isRegistrationConfirm && (
              <Form.Item>
                <Form.Item name="accept" valuePropName="checked">
                  <Checkbox
                    value={chooseAll}
                    onChange={() => setСhooseAll(!chooseAll)}
                    className="register-page-new__checkbox"
                    classCheckboxTick={undefined}
                    content={undefined}
                    reverse={undefined}
                  />
                </Form.Item>
              </Form.Item>
            )}
            <div>
              {formatMessage(intl, ACCEPT)}{" "}
              <Link
                className="register-page-new__link register-page-new__underline"
                to={`${landingLink}/docs/terms`}
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage(intl, LANDING_FOOTER_TERMS_OF_SERVICE)}
              </Link>{" "}
              and{" "}
              <Link
                className="register-page-new__link register-page-new__underline"
                data-testid={"history-link"}
                to={`${landingLink}/docs/data-policy`}
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage(intl, LANDING_FOOTER_PRIVACY_POLICE)}
              </Link>
            </div>
          </div>
          <Form.Item>
            {isRegistrationConfirm ? (
              <>
                <button
                  disabled={resendButtonDisabled}
                  onClick={resendSubmit}
                  className={cn("button", "register-page-new__button")}
                >
                  Re-Send Email ({formattedTime})
                </button>
                <p
                  onClick={updateEmail}
                  className="register-page-new__text register-page-new__update-email"
                >
                  {formatMessage(intl, REGISTRATION_CONFIRM_PAGE_UPDATE_EMAIL)}
                </p>
              </>
            ) : (
              <>
                <button
                  disabled={disabled}
                  type="submit"
                  className={cn("button", "register-page-new__button")}
                >
                  Continue
                </button>
                {/* <Form.Item> 
                  <p style={{ marginTop: "32px" }} className="register-page-new__text">  /// captcha is required
                    This site is protected by reCAPTCHA and the Google Privacy Policy.
                  </p>
                </Form.Item> */}
              </>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default injectIntl(RegisterPageNew);
