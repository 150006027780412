import { FC, useEffect, useMemo } from "react";
import { injectIntl } from "react-intl";
import "./EmailVerification.sass";
import { IBaseComponentProps } from "../../types";
import Modal from "../../ui-kit/components/Modal";
import emailVerificationSuccessful from "../../resources/images/email-verification-successful.png"
import errorOccurred from "../../resources/images/error-occurred.png"
import cn from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFinalizeByToken } from "../../hooks/useFinalizeByToken";
import useGetLandingLinkByAuthorizedUser from "../../hooks/useGetLandingLinkByAuthorizedUser";
import formatMessage from "../../utils/formatMessage";
import { isSnapshot, ROUTE_PATH } from "../../constants";

type TProps = {} & IBaseComponentProps
type ErrorWithMessage = {
  data: {
    message: string;
  };
};


const CONFIRMATION_EMAIL_PAGE_TITLE = { id: "confirmation-email-page.title" };
const CONFIRMATION_EMAIL_PAGE_DESCRIPTION_REGISTER = {
  id: "confirmation-email-page.description-register",
};
const CONFIRMATION_EMAIL_PAGE_DESCRIPTION = {
  id: "confirmation-email-page.description",
};
const CONFIRMATION_EMAIL_PAGE_RE_SEND_EMAIL_FAILED = {
  id: "confirmation-email-page.login-failed-register",
};


const EmailVerification: FC<TProps> = ({ intl, className }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");
  const {
    finalize,
    isLoading,
    isSuccess,
    isUninitialized,
    type,
    error,
    isError,
  } = useFinalizeByToken();

  useEffect(() => {
    if (token && isUninitialized) {
      finalize({
        token,
      });
    }
  }, [finalize, isUninitialized, token]);

  const { landingId } = useGetLandingLinkByAuthorizedUser()

  const message = useMemo(() => {
    if (error && (error as ErrorWithMessage).data) {
      return (error as ErrorWithMessage).data.message;
    } else return "";
  }, [error]);

  const link = useMemo(() => {
    if (type === "register" && isSuccess) return `${ROUTE_PATH.LOGIN}?landing-id=${landingId}`
    if (type === "register" && isError) return `${ROUTE_PATH.REGISTER}?landing-id=${landingId}`

    else return ROUTE_PATH.ROOT

  }, [isError, isSuccess, landingId, type])
  const navigate = useNavigate();

  if ((!isSuccess && !isError) || isLoading || isSnapshot) return null

  if (isSuccess)
    return (
      <Modal
        visible={true}
        onClose={() => {
          navigate(link);
        }}
        outerClassName="email-verification__modal">
        <div className="email-verification__content">
          <img className="email-verification__img" src={emailVerificationSuccessful} alt="" />
          <h2 className="email-verification__title"> {formatMessage(intl, CONFIRMATION_EMAIL_PAGE_TITLE)}</h2>
          <p className="email-verification__text">
            {formatMessage(
              intl,
              type === "register"
                ? CONFIRMATION_EMAIL_PAGE_DESCRIPTION_REGISTER
                : CONFIRMATION_EMAIL_PAGE_DESCRIPTION
            )}
          </p>
          <Link to={link}>
            <button
              className={cn("button")}
            >
              Done!
            </button>
          </Link>
        </div>
      </Modal>
    )
  if (isError)
    return (
      <Modal
        visible={true}
        onClose={() => {
          navigate(link);
        }}
        outerClassName="email-verification__modal">
        <div className="email-verification__content">
          <img className="email-verification__img" src={errorOccurred} alt="" />
          <h2 className="email-verification__title">Error occurred</h2>
          <p className="email-verification__text">
            {message}
          </p>
          <Link to={link}>
            <button
              className={cn("button", "email-verification__button")}
            >
              {type === "register" ?
                formatMessage(
                  intl,
                  CONFIRMATION_EMAIL_PAGE_RE_SEND_EMAIL_FAILED
                ) :
                "Go to Dashboard"
              }
            </button>
          </Link>
        </div>
      </Modal>
    );
  return null;

}

export default injectIntl(EmailVerification)