import { useMemo } from "react";
import { additionalDataAboutPaidTariffs, TariffKeys } from "../components/PlansList";
import { useGetCurrentPlanQuery } from "../api/reg-service.api";

const useGetDataByTariff = () => {
  const currentPlanQuery = useGetCurrentPlanQuery();

  const planName = currentPlanQuery.data?.name;

  const transformPlanName = useMemo(() => {
    if (planName?.includes("Beginner")) return "Beginner";
    if (planName?.includes("New Standard")) return "NewStandard";
    else return planName || "";
  }, [planName]);

  const requestsMonthCurrency = useMemo(() => {
    if (!planName) return "";
    if (planName === "Tailored") return "Custom";
    else return "M";
  }, [planName]);

  const requestsDayCurrency = useMemo(() => {
    if (!planName) return "";
    if (planName === "Tailored") return "Custom";
    if (
      planName === "New Standard" ||
      planName.includes("Beginner") ||
      planName === "HybridN125"
    )
      return "K";
    else return "M";
  }, [planName]);

  const dataTransferCurrency = useMemo(() => {
    if (!planName) return "";
    if (planName === "Tailored") return "Custom";
    if (planName.includes("Beginner")) return "GB";
    else return "TB";
  }, [planName]);

  const socketsCurrency = useMemo(() => {
    if (!planName) return "";
    if (planName === "Tailored") return "Custom";
    else return "";
  }, [planName]);

  const dataBySelectedTariff =
    additionalDataAboutPaidTariffs[transformPlanName as TariffKeys];

  return {
    dataBySelectedTariff,
    data: {
      requestsSecond:  {
        value: dataBySelectedTariff?.requestsPerSecond || "",
       currency: ""
     },
      requestsDay:  {
        value: dataBySelectedTariff?.requestsPerDay || "",
       currency: requestsDayCurrency
     },
      requestsMonth: {
        value: dataBySelectedTariff?.requestsPerMonth || "",
       currency: requestsMonthCurrency
     },
      dataTransfer: {
         value: dataBySelectedTariff?.transferIncluded || "",
        currency: dataTransferCurrency
      },
      sockets: {
        value: dataBySelectedTariff?.webSocketsConnections || "",
       currency: socketsCurrency
     },
    },
  }
}
  export default useGetDataByTariff