/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form } from "antd";
import "./login-new.page.sass";
import { IBaseComponentProps } from "../../types";
import { useLoginMutation } from "../../api/reg-service.api";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTE_PATH } from "../../constants";
import useGetLandingLinkByAuthorizedUser from "../../hooks/useGetLandingLinkByAuthorizedUser";
import formatMessage from "../../utils/formatMessage";
import { responsePathOr } from "../../utils/utils";
import cn from "classnames";
import { injectIntl } from "react-intl";
import Icon from "../../ui-kit/components/Icon";
import { ClosedEye, OpenedEye } from "../../resources/icons/password-eye";
import Image from "../../ui-kit/components/Image";
import TextInput from "../../ui-kit/components/TextInput";
import GoogleButton from "../../components/GoogleButton";

type TProps = {} & IBaseComponentProps;
interface IFormInput {
  email: string;
  password: string;
}

const TITLE = { id: "login-form.title" };
const SIGNUP = { id: "login-form.signup" };

const openCloseIcons = (visible: boolean) =>
  visible ? <Icon component={OpenedEye} /> : <Icon component={ClosedEye} />;

const LoginPageNew: React.FC<TProps> = ({ className, intl }) => {
  const [login, { isLoading, isSuccess }] = useLoginMutation();
  const [form] = Form.useForm<IFormInput>();
  const [submitError, setSubmitError] = useState<string>();
  const { landingId } = useGetLandingLinkByAuthorizedUser();
  const [formValid, setFormValid] = useState(false);
  // const [isFocused, setIsFocused] = useState(false);

  const onSubmit = async (values: IFormInput) => {
    try {
      window.gtag && window.gtag("event", "click_login");
      await login({
        ...values,
        email: values.email.toLowerCase(),
        landingId: landingId,
      }).unwrap();
    } catch (err) {
      setSubmitError(responsePathOr(err));
    }
  };

  const disabled = isLoading || isSuccess || formValid;
  // const disabled = formValid || !isFocused || !captchaIsPassed || isLoading;
  return (
    <div className="login-page-new">
      <Form
        form={form}
        className={cn("login-page-new__form", className)}
        onFinish={onSubmit}
        onFieldsChange={() => {
          if (submitError) setSubmitError("");
        }}
        layout="vertical"
        // onValuesChange={() => {
        //   setIsFocused(true);
        //   form
        //     .validateFields()
        //     .then((values) => {
        //       const valuesAvailable = Object.values(values).every(
        //         (value) => value
        //       );
        //       setFormValid(!valuesAvailable);
        //     })
        //     .catch((errorInfo) => {
        //       const valuesAvailable = Object.values(errorInfo.values).every(
        //         (value) => value
        //       );
        //       if (valuesAvailable && !errorInfo.errorFields.length) {
        //         setFormValid(false);
        //       } else {
        //         setFormValid(true);
        //       }
        //     });
        // }}
      >
        <Form.Item style={{ marginBottom: 0, position: "relative" }}>
          <Image
            className="login-page-new__logo"
            src="/images/logo-dark.svg"
            srcDark="/images/logo-light.svg"
            alt="Core"
          />
          <h2 className="login-page-new__title">
            {formatMessage(intl, TITLE)}
          </h2>
          <p className="login-page-new__subtitle">Sign in with Google</p>
          <GoogleButton
            className="login-page-new__google-button"
            landingId={landingId}
            onError={(err) => {
              setSubmitError(responsePathOr(err));
            }}
            onSuccess={() => {}}
          />
          <div className="login-page-new__line"></div>
          <p className="login-page-new__subtitle">
            Or continue with email address
          </p>
        </Form.Item>
        <Form.Item
          name={"email"}
          rules={[
            {
              type: "email",
            },
          ]}
        >
          <TextInput
            placeholder="Your email"
            name="Email"
            type="email"
            required
            className="login-page-new__input"
            icon="mail"
            classLabel={undefined}
            classInput={undefined}
            label={undefined}
            copy={undefined}
            currency={undefined}
            tooltip={undefined}
            place={undefined}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              min: 8,
            },
          ]}
          name={"password"}
        >
          <TextInput
            placeholder="Password"
            name="Password"
            type="password"
            required
            className="login-page-new__input"
            classLabel={undefined}
            classInput={undefined}
            label={undefined}
            copy={undefined}
            currency={undefined}
            tooltip={undefined}
            place={undefined}
            icon="lock"
          />
        </Form.Item>
        <Form.Item>
          <button
            disabled={disabled}
            type="submit"
            className={cn("button", "login-page-new__button")}
          >
            Sign in
          </button>
        </Form.Item>
        <Form.Item>
          {/* <p style={{ marginBottom: "32px" }} className="login-page-new__text">
            This site is protected by reCAPTCHA and the Google Privacy Policy.
          </p> */}
          <p className="login-page-new__text">
            Don’t have an account?
            <Link
              className="login-page-new__subtitle"
              data-testid={"history-link"}
              to={`${ROUTE_PATH.REGISTER}?landing-id=${landingId}`}
            >
              {" "}
              {formatMessage(intl, SIGNUP)}
            </Link>
          </p>
        </Form.Item>
      </Form>
    </div>
  );
};

export default injectIntl(LoginPageNew);
