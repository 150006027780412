import { ILoginResponse } from "../api/types/login";
import { AxiosRequestConfig } from "axios";
import { pathOr } from "ramda";
import { LANDING_LINKS } from "../constants";

export const mapCycleToMs = {
  minute: 60,
  day: 86400,
  week: 604800,
  month: 2.592e6,
  year: 3.154e7,
};

export const saveToken = (tokens: ILoginResponse) => {
  localStorage.setItem("accessToken", tokens.accessToken);
  localStorage.setItem("refreshToken", tokens.refreshToken);
};

export const capitaliseFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const removeToken = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  return {
    Authorization: `Bearer ${refreshToken}`,
  };
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem("accessToken");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export const addAccessTokenToHeaders = (
  headers: AxiosRequestConfig["headers"]
): AxiosRequestConfig["headers"] => {
  const accessToken = localStorage.getItem("accessToken");
  const newHeaders: AxiosRequestConfig["headers"] = { ...headers };
  newHeaders["Authorization"] = `Bearer ${accessToken}`;
  return newHeaders;
};

export const responsePathOr = pathOr("Unknown Error", ["data", "message"]);

export const getYear = (): number => new Date().getUTCFullYear();

export const getLandingPageLinkById = (landingId?: string): string => {
  if (!landingId) return "";
  const key = landingId.toString().toLocaleUpperCase();
  return LANDING_LINKS[`LANDING_LINK_${key}`];
};
export const setLSData = (key: string, data: Record<string, any>) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const removeLSData = (key: string) => localStorage.removeItem(key);

export const getLSData = <T extends Record<string, any>>(
  key: string
): T | undefined => {
  const item = localStorage.getItem(key);
  if (!item) return undefined;
  return JSON.parse(item);
};

export const checkAvailabilityTs = (
  ts: number,
  timeGap: { interval: keyof typeof mapCycleToMs; frequency: number }
) => {
  const endDate =
    mapCycleToMs[timeGap.interval] * timeGap.frequency * 1000 + ts;

  return new Date().getTime() <= endDate;
};
export const countLettersBeforeSecondUpper = (word: string): number => {
  let count = 0;
  let upperCount = 0;

  for (const char of word) {
    if (char.toUpperCase() === char && char.toLowerCase() !== char) {
      upperCount++;
      if (upperCount === 2) {
        break;
      }
    }
    if (upperCount < 2) {
      count++;
    }
  }

  return count;
};

export const saveLandingId = (landingId?: string) => {
  if (!landingId) return;
  localStorage.setItem("landingId", landingId);
};
export const getLandingId = () => {
  const landingId = localStorage.getItem("landingId") || undefined;
  return landingId;
};
export const formatLargeNumber = (num: number) => {
  if (num >= 1e12) {
    return (num / 1e12).toFixed(1) + "T";
  } else if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + "B";
  } else if (num >= 1e6) {
    return Math.round(num / 1e6) + "M";
  } else if (num >= 1e3) {
    return Math.round(num / 1e3) + "K";
  }
  return num.toString();
};

export enum EMeasure {
  KILOBYTES = "KB",
  MEGABYTES = "MB",
  GIGABYTES = "GB",
  TERABYTES = "TB",
}

// const createPaymentString = (
//   splToken: string,
//   recipient: string,
//   amount: string,
//   label: string,
//   memo: string
// ) => {
//   return `solana:${recipient}?amount=${amount}&spl-token=${splToken}&label=${label}&memo=${memo}`;
// };
// const label = "InstantNodes";
