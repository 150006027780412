import { FC, ReactNode } from "react"
import "./Endpoints.sass"
import { injectIntl } from "react-intl"
import { IBaseComponentProps } from "../../types"

import Title from "../Title"
import PlanDescription from "../PlanDescription/PlanDescription"
import ApiKeys from "../ApiKeys/ApiKeys"
import Urls from "../Urls/Urls"
import Security from "../Security/Security"
import CodeExamplesNew from "../CodeExamplesNew/CodeExamplesNew"


type TProps = {

} & IBaseComponentProps


export type TItem = {
  title: string;
  value: ReactNode;
  tooltip: string;
}

const ENDPOINTS_TITLE = { id: "endpoints.title" }

const Endpoints: FC<TProps> = ({ intl, className }) => {

  return (
    <div className="endpoints">
      <Title text={ENDPOINTS_TITLE} />
      <div className="endpoints__content">
        <PlanDescription />
        <ApiKeys />
        <Urls />
        <Security />
        <CodeExamplesNew />
      </div>
    </div>
  )
}

export default injectIntl(Endpoints)