import useToken from "antd/es/theme/useToken";

import "./billing.page.sass";
import Title from "../../components/Title";
import BillingPlan from "../../components/Billing";

export const BILLING_PAGE = { id: "billing-page.billing" };

const BillingPage: React.FC = () => {
  const [, token] = useToken();

  return (
    <div
      style={{
        color: token.colorPrimary,
      }}
      className="billing-page"
    >
      <Title className="billing-page__title" text={BILLING_PAGE} />
      <BillingPlan />
    </div>
  );
};

export default BillingPage;
