import { useEffect } from "react";
import { useGetProfileQuery } from "../api/reg-service.api";
import Intercom from "@intercom/messenger-js-sdk";
import { intercomAppId } from "../constants";

const useIntercomChat = () => {
  const { data: profile } = useGetProfileQuery();

  useEffect(() => {
    if (profile && intercomAppId) {
      Intercom({
        app_id: intercomAppId,
        user_id: String(profile.id), // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: "Client", // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: profile.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      });
    }
  }, [profile]);
};

export default useIntercomChat;
