import { FC, useRef } from "react";
import React, { useEffect } from "react";
import "./ButtonGroupSmooth.sass";
import cn from "classnames";

export type Props = {
  // size: string
  // theme: string
  className?: string;
  title: string;
  value: string;
  checked: boolean;
  onPress: (arg0: string) => void;
  getButtonRef: (title: string, event: any, value: string) => void;
};

const ButtonGroupSmooth: FC<Props> = ({
  title,
  className = "",
  value,
  checked,
  onPress,
  getButtonRef,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    getButtonRef(title, buttonRef, value);
  }, [checked, buttonRef, getButtonRef, title, value]);

  return (
    <button
      name={value}
      ref={buttonRef}
      type="button"
      className={cn({
        buttonGroupSmooth: true,
        buttonGroupSmooth_disabled: checked,
        [className]: className,
        // [`size-buttonGroupSmooth-xs`]: true,
        // [`theme-buttonGroupSmooth-positive`]: true,
      })}
      onClick={() => {
        onPress(value);
      }}
      disabled={checked}
    >
      {title}
    </button>
  );
};

export default ButtonGroupSmooth;
