import "./styles/styles.sass";
import "./ui-kit/styles/app.sass";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { getRoutes } from "./pages";
import { notification } from "antd";
import { hydrateRoot, createRoot } from "react-dom/client";
import GoogleMetrics from "./components/GoogleMetrics/GoogleMetrics";
import StatCounter from "./components/StatCounter/StatCounter";
import Providers from "./providers";

const domNode = document.getElementById("root") as HTMLElement;
const root = createRoot(domNode);
const router = createBrowserRouter(getRoutes());
notification.config({ placement: "bottomRight", duration: 4 });

const App = () => (
  <Providers>
    <GoogleMetrics />
    <StatCounter />
    <RouterProvider router={router} />
  </Providers>
);

if (domNode.hasChildNodes()) {
  hydrateRoot(domNode, <App />);
} else {
  root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
