import { IBaseComponentProps } from "../../types";
import cn from "classnames";
import styles from "./SurveyBadge.module.sass";
import { injectIntl } from "react-intl";
import Icon from "../../ui-kit/components/Icon";
import { SURVEY_PATH } from "../../constants";

interface IProps extends IBaseComponentProps {}

const SurveyBadge: React.FC<IProps> = ({ className }) => {
  return (
    <div className={cn(styles.root, className)}>
      <div className={cn("title-dark-green", styles.title)}>
        <span>Complete the survey</span> to claim your <span>90% discount</span>{" "}
        for the month!
      </div>
      <a
        href={`https://instantnodes.io/${SURVEY_PATH}`}
        target="_blank"
        rel="noreferrer"
      >
        <button className={cn("button", styles.button)}>
          Start Survey
          <Icon name="arrow-right" size="24" />
        </button>
      </a>
    </div>
  );
};

export default injectIntl(SurveyBadge);
