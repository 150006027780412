import { FormatDateOptions, IntlShape } from "react-intl";
import {
  TPeriod,
  TRequestsPerPeriod,
} from "../api/types/get-requests-per-period";
import { pick } from "ramda";

type TIntervalMeasure = "month" | "day" | "hour" | "minute" | "second";

export type TInterval = Record<TIntervalMeasure, number>;
export type DateDescriptor = {
  value: string | number | Date;
  opts?: FormatDateOptions;
};

export const formatterXMap: Record<TPeriod, (date: string) => string> = {
  hour: (date) =>
    new Date(date)
      .toLocaleDateString("ru", {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "UTC",
      })
      .split(" ")
      .splice(1)
      .join(" ") + " UTC",
  day: (date) =>
    new Date(date).toLocaleDateString("en", {
      month: "short",
      day: "2-digit",
      timeZone: "UTC",
    }),
  minute: (date) => new Date(date).getMinutes().toString(),
  month: (date) =>
    new Date(date).toLocaleDateString("en", {
      month: "short",
      timeZone: "UTC",
    }),
};

export const convertToISO = (dateString: string): string => {
  if (!dateString.endsWith("Z")) {
    dateString += "Z";
  }

  if (dateString[11] !== "T") {
    dateString.replace(" ", "T");
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    throw new Error("Некорректная дата");
  }

  return date.toISOString();
};

export const mapStartPeriodIntervalByPeriod: Record<TPeriod, any> = {
  minute: pick(["second"]),
  hour: pick(["minute", "second"]),
  day: pick(["hour", "minute", "second"]),
  month: pick([""]),
};

export const mapEndPeriodIntervalByPeriod: Record<
  TPeriod,
  Partial<TInterval>
> = {
  hour: { hour: 1 },
  day: { day: 1 },
  minute: { minute: 1 },
  month: { day: 30 },
};

export const getInterval = (startDate?: string): Partial<TInterval> => {
  if (!startDate) return {};
  const _startDate = new Date(startDate);
  if (isNaN(_startDate.getDate())) return {};
  return {
    day: _startDate.getUTCDate() - 1,
    hour: _startDate.getUTCHours(),
    minute: _startDate.getUTCMinutes(),
    second: _startDate.getUTCSeconds(),
  };
};

export const toStartOfInterval = (date: Date, interval: TIntervalMeasure) => {
  const newDate = new Date(date);

  switch (interval) {
    case "hour":
      newDate.setMinutes(0, 0, 0);
      break;
    case "day":
      newDate.setHours(0, 0, 0, 0);
      break;
    case "month":
      newDate.setDate(1);
      newDate.setHours(0, 0, 0, 0);
      break;
    default:
      throw new Error("Unsupported interval");
  }

  return newDate;
};

export function isEarlerWithStartPeriod(
  el: TRequestsPerPeriod,
  startPlanDate: string | undefined,
  period: TIntervalMeasure
): boolean {
  return (
    new Date(el.date) < toStartOfInterval(new Date(startPlanDate || 0), period)
  );
}

export const addInterval = (date: string, interval: Partial<TInterval>) => {
  const _date = new Date(date);
  if (isNaN(_date.getDate())) return new Date();

  _date.setUTCMonth(_date.getUTCMonth() + (interval.month || 0));

  _date.setUTCDate(_date.getUTCDate() + (interval.day || 0));

  _date.setUTCHours(_date.getUTCHours() + (interval.hour || 0));

  _date.setUTCMinutes(_date.getUTCMinutes() + (interval.minute || 0));

  return _date;
};

export const formatLabelDate = (date: Date, period: TPeriod) => {
  var _date;

  switch (period) {
    case "day":
      _date =
        date
          .toLocaleDateString("en", {
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            hourCycle: "h23",
            minute: "2-digit",
            timeZone: "UTC",
          })
          .replace(",", "") + " UTC";
      break;
    case "hour":
      _date =
        date
          .toLocaleDateString("en", {
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h23",
            timeZone: "UTC",
          })
          .replace(",", "") + " UTC";
      break;
    case "minute":
      _date =
        date
          .toLocaleDateString("en", {
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h23",
            timeZone: "UTC",
          })
          .replace(",", "") + " UTC";
      break;
    case "month":
      _date =
        date
          .toLocaleDateString("en", {
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            hourCycle: "h23",
            minute: "2-digit",
            timeZone: "UTC",
          })
          .replace(",", "") + " UTC";
      break;
  }
  return _date;
};

const formatDate = (intl: IntlShape, date: DateDescriptor): string => {
  return intl.formatDate(date.value, date.opts);
};

export default formatDate;
