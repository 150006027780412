import { FC } from "react";
import { injectIntl } from "react-intl";
import "./Urls.sass";
import { IBaseComponentProps } from "../../types";
import Card from "../../ui-kit/components/Card";
import cn from "classnames";
import Form from "../../ui-kit/components/Form";
import { Button, Tooltip as TooltipAntd } from "antd";
import copyingToClipboard from "../../utils/copyingToClipboard";
import { useGetApiKeysQuery } from "../../api/reg-service.api";
import { createRpcUrl } from "../../constants";
import { ReactComponent as CopyIcon } from "../../resources/images/copy.svg";
import WindowWithEmailAddress from "../../hocs/windowWithEmailAddress/windowWithEmailAddress";

type TProps = {} & IBaseComponentProps;
const Urls: FC<TProps> = ({ intl, className }) => {
  const { data: apiKeys } = useGetApiKeysQuery();
  const rpcHttpUrl =
    apiKeys && apiKeys.length
      ? createRpcUrl(apiKeys[0])
      : createRpcUrl("<your-api-key>");

  const rpcWsUrl =
    apiKeys && apiKeys.length
      ? createRpcUrl(apiKeys[0], true)
      : createRpcUrl("<your-api-key>", true);

  return (
    <Card
      className={cn("urls", className)}
      title="URLs"
      classTitle="title-purple"
    >
      <div className="urls__content">
        <div className="urls__item">
          <div className="urls__title">
            RPC Endpoint
            {/* <Tooltip
              className="urls__tooltip"
              title={"title"}
              icon="info"
              place="top"
            /> */}
          </div>
          <div className="urls__input">
            <Form
              readOnly
              className={"urls__form"}
              value={rpcHttpUrl}
              setValue={() => { }}
              onSubmit={() => { }}
              placeholder=""
              type="text"
              name="rpcEndpoint"
            />
            <TooltipAntd trigger={["click"]} title="copied">
              <Button
                className="urls__copy"
                onClick={() => copyingToClipboard(rpcHttpUrl)}
                icon={<CopyIcon />}
              />
            </TooltipAntd>
          </div>
        </div>
        <div className="urls__item">
          <div className="urls__title">
            WebSocket
            {/* <Tooltip
              className="urls__tooltip"
              title={"title"}
              icon="info"
              place="top"
            /> */}
          </div>
          <div className="urls__input">
            <Form
              readOnly
              className={"urls__form"}
              value={rpcWsUrl}
              setValue={() => { }}
              onSubmit={() => { }}
              placeholder=""
              type="text"
              name="rpcEndpoint"
            />
            <TooltipAntd trigger={["click"]} title="copied">
              <Button
                className="urls__copy"
                onClick={() => copyingToClipboard(rpcWsUrl)}
                icon={<CopyIcon />}
              />
            </TooltipAntd>
          </div>
        </div>

        <div className="urls__item">
          <div className="urls__title">
            Staked Connection URL
            {/* <Tooltip
              className="urls__tooltip"
              title={"title"}
              icon="info"
              place="top"
            /> */}
          </div>
          <div className="urls__input">
            <Form
              readOnly
              className={"urls__form urls__disabled"}
              value={"Comming soon"}
              setValue={() => { }}
              onSubmit={() => { }}
              placeholder=""
              type="text"
              name="rpcEndpoint"
            />
            <button
              onClick={() => { }}
              disabled
              className={cn("button", "api-keys__button urls__button")}
            // onClick={() => setVisibleModal(true)}
            >
              Upgrade
            </button>
          </div>
        </div>

        <div className="urls__item">
          <div className="urls__title">
            Geyser
            {/* <Tooltip
              className="urls__tooltip"
              title={"title"}
              icon="info"
              place="top"
            />  */}
          </div>
          <div className="urls__input">
            {/* <Form
              readOnly
              className={"urls__form urls__disabled"}
              value={"Please contact our sales team for access"}
              setValue={() => { }}
              onSubmit={() => { }}
              placeholder=""
              type="text"
              name="rpcEndpoint"
            /> */}
            <div className="urls__textarea">Please contact our sales team for access</div>
            <WindowWithEmailAddress placement="bottomRight" className="tailored-card__row">
              <button
                onClick={() => { }}
                className={cn("button", "api-keys__button urls__button")}
              >
                Contact
              </button>
            </WindowWithEmailAddress>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default injectIntl(Urls);
