import { FC, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import "./SetEmail.sass";
import { IBaseComponentProps } from "../../types";
import cn from "classnames";
import Card from "../../ui-kit/components/Card";
import TextInput from "../../ui-kit/components/TextInput";
import { Button, Form, Input, Space } from "antd";
import {
  useGetProfileQuery,
  useSetEmailMutation,
} from "../../api/reg-service.api";
import { useTimer } from "../../hooks/useTimer";
import { responsePathOr } from "../../utils/utils";
import {} from "../../constants";
import { ClosedEye, OpenedEye } from "../../resources/icons/password-eye";
import Icon from "@ant-design/icons";

type TProps = {} & IBaseComponentProps;
interface IFormInput {
  email: string;
  password: string;
}

const SetEmail: FC<TProps> = ({ intl, className }) => {
  const [form] = Form.useForm();
  const { data: profile } = useGetProfileQuery();
  const { count, formattedTime, reset, start } = useTimer(80);
  const [setEmail, { isLoading }] = useSetEmailMutation();
  const landingId = profile?.landingId || "";
  const [isChangeEmailConfirm, setIsChangeEmailConfirm] =
    useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>();
  const [isFocused, setIsFocused] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const openCloseIcons = (visible: boolean) => {
    return visible ? (
      <Icon component={OpenedEye} />
    ) : (
      <Icon component={ClosedEye} />
    );
  };
  const disabled = formValid || !isFocused || isLoading;
  const resendButtonDisabled = !!count || isLoading;
  const onSubmit = async (values: IFormInput) => {
    try {
      await setEmail({
        ...values,
        newEmail: values.email.toLowerCase(),
        landingId: +landingId,
      }).unwrap();
      setIsChangeEmailConfirm(true);
      reset();
      start();
    } catch (err) {
      setSubmitError(responsePathOr(err));
    }
  };

  useEffect(() => {
    profile && form.setFieldValue("oldEmail", profile?.email);
  }, [form, profile]);

  return (
    <Card
      className={cn(className, "set-email")}
      title="Email"
      classTitle={cn("title-red")}
    >
      <div className={"set-email__content"}>
        <Form
          form={form}
          scrollToFirstError
          className={cn("set-email_form", className)}
          onFinish={onSubmit}
          onFieldsChange={() => {
            if (submitError) setSubmitError("");
          }}
          layout="vertical"
          autoComplete="off"
          onValuesChange={() => {
            setIsFocused(true);
            form
              .validateFields()
              .then((values) => {
                const valuesAvailable = Object.values(values).every(
                  (value) => value
                );
                setFormValid(!valuesAvailable);
              })
              .catch((errorInfo) => {
                const valuesAvailable = Object.values(errorInfo.values).every(
                  (value) => value
                );
                if (valuesAvailable && !errorInfo.errorFields.length) {
                  setFormValid(false);
                } else {
                  setFormValid(true);
                }
              });
          }}
        >
          <Form.Item
            className="set-email__label"
            label="Email"
            name={"email"}
            rules={[
              {
                type: "email",
              },
            ]}
          >
            <TextInput
              name="Email"
              type="Email"
              required
              className="set-email__input"
              classLabel={undefined}
              classInput={undefined}
              label={undefined}
              copy={undefined}
              currency={undefined}
              tooltip={undefined}
              place={undefined}
              icon={undefined}
            />
          </Form.Item>
          <Form.Item
            className="set-email__label"
            label="Password"
            rules={[
              {
                min: 8,
              },
            ]}
            name={"password"}
          >
            <Input.Password
              className="set-email__password set-email__input"
              autoComplete="new-password"
              iconRender={openCloseIcons}
              readOnly={isChangeEmailConfirm}
            />
          </Form.Item>
          <Form.Item className="set-email__button">
            <Space>
              <Button
                // className=""
                type="primary"
                disabled={
                  isChangeEmailConfirm ? resendButtonDisabled : disabled
                }
                htmlType="submit"
              >
                {isChangeEmailConfirm ? (
                  <>Re-Send Code {formattedTime}</>
                ) : (
                  "Update email"
                )}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Card>
  );
};

export default injectIntl(SetEmail);
