import { useMemo } from "react";
import { IGetCurrentPlanResponse } from "../api/types/get-current-plan";
import { IGetCurrentSubscriptionResponse } from "../api/types/get-subscription";
import BigNumber from "bignumber.js";
import { convertBigNumber } from "../utils/convertBigNumberToNumber";
import { TPaymentTokenResponse } from "../api/types/paymentToken";
import usePlanType, { EPlanType } from "./usePlanType";
import { TPlan } from "../entities/plan";

const convertPriceWithCents = (price?: number, decimals?: number) => {
  if (price === 0 || !price) return "0";
  return convertBigNumber(price, decimals).toNumber().toFixed(2);
};

const convertPercentDiscount = (amount: string, price: number) => {
  const discountBnPercents = new BigNumber(amount).div(100);
  return convertBigNumber(price, 2)
    .times(new BigNumber(1).minus(discountBnPercents))
    .toNumber()
    .toFixed(2);
};

const convertFlatDiscount = (amount: string, price: number) => {
  const discountBnPrice = new BigNumber(price)
    .minus(amount)
    .dividedToIntegerBy(100);
  return discountBnPrice.toNumber().toFixed(2);
};

const useNextBilledPrice = (
  currentPlanQuery: {
    data?: IGetCurrentPlanResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  tokens: TPaymentTokenResponse | undefined,
  currentPlanFromSubscription?: TPlan
) => {
  const paymentToken = tokens ? tokens[0] : undefined;
  const currentSubscription = currentSubscriptionQuery.data;
  const currentPlan = currentPlanQuery.data;
  const planType = usePlanType(currentPlan);
  return useMemo(() => {
    if (currentSubscription?.discount) {
      if (
        planType !== EPlanType.DEFAULT &&
        currentSubscription.status !== "canceled" &&
        currentSubscription?.nextBilledAt &&
        currentSubscription.discount?.endsAt &&
        new Date(currentSubscription.nextBilledAt) <
          new Date(currentSubscription.discount.endsAt)
      ) {
        if (
          !currentSubscription.discount.value &&
          currentPlan?.discountPercentage
        ) {
          return convertBigNumber(currentPlan?.price, paymentToken?.decimals)
            .times(1 - currentPlan.discountPercentage / 100)
            .toNumber()
            .toFixed(2);
        }

        let result;

        switch (currentSubscription.discount.value?.type) {
          case "percentage":
            result = convertPercentDiscount(
              currentSubscription.discount.value.amount,
              currentSubscription.price || 0
            );
            break;
          case "flat":
            result = convertFlatDiscount(
              currentSubscription.discount.value.amount,
              currentSubscription.price || 0
            );
            break;
          default:
            result = convertPriceWithCents(
              currentPlan?.price,
              paymentToken?.decimals
            );
        }

        return result;
      }
    }

    if (planType !== EPlanType.DEFAULT && !currentSubscription?.nextBilledAt)
      return "-";

    const price =
      currentSubscription?.status === "past_due"
        ? currentPlanFromSubscription?.price
        : currentPlan?.price;
    return convertPriceWithCents(price, paymentToken?.decimals);
  }, [
    currentPlan,
    currentPlanFromSubscription?.price,
    currentSubscription,
    paymentToken?.decimals,
    planType,
  ]);
};

export default useNextBilledPrice;
