import IntlProviderWrapper from "./IntlProviderWrapper";
import ConfigProviderWrapper from "./ConfigProviderWrapper";
import PersistGateProvider from "./PersistGateWrapper";
import StoreProviderWrapper from "./StoreProviderWrapper";
import { HelmetProvider } from "react-helmet-async";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import GoogleOAuthProvider from "./GoogleGsiWrapper";
import { googleOAuthClientId } from "../constants";

interface Provider<TProps> {
  Component: React.ComponentType<React.PropsWithChildren<TProps>>;
  props?: Omit<TProps, "children">;
}

function composeProviders<TProviders extends Array<Provider<any>>>(
  providers: TProviders
): React.ComponentType<React.PropsWithChildren> {
  const ProviderComponent: React.FC<React.PropsWithChildren> = ({
    children,
  }) => {
    const initialJSX = <>{children}</>;

    return providers.reduceRight<JSX.Element>(
      (prevJSX, { Component: CurrentProvider, props = {} }) => {
        return <CurrentProvider {...props}>{prevJSX}</CurrentProvider>;
      },
      initialJSX
    );
  };

  return ProviderComponent;
}

function createProvider<TProps>(
  Component: React.ComponentType<React.PropsWithChildren<TProps>>,
  props?: Omit<TProps, "children">
): Provider<TProps> {
  return { Component, props };
}

const providers = [
  createProvider(React.StrictMode),
  createProvider(HelmetProvider),
  createProvider(StoreProviderWrapper),
  createProvider(PersistGateProvider),
  createProvider(ConfigProviderWrapper),
  createProvider(IntlProviderWrapper),
  createProvider(GoogleOAuthProvider, { clientId: googleOAuthClientId }),
];

export const testProviders = [
  createProvider(StoreProviderWrapper),
  createProvider(IntlProviderWrapper),
  createProvider(BrowserRouter),
];

export const TestProviders = composeProviders(testProviders);

const Providers = composeProviders(providers);

export default Providers;
