import { Typography } from "antd";
import cn from "classnames";
import { injectIntl } from "react-intl";
import formatMessage, { MessageDescriptor } from "../../utils/formatMessage";
import { IBaseComponentProps } from "../../types";
import "./Title.sass"

interface IProps extends IBaseComponentProps {
  text: MessageDescriptor;
}

// REF: @shared
const Title: React.FC<IProps> = ({ text, intl, className }) => {
  return (
    <Typography.Title level={3} className={cn("title", className)} >
      {formatMessage(intl, text)}
    </Typography.Title>
  );
};

export default injectIntl(Title);
