import { FC, ReactNode, useMemo } from "react";
import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../types";
import "./DashboardInformationWindow.sass";
import warning_icon from "../../resources/images/warning_icon.svg";
import success_icon from "../../resources/images/success_icon.svg";
import error_icon from "../../resources/images/error_icon.svg";
import { ReactComponent as Cross } from "../../resources/images/cross_white_header.svg";

type TProps = {
  type: "error" | "success" | "warning";
  text: ReactNode | string;
  closeWindow?: () => void;
} & IBaseComponentProps;

const DashboardInformationWindow: FC<TProps> = ({
  intl,
  type,
  className,
  text,
  closeWindow,
}) => {
  const src = useMemo(() => {
    switch (type) {
      case "error":
        return error_icon;
      case "success":
        return success_icon;
      case "warning":
        return warning_icon;
    }
  }, [type]);

  const rootClassName = useMemo(() => {
    switch (type) {
      case "error":
        return "dashboard-information-window__error";
      case "success":
        return "dashboard-information-window__success";
      case "warning":
        return "dashboard-information-window__warning";
    }
  }, [type]);

  return (
    <div
      className={`dashboard-information-window ${className} ${rootClassName}`}
    >
      <img
        width={32}
        height={32}
        className="dashboard-information-window__icon"
        src={src}
        alt=""
      />
      <p className="dashboard-information-window__text">{text}</p>
      {closeWindow && (
        <Cross
          onClick={() => {
            closeWindow && closeWindow();
          }}
          className="dashboard-information-window__closeicon"
        />
      )}
    </div>
  );
};

export default injectIntl(DashboardInformationWindow);
