import { useEffect, useMemo } from "react";
import { useGetPlansMutation, useGetProfileQuery } from "../api/reg-service.api";

const useAvailablePlans = ({ skip }: { skip?: boolean }) => {
  const { data: profile, isLoading: isGetProfileLoading } = useGetProfileQuery();
  const landingId = profile?.landingId || ""
  // const { data: plans } = useGetPlansMutation({ landingId: +landingId }, {
  //   skip,
  // });
  const [getPlans, { data: plans, isLoading: isGetPlansLoading }] = useGetPlansMutation();
  // const currentSubscriptionQuery = useGetCurrentSubscriptionQuery();

  useEffect(() => {
    if (!landingId) return
    getPlans({ landingId: +landingId })
  }, [getPlans, landingId]);

  const standartPlan = useMemo(
    () => plans?.find((el) => el.isAvailable && !el.isDefault),
    [plans]
  );
  const defaultPlan = useMemo(() => plans?.find((el) => el.isDefault), [plans]);

  const paidTariffs = useMemo(() => plans?.filter((el) => el.isAvailable && !el.isDefault && el.name) || [], [plans])
  return {
    standartPlan,
    defaultPlan,
    plans,
    paidTariffs,
    isLoading: isGetProfileLoading || isGetPlansLoading
  };
};

export default useAvailablePlans;
