import React, { useMemo } from "react";
import cn from "classnames";
import styles from "./Packages.module.sass";
import { IBaseComponentProps } from "../../../../types";
import { injectIntl } from "react-intl";
import useGetCheckItemsFromTariff from "../../../../hooks/useGetCheckItemsFromTariff";
import { TPlan } from "../../../../entities/plan";
import ButtonGroupsSmooth, {
  ButtonGroupsSmoothOption,
} from "../../../../components/ButtonGroupsSmooth/ButtonGroupsSmooth";
import {
  PLANS_LIST_DISCOUNT,
  PLANS_LIST_DISCOUNT_FIRST,
  PLANS_LIST_FREE,
  PLANS_LIST_TAX,
  PLANS_LIST_YOUR_ACTIVE_SUBSCRIPTION,
} from "../../../../components/PlansList";
import formatMessage from "../../../../utils/formatMessage";
import useAvailablePlans from "../../../../hooks/useAvailablePlans";
import { convertBigNumberToNumber } from "../../../../utils/convertBigNumberToNumber";
import {
  useGetCurrentPlanQuery,
  useGetCurrentSubscriptionQuery,
  useGetPaymentTokensQuery,
} from "../../../../api/reg-service.api";
import StandartPlanRenderPrice from "../../../../components/StandartPlanRenderPrice/StandartPlanRenderPrice";
import { useStandardButtonProps } from "../../../../hooks/useStandardButtonProps";
import usePlanType, { EPlanType } from "../../../../hooks/usePlanType";
import { countLettersBeforeSecondUpper } from "../../../../utils/utils";
import Icon from "../../../components/Icon";
import { Tooltip } from "antd";

interface IProps extends IBaseComponentProps {
  selectedPlan: TPlan;
  setSelectedPaidTariffOption: (selectedPlan: ButtonGroupsSmoothOption) => void;
  selectedPaidTariffOption: ButtonGroupsSmoothOption;
  onUpdateClick: VoidFunction;
}

const Packages: React.FC<IProps> = ({
  className,
  selectedPlan,
  setSelectedPaidTariffOption,
  selectedPaidTariffOption,
  onUpdateClick,
  intl,
}) => {
  const { beginnerCheckItems, standartCheckItems } =
    useGetCheckItemsFromTariff(selectedPlan);

  const currentPlanQuery = useGetCurrentPlanQuery((() => { })(), {
    pollingInterval: 60000,
  });

  const currentSubscriptionQuery = useGetCurrentSubscriptionQuery(
    (() => { })(),
    {
      pollingInterval: 60000,
    }
  );

  const planType = usePlanType(currentPlanQuery.data);

  const standardButtonProps = useStandardButtonProps(
    currentPlanQuery,
    currentSubscriptionQuery,
    selectedPlan?.name
  );

  const defaultPlanButtonProps = {
    disabled: true,
    title:
      planType === EPlanType.DEFAULT &&
        (!currentSubscriptionQuery.data ||
          (currentSubscriptionQuery.data.status !== "canceled" &&
            currentSubscriptionQuery.data?.status !== "past_due"))
        ? PLANS_LIST_YOUR_ACTIVE_SUBSCRIPTION
        : undefined,
  };

  const { paidTariffs } = useAvailablePlans({});

  const paidTariffsOptions = useMemo(
    () =>
      paidTariffs.map((el, index) => {
        return {
          title: `${el.rps.toString()} RPS`,
          value: el.name,
        } as ButtonGroupsSmoothOption;
      }),
    [paidTariffs]
  );

  const { data: tokens } = useGetPaymentTokensQuery();

  const paymentToken = tokens ? tokens[0] : undefined;

  const onButtonGroupsSmoothChange = (event: string) => {
    const _selectedTariff =
      paidTariffs.find((el) => el.name === selectedPaidTariffOption.title) ||
      paidTariffs[0];

    if (_selectedTariff && paymentToken) {
      window.gtag &&
        window.gtag("event", "click_select_plan", {
          plan_name: _selectedTariff?.name,
          page: window.location.pathname,
          plan_price: convertBigNumberToNumber(
            _selectedTariff?.price,
            paymentToken?.decimals
          ),
          time: new Date().toISOString(),
        });
    }
    setSelectedPaidTariffOption({ title: event, value: event });
  };

  const packages = [
    {
      title: PLANS_LIST_FREE,
      classTitle: "title-blue",
      recommended: false,
      price: "Free",
      note: "Access your free plan anytime and enjoy our services!",
      tooltip: "Small description Lite",
      classButton: defaultPlanButtonProps.disabled ? "button-stroke" : "button",
      buttonText: defaultPlanButtonProps?.title
        ? formatMessage(intl, defaultPlanButtonProps.title)
        : "",
      buttonDisabled: defaultPlanButtonProps.disabled,
      value: "0",
      onChange: () => { },
      options: [{ title: "Up to 10 requests per second", value: "0" }],
      list: beginnerCheckItems,
    },
    {
      classTitle: "title-purple",
      recommended: true,
      // content:
      //   'Pro shop and tools to set up your profile <span role="img" aria-label="fire">🔥</span>',
      renderPrice: () => (
        <StandartPlanRenderPrice
          selectedPlan={selectedPlan}
          className={styles.percent}
        />
      ),
      // note: "of the monthly income you earn on the market",
      note: selectedPlan?.discountPercentage
        ? selectedPlan?.name === "HybridN225"
          ? formatMessage(intl, {
            id: PLANS_LIST_DISCOUNT.id,
            params: { months: 1 },
          })
          : formatMessage(intl, {
            id: PLANS_LIST_DISCOUNT_FIRST.id,
            params: {
              months: selectedPlan?.name === "HybridN1500" ? 6 : 3,
            },
          })
        : formatMessage(intl, PLANS_LIST_TAX),
      classButton: standardButtonProps?.disabled ? "button-stroke" : "button",
      buttonText: standardButtonProps
        ? formatMessage(intl, standardButtonProps?.buttonTitle)
        : "",
      buttonDisabled: standardButtonProps?.disabled,
      value: selectedPaidTariffOption.value,
      onChange: onButtonGroupsSmoothChange,
      options: paidTariffsOptions,
      onButtonClick: onUpdateClick,
      list: standartCheckItems,
    },
  ];


  return (
    <div className={cn(styles.packages, className)}>
      <div className={styles.list}>
        {packages.map((x, index) => {
          const name = selectedPlan?.name || paidTariffs[0]?.name || ""
          const count = countLettersBeforeSecondUpper(name);

          const titleView = (() => {
            if (paidTariffsOptions && !name.includes("New Standard"))
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {name.slice(0, count)}
                  <p className={cn(styles.chunk)}>{name.slice(count)}</p>
                </div>
              );
            else
              return name
          })
          return (
            <div className={styles.package} key={index}>
              <div className={styles.top}>
                <div className={cn(x.classTitle, styles.title)}>
                  {x.title
                    ? formatMessage(intl, x.title)
                    : titleView()}

                </div>
                {/* {x.recommended && (
                <div className={styles.recommended}>Recommended</div>
              )} */}
              </div>
              {/* <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: x.content }}
            ></div> */}
              <div className={styles.buttonGroupsWrapper}>
                {x.options.length >= 1 ? (
                  <ButtonGroupsSmooth
                    style={{
                      gridTemplateColumns: `repeat(${x.options.length}, 1fr)`,
                    }}
                    className={`${styles.buttonGroups} ${x.options.length === 1 && styles.buttonGroupsOne}`}
                    value={x.value}
                    options={x.options}
                    onChange={x.onChange}
                  />
                ) : (
                  <div style={{ height: "40px" }}></div>
                )}
                {/* {buttonTitle ? button : <div style={{ height: "40px" }}></div>} */}
              </div>
              <div className={styles.line}>
                {x.renderPrice ? (
                  x.renderPrice()
                ) : (
                  <div className={cn("h1", styles.percent)}>{x.price}</div>
                )}
                <div className={styles.note}>{x.note}</div>
                {/* <Tooltip
                className={styles.tooltip}
                title={x.tooltip}
                icon="info"
                place="top"
              /> */}
              </div>
              <ul className={styles.group}>
                {x.list.map((item, index) => {
                  if (typeof item === "object")
                    return (
                      <li style={{ cursor: "pointer" }} className={styles.item} key={index}>
                        <Tooltip placement="bottom" trigger={["click", "focus"]} title={item.hint}>
                          {item.text}
                          <Icon className={styles.icon} name={"info"} />
                        </Tooltip>
                      </li>
                    );
                  return (
                    <li className={styles.item} key={index}>
                      {item}
                    </li>
                  );
                })}
              </ul>
              {x.buttonText && (
                <button
                  className={cn(x.classButton, styles.button, {
                    disabled: x.buttonDisabled,
                  })}
                  disabled={x.buttonDisabled}
                  onClick={() => {
                    x.onButtonClick && x.onButtonClick();
                  }}
                >
                  {x.buttonText}
                </button>
              )}
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default injectIntl(Packages);
