import { FC, useMemo } from "react";
import NewPlanCard from "../NewPlanCard";
import formatMessage from "../../utils/formatMessage";
import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../types";
import {
  PLANS_LIST_FREE,
  PLANS_LIST_TAILORED,
  PLANS_LIST_YOUR_ACTIVE_SUBSCRIPTION,
}
  from ".";
import usePlanType, { EPlanType } from "../../hooks/usePlanType";
import { useGetCurrentPlanQuery, useGetPaymentTokensQuery } from "../../api/reg-service.api";
import { TSubscription } from "../../entities/subscription";
import { TPlan } from "../../entities/plan";
import { ButtonGroupsSmoothOption } from "../ButtonGroupsSmooth/ButtonGroupsSmooth";
import useAvailablePlans from "../../hooks/useAvailablePlans";
import { convertBigNumberToNumber } from "../../utils/convertBigNumberToNumber";
import { LANDING_PACKAGES_BLOCK_CONTACT_US_BUTTON } from "../LandingPackagesBlock/LandingPackagesBlock";
import useGetCheckItemsFromTariff from "../../hooks/useGetCheckItemsFromTariff";
import "./PlansList.sass"
import StandartPlanRenderPrice from "../StandartPlanRenderPrice/StandartPlanRenderPrice";
type TProps = {
  subscription?: Omit<TSubscription, "discount">;
  selectedPlan: TPlan;
  selectedPaidTariffOption: ButtonGroupsSmoothOption;
  setSelectedPaidTariffOption: (value: ButtonGroupsSmoothOption) => void;
  showModal: () => void;
  standardButtonProps: {
    disabled: boolean;
    buttonTitle: {
      id: string;
    };
  }
} & IBaseComponentProps;


const PlansList: FC<TProps> = ({
  intl,
  subscription,
  selectedPlan,
  selectedPaidTariffOption,
  setSelectedPaidTariffOption,
  showModal,
  standardButtonProps
}) => {
  const currentPlanQuery = useGetCurrentPlanQuery((() => { })(), {
    pollingInterval: 60000,
  });

  const { paidTariffs, isLoading: isAvailablePlansLoading } = useAvailablePlans({});
  const { data: tokens, isLoading: isGetPaymentTokensLoading } = useGetPaymentTokensQuery();
  const planType = usePlanType(currentPlanQuery.data);
  const paymentToken = tokens ? tokens[0] : undefined;
  const isTailoredNoCanceled = subscription?.status !== "canceled" && planType === EPlanType.TAILORED;
  const { beginnerCheckItems, standartCheckItems, tailoredCheckItems } = useGetCheckItemsFromTariff(selectedPlan)

  const onButtonGroupsSmoothChange = (event: string) => {
    const _selectedTariff =
      paidTariffs.find((el) => el.name === selectedPaidTariffOption.title) ||
      paidTariffs[0];

    if (_selectedTariff && paymentToken) {
      window.gtag &&
        window.gtag("event", "click_select_plan", {
          plan_name: _selectedTariff?.name,
          page: window.location.pathname,
          plan_price: convertBigNumberToNumber(
            _selectedTariff?.price,
            paymentToken?.decimals
          ),
          time: new Date().toISOString(),
        });
    }
    setSelectedPaidTariffOption({ title: event, value: event });
  };

  const defaultPlanButtonProps = {
    disabled: true,
    title:
      planType === EPlanType.DEFAULT &&
        (!subscription ||
          (subscription.status !== "canceled" &&
            subscription?.status !== "past_due"))
        ? formatMessage(intl, PLANS_LIST_YOUR_ACTIVE_SUBSCRIPTION)
        : undefined,
  };

  const paidTariffsOptions = useMemo(
    () =>
      paidTariffs.map((el, index) => {
        return {
          title: `${el.rps.toString()} RPS`,
          value: el.name,
        } as ButtonGroupsSmoothOption;
      }),
    [paidTariffs]
  );

  const standartButtonClick = () => {
    window.gtag &&
      window.gtag("event", "click_subscribe", {
        plan_name: selectedPlan?.name,
        page: window.location.pathname,
        plan_price: convertBigNumberToNumber(
          selectedPlan?.price,
          paymentToken?.decimals
        ),
        time: new Date().toISOString(),
      });
    showModal();
  }
  if (isAvailablePlansLoading || currentPlanQuery.isLoading || isGetPaymentTokensLoading) return null
  return (
    <div className="plans-list__myplan_block">
      <NewPlanCard
        className="plans-list__myplan_block_item _indent"
        title={formatMessage(intl, PLANS_LIST_FREE)}
        buttonTitle={defaultPlanButtonProps.title}
        renderPrice={() => (
          <div
            style={{ color: "#ffffff99" }}
            className="plans-list__price-text"
          >
            Free
          </div>
        )}
        checkItems={beginnerCheckItems}
        buttonDisabled={defaultPlanButtonProps.disabled}
      />
      <NewPlanCard
        className="plans-list__myplan_block_item"
        title={selectedPlan?.name || paidTariffs[0]?.name || ""}
        buttonTitle={
          standardButtonProps
            ? formatMessage(intl, standardButtonProps.buttonTitle)
            : undefined
        }
        renderPrice={() => <StandartPlanRenderPrice selectedPlan={selectedPlan} />}
        checkItems={standartCheckItems}
        buttonDisabled={standardButtonProps?.disabled || false}
        buttonType={"primary"}
        isBeastMode={
          selectedPlan?.name === "HybridN1500" ||
          selectedPlan?.name === "HybridN425"
        }
        selectedPaidTariffOption={selectedPaidTariffOption}
        paidTariffsOptions={paidTariffsOptions}
        onButtonGroupsSmoothChange={onButtonGroupsSmoothChange}
        buttonClick={standartButtonClick}
      />
      <NewPlanCard
        className="plans-list__myplan_block_item _indent"
        title={formatMessage(intl, PLANS_LIST_TAILORED)}
        buttonTitle={
          isTailoredNoCanceled
            ? formatMessage(intl, PLANS_LIST_YOUR_ACTIVE_SUBSCRIPTION)
            : formatMessage(
              intl,
              LANDING_PACKAGES_BLOCK_CONTACT_US_BUTTON
            )
        }
        renderPrice={() => {
          if (!isTailoredNoCanceled) {
            return (
              <div
                style={{ color: "#7A8CF9" }}
                className="plans-list__price_text"
              >
                Custom
              </div>
            );
          }
        }}
        checkItems={tailoredCheckItems}
        buttonDisabled={isTailoredNoCanceled}
        buttonType="primary"
        isButtonLinkToEmail
        isActiveTailoredPlan={isTailoredNoCanceled}
      />
    </div>
  )
}
export default injectIntl(PlansList);