export const HOME_PAGE_DASHBOARD = { id: "home-page.dashboard" };
export const HOME_PAGE_PRICING_PLANS = { id: "home-page.pricing-plans" };
export const HOME_PAGE_MY_PLAN = { id: "home-page.my-plan" };
export const HOME_PAGE_MY_PLAN_DESCRIPTION = {
  id: "home-page.my-plan-description",
};
export const HOME_PAGE_MY_PLANITEM_SUBTITLE = {
  id: "home-page.my-plan-item-subtitle",
};
export const HOME_PAGE_MY_PLANITEM_DESCRIPTION_PRO = {
  id: "home-page.my-plan-item-description-pro",
};
export const HOME_PAGE_MY_PLANITEM_SUBTITLE_PRO = {
  id: "home-page.my-plan-item-subtitle-pro",
};
export const HOME_PAGE_MY_PLANITEM_DESCRIPTION_HISTORICAL = {
  id: "home-page.my-plan-item-description-historical",
};
export const HOME_PAGE_MY_PLANITEM_SUBTITLE_HISTORICAL = {
  id: "home-page.my-plan-item-subtitle-historical",
};
export const HOME_PAGE_MY_PLAN_ITEM_ADDITIONAL_DESCRIPTION = {
  id: "home-page.my-plan-item-additional-description",
};
export const HOME_PAGE_MY_PLAN_ITEM_ADDITIONAL_DESCRIPTION_PRO = {
  id: "home-page.my-plan-item-additional-description-pro",
};
export const HOME_PAGE_MY_PLAN_ITEM_ADDITIONAL_DESCRIPTION_TAILORED = {
  id: "home-page.my-plan-item-additional-description-tailored",
};

export { default } from "./Dashboard";
