import { useGetCurrentSubscriptionQuery, useGetPlanByIdQuery, useGetProfileQuery } from "../api/reg-service.api";

const useGetPlanFromSubscriptions = () => {
  const { data: subscription } = useGetCurrentSubscriptionQuery();
  const planId = subscription?.planId || 0
  const { data: profile } = useGetProfileQuery();
  const landingId = profile?.landingId || ""
  const { data: currentPlanFromSubscription, error, isLoading } = useGetPlanByIdQuery({ planId, landingId: +landingId }, { skip: !planId })

  return { currentPlanFromSubscription, error, isLoading };
};

export default useGetPlanFromSubscriptions;