import useToken from "antd/es/theme/useToken";

import "./pricing-plans.page.sass";
import { HOME_PAGE_PRICING_PLANS } from "../../components/Dashboard";
import Title from "../../components/Title";
import UpgradeToPro from "../../ui-kit/screens/UpgradeToPro";

const PricingPlans: React.FC = () => {
  const [, token] = useToken();

  return (
    <div
      style={{
        color: token.colorPrimary,
      }}
      className="home-page"
    >
      <Title className="home-page__title" text={HOME_PAGE_PRICING_PLANS} />
      <UpgradeToPro />
    </div>
  );
};

export default PricingPlans;
