import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Actions.module.sass";
import Icon from "../Icon";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

interface IProps {
  className?: string;
  classActionsHead?: string;

  classActionsBody?: string;
  classActionsOption?: string;

  items: { action: VoidFunction; icon: string; title: string }[];
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  small?: boolean;
  up?: boolean;
}

const Actions: React.FC<IProps> = ({
  className,
  classActionsHead,
  classActionsBody,
  classActionsOption,
  items,
  visible,
  setVisible,
  small,
  up,
}) => {
  const [innerVisible, setInnerVisible] = useState(false);

  const toggle = () => {
    setVisible ? setVisible(!visible) : setInnerVisible(!innerVisible);
  };

  const rootRef = useRef(null);

  useOutsideClick(rootRef, () =>
    setVisible ? setVisible(false) : setInnerVisible(false)
  );

  return (
    <div
      ref={rootRef}
      className={cn(
        styles.actions,
        className,
        {
          [styles.small]: small,
        },
        {
          [styles.up]: up,
        },
        {
          [styles.active]: visible ? visible : innerVisible,
        }
      )}
    >
      <button
        className={cn(styles.head, classActionsHead)}
        onClick={() => toggle()}
      >
        <Icon name="more-horizontal" size="24" />
      </button>
      <div className={cn(styles.body, classActionsBody)}>
        {items.map((x, index) => (
          <button
            className={cn(styles.option, classActionsOption)}
            onClick={x.action}
            key={index}
          >
            {x.icon && <Icon name={x.icon} size="24" />}
            {x.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Actions;
