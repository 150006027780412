import React, { useMemo, useState } from "react";
import styles from "./TrafficChannel.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  ReferenceLine,
} from "recharts";
import useDarkMode from "../../../../hooks/useDarkMode";
import {
  useGetCurrentPlanQuery,
  useGetRequestPerPeriodQuery,
} from "../../../../api/reg-service.api";
import { TPeriod } from "../../../../api/types/get-requests-per-period";
import useLimits from "../../../../hooks/useLimits";
import BigNumber from "bignumber.js";
import {
  addInterval,
  formatLabelDate,
  formatterXMap,
  getInterval,
  isEarlerWithStartPeriod,
  mapEndPeriodIntervalByPeriod,
  mapStartPeriodIntervalByPeriod,
} from "../../../../utils/formatDate";
import { capitaliseFirstLetter } from "../../../../utils/utils";

const intervals = [
  "Hours", // by hour
  "Days", // by day
  "Months", // by month
];

interface IProps {
  className?: string;
}

enum EMeasure {
  UNITS,
  THOUSANDS = "K",
  MILLIONS = "M",
}

const mapOptionToPeriod: Record<string, TPeriod> = {
  Hours: "hour",
  Days: "day",
  Months: "month",
};

const mapPeriodToOption: Record<TPeriod, string> = {
  hour: "Hours",
  day: "Days",
  month: "Months",
  minute: "Minutes",
};

const formatterRpcMap = {
  [EMeasure.UNITS]: (rpc: number) => Number(new BigNumber(rpc).toString()),
  [EMeasure.THOUSANDS]: (rpc: number) =>
    Number(new BigNumber(rpc).times(1e-3).toString()),
  [EMeasure.MILLIONS]: (rpc: number) =>
    Number(new BigNumber(rpc).times(1e-6).toString()),
};

const OLD_PLAN_DATA_KEY = "previousPlanRequests";
const TOTAL_REQUESTS_DATA_KEY = "totalRequests";

const dataKeyToNameMap = {
  [OLD_PLAN_DATA_KEY]: "Previous Requests",
};

const TrafficChannel: React.FC<IProps> = ({ className }) => {
  const { dayLimit, monthLimit } = useLimits();
  const darkMode = useDarkMode(true, { storageKey: "" });
  const [period, setPeriod] = useState<TPeriod>("hour");
  const { data: currentPlan } = useGetCurrentPlanQuery();

  const startPlanDate = currentPlan?.validFrom;

  const { data: requestPerPeriod, isLoading: isLoadingrequestsPerPeriod } =
    useGetRequestPerPeriodQuery(
      {
        period,
        startDate: startPlanDate,
      },
      {
        pollingInterval: 60000,
        skip: !currentPlan,
      }
    );

  const limit = useMemo(() => {
    let _limit;
    switch (period) {
      case "day":
        _limit = dayLimit;
        break;
      case "hour":
        _limit = 0;
        break;
      case "month":
        _limit = monthLimit;
        break;
    }

    return _limit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPerPeriod, period]);

  const measure = useMemo(() => {
    const maxValue =
      requestPerPeriod
        ?.filter((el) => !isEarlerWithStartPeriod(el, startPlanDate, period))
        .reduce((acc, el) => {
          if (acc < el.requests) return el.requests;
          return acc;
        }, 0) || 0;
    return getMeasureOfValue(maxValue);
  }, [requestPerPeriod, startPlanDate, period]);

  const formatter = useMemo(() => {
    return formatterRpcMap[measure];
  }, [measure]);

  const data = useMemo(() => {
    if (!requestPerPeriod) return [];
    if (!limit) {
      return [
        ...requestPerPeriod.map((el) => {
          if (isEarlerWithStartPeriod(el, startPlanDate, period)) {
            return {
              name: formatterXMap[period](el.date),
              requests: 0,
              date: el.date,
              [OLD_PLAN_DATA_KEY]: el.requests,
            };
          }
          return {
            name: formatterXMap[period](el.date),
            requests: formatter(el.requests),
            date: el.date,
          };
        }),
      ].reverse();
    }

    const calclulateOverLimit = (requests: number, limit: number) => {
      const overLimitRequests = requests - limit;
      const minimalBarSize = new BigNumber(requests)
        .div(100)
        .times(3)
        .toNumber();
      if (overLimitRequests < minimalBarSize) return minimalBarSize;
      return overLimitRequests;
    };
    return [
      ...requestPerPeriod.map((el) => {
        if (isEarlerWithStartPeriod(el, startPlanDate, period)) {
          return {
            name: formatterXMap[period](el.date),

            requests: 0,
            overLimitRequests: undefined,
            clearOverlimitRequests: undefined,
            limit: formatter(limit),
            date: el.date,
            [OLD_PLAN_DATA_KEY]: el.requests,
          };
        }

        return {
          name: formatterXMap[period](el.date),
          [TOTAL_REQUESTS_DATA_KEY]: formatter(el.requests),
          requests:
            el.requests > limit
              ? formatter(el.requests - (el.requests - limit)) // underLimit
              : formatter(el.requests), // all
          overLimitRequests:
            el.requests > limit
              ? formatter(calclulateOverLimit(el.requests, limit))
              : undefined,
          clearOverlimitRequests:
            el.requests > limit ? formatter(el.requests - limit) : undefined,
          limit: formatter(limit),
          date: el.date,
        };
      }),
    ].reverse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPerPeriod, limit, formatter]);

  const legend = useMemo(() => {
    const result = [
      {
        title: "Under-limit requests",
        color: "#007AFF",
      },
    ];
    if (!data.length && !isLoadingrequestsPerPeriod) return [];
    if (limit) {
      const measureOfValue = getMeasureOfValue(limit);
      return [
        ...result,
        {
          title: `Your limit: ${formatterRpcMap[measureOfValue](
            limit
          )}${measureOfValue} / ${capitaliseFirstLetter(period)}`,
          color: "#FF3B30",
        },
      ];
    }
    return result;
  }, [data.length, isLoadingrequestsPerPeriod, limit, period]);

  return (
    <Card
      className={cn(styles.card, className)}
      title="RPC Requests Overview"
      classTitle="title-purple"
      head={
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={mapPeriodToOption[period]}
          setValue={(value) => {
            const nextPeriod = mapOptionToPeriod[value];
            if (nextPeriod) {
              setPeriod(nextPeriod);
            }
          }}
          options={intervals}
          small
        />
      }
    >
      <div className={styles.chart}>
        {!data.length && !isLoadingrequestsPerPeriod ? (
          <>
            <img
              className={styles.stub}
              alt="stub"
              src="/images/content/stub-1.svg"
            />
            <div className={cn(styles.titleStub)}>No data available</div>
          </>
        ) : null}
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            barSize={46}
            barGap={8}
          >
            <CartesianGrid
              strokeDasharray="none"
              stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
              vertical={false}
            />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
              padding={{ left: 10 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
              tickFormatter={(val) => {
                if (measure) return `${val}${measure}`;

                return val;
              }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#272B30",
                borderColor: "rgba(255, 255, 255, 0.12)",
                borderRadius: 8,
                boxShadow:
                  "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
              }}
              labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}
              itemStyle={{
                padding: 0,
                textTransform: "capitalize",
                fontSize: 12,
                fontWeight: "600",
                color: "#fff",
              }}
              content={(props) => {
                const { contentStyle, payload, itemStyle } = props;
                const previousPlanRequests =
                  payload && payload[0]
                    ? payload[0].payload[OLD_PLAN_DATA_KEY]
                    : undefined;
                return (
                  <div className={styles.tooltipRoot} style={contentStyle}>
                    <div style={props.labelStyle}>
                      {props.labelFormatter && payload
                        ? props.labelFormatter(props.label, payload)
                        : props.label}
                    </div>
                    {payload &&
                      payload.map((el, i) => {
                        if (
                          el.dataKey === "requests" &&
                          el.payload[TOTAL_REQUESTS_DATA_KEY]
                        )
                          return (
                            <div style={itemStyle}>
                              {props.labelFormatter && props.formatter
                                ? `${el.dataKey}: ${props.formatter(
                                    el.payload[TOTAL_REQUESTS_DATA_KEY],
                                    el.dataKey,
                                    el,
                                    i,
                                    payload
                                  )}`
                                : `${el.dataKey}: ${el.payload[TOTAL_REQUESTS_DATA_KEY]}`}
                            </div>
                          );
                        return el.dataKey ? (
                          <div style={itemStyle}>
                            {props.labelFormatter && props.formatter
                              ? `${el.dataKey}: ${props.formatter(
                                  el.payload[el.dataKey],
                                  el.dataKey,
                                  el,
                                  i,
                                  payload
                                )}`
                              : `${el.dataKey}: ${el.payload[el.dataKey]}`}
                          </div>
                        ) : null;
                      })}
                    {previousPlanRequests && payload && payload[0] ? (
                      <>
                        <div className={styles.tooltipHint} style={itemStyle}>
                          Above you see only the requests related to the current
                          plan and the current month, which is defined as 30
                          days from the date you purchased or renewed your plan.
                          The number of requests related to previous periods or
                          plans can be found below.
                        </div>
                        <div style={itemStyle}>
                          {props.formatter
                            ? `${
                                dataKeyToNameMap[OLD_PLAN_DATA_KEY]
                              }: ${props.formatter(
                                previousPlanRequests,
                                OLD_PLAN_DATA_KEY,
                                payload[0],
                                0,
                                payload
                              )}`
                            : `${dataKeyToNameMap[OLD_PLAN_DATA_KEY]}: ${previousPlanRequests}`}
                        </div>
                      </>
                    ) : null}
                  </div>
                );
              }}
              cursor={{ fill: "#f3f2f3" }}
              formatter={(val, name, item) => {
                let _val;

                _val = val;
                if (name === "overLimitRequests") {
                  const clearOverLimitRequest = item.payload
                    ? item.payload.clearOverlimitRequests
                    : 0;

                  _val = clearOverLimitRequest;
                }

                if (name === OLD_PLAN_DATA_KEY) {
                  const _measure = getMeasureOfValue(Number(val));

                  _val = formatterRpcMap[_measure](Number(val));

                  return `${_val}${_measure ? _measure : ""}`;
                }

                if (measure) return `${_val}${measure}`;

                return _val;
              }}
              labelFormatter={(label, payload) => {
                if (payload[0]) {
                  const date = payload[0].payload.date;
                  if (!startPlanDate) return date;
                  const interval = getInterval(startPlanDate);
                  const startIntervalDate = addInterval(
                    date,
                    mapStartPeriodIntervalByPeriod[period](interval)
                  );
                  const endIntervalDate = addInterval(
                    startIntervalDate.toISOString(),
                    mapEndPeriodIntervalByPeriod[period]
                  );

                  return `${formatLabelDate(
                    startIntervalDate,
                    period
                  )} - ${formatLabelDate(endIntervalDate, period)}`;
                }

                return label;
              }}
            />
            <Bar dataKey="requests" stackId="a" fill="#007AFF" />
            <Bar dataKey="overLimitRequests" stackId="a" fill="#FF3B30" />
            {limit && (
              <ReferenceLine
                y={formatter(limit)}
                stroke="#FF3B30"
                strokeDasharray="5 5"
                ifOverflow="visible"
                strokeWidth={2}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.legend}>
        {legend.map((x, index) => (
          <div className={styles.indicator} key={index}>
            <div
              className={styles.color}
              style={{ backgroundColor: x.color }}
            ></div>
            {x.title}
          </div>
        ))}
      </div>
    </Card>
  );
};

export default TrafficChannel;

function getMeasureOfValue(value: number) {
  let measure = EMeasure.UNITS;
  if (value * 1e-6 > 1) measure = EMeasure.MILLIONS;
  else if (value * 1e-3 > 1) measure = EMeasure.THOUSANDS;

  return measure;
}
