import { FC } from "react";
import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../types";
import TrafficChannel from "../../ui-kit/screens/Customers/TrafficChannel";
import "./DashboardNew.sass";
import Overview from "../../ui-kit/screens/Customers/Overview";
import TopCountries from "../../ui-kit/screens/Earning/TopCountries";
import MoreCustomers from "../../ui-kit/screens/Home/MoreCustomers";
import ProductActivity from "../../ui-kit/screens/ProductsDashboard/ProductActivity";
import RequestPerSecond from "../RequestPerSecond/RequestPerSecond";
import useSubscriptionDetails from "../../hooks/useSubscriptionDetails";
import useGetPlanFromSubscriptions from "../../hooks/useGetPlanFromSubscriptions";
import useNextBilledPrice from "../../hooks/useNextBilledPrice";
import { useGetPaymentTokensQuery } from "../../api/reg-service.api";

type TProps = {
  currentPlanQuery: any;
  currentSubscriptionQuery: any;
} & IBaseComponentProps;

const DashboardNew: FC<TProps> = ({
  intl,
  currentPlanQuery,
  currentSubscriptionQuery,
}) => {
  const { currentPlanFromSubscription } = useGetPlanFromSubscriptions();
  const { data: tokens } = useGetPaymentTokensQuery();
  const { subscription } = useSubscriptionDetails(
    currentPlanQuery,
    currentSubscriptionQuery,
    currentPlanFromSubscription
  );
  const nextBilledPrice = useNextBilledPrice(
    currentPlanQuery,
    currentSubscriptionQuery,
    tokens,
    currentPlanFromSubscription
  );

  return (
    <div className="dashboard-new">
      <RequestPerSecond className="dashboard-new__request-per-second" />
      <TrafficChannel className={`dashboard-new__traffic-channel`} />
      <Overview className={`dashboard-new__overview`} />
      {currentPlanQuery.data && subscription && (
        <TopCountries
          subscription={subscription}
          nextBilledPrice={nextBilledPrice}
          className={`dashboard-new__top-countries`}
          plan={currentPlanQuery.data}
        />
      )}
      <ProductActivity className="dashboard-new__product-activity" />
      <MoreCustomers className={`dashboard-new__more-customers`} />
    </div>
  );
};

export default injectIntl(DashboardNew);
