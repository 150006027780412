import cn from "classnames";

import "./RegisterForm.sass";
import Icon from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Space, Typography } from "antd";
import { injectIntl } from "react-intl";
import formatMessage from "../../utils/formatMessage";
import { ClosedEye, OpenedEye } from "../../resources/icons/password-eye";
import { useRegisterMutation } from "../../api/reg-service.api";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactNode, useRef, useState } from "react";
import InputPassword from "../InputPassword/InputPassword";
import { responsePathOr } from "../../utils/utils";
import { IBaseComponentProps } from "../../types";
import { Link } from "react-router-dom";
import { ROUTE_PATH } from "../../constants";
import { useTimer } from "../../hooks/useTimer";
import {
  LANDING_FOOTER_PRIVACY_POLICE,
  LANDING_FOOTER_TERMS_OF_SERVICE,
} from "../LandingFooter/LandingFooter";
import useGetLandingLinkByAuthorizedUser from "../../hooks/useGetLandingLinkByAuthorizedUser";
import GoogleButton from "../GoogleButton";

type TRenderButtons = (props: {
  disabled?: boolean;
  submitError?: string;
}) => ReactNode;

interface IProps extends IBaseComponentProps {
  title?: ReactNode;
  renderButtons?: TRenderButtons;
  onSuccessCb?: () => void;
  iswithVerificationStage?: boolean;
}

interface IFormInput {
  email: string;
  password: string;
  repeatPassword: string;
}

const key =
  (window as Window & typeof globalThis & { env: Record<string, string> }).env
    .SITEKEY_FOR_CAPTCHA || "";

const TITLE = { id: "register-form.title" };
const SUBTITLE = { id: "register-form.subtitle" };
const EMAIL_LABEL = { id: "register-form.email-label" };
const PASSWORD_LABEL = { id: "register-form.password-label" };
const REPEAT_PASSWORD_LABEL = { id: "register-form.repeat-password-label" };
const CREATE_ACCOUNT = { id: "register-form.create-account" };
const REGISTRATION_CONFIRM_PAGE_TITLE = {
  id: "registration-confirm-page.title",
};
const REGISTRATION_CONFIRM_PAGE_DESCRIPTION = {
  id: "registration-confirm-page.description",
};
const REGISTRATION_CONFIRM_PAGE_RE_SEND_EMAIL = {
  id: "registration-confirm-page.re-send-email",
};
const REGISTRATION_CONFIRM_PAGE_UPDATE_EMAIL = {
  id: "registration-confirm-page.update-email",
};

const HAVE_AN_ACCOUNT = { id: "register-form.have-an-account" };
const SIGNIN = { id: "register-form.signin" };
// const ACCEPT = { id: "register-form.accept" };
const ACCEPT = { id: "register-form.accept" };

const openCloseIcons = (visible: boolean) =>
  visible ? <Icon component={OpenedEye} /> : <Icon component={ClosedEye} />;

const RegisterForm: React.FC<IProps> = ({
  className,
  intl,
  title,
  renderButtons,
  onSuccessCb,
  iswithVerificationStage,
}) => {
  const [register, { isLoading }] = useRegisterMutation();
  const { formattedTime, start, count, reset } = useTimer(80);

  const captchaRef = useRef<any>(null);
  const [captchaIsPassed, setCaptchaIsPassed] = useState(false);
  const [form] = Form.useForm();
  const [formValid, setFormValid] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [submitError, setSubmitError] = useState<string>();
  const [isRegistrationConfirm, setIsRegistrationConfirm] =
    useState<boolean>(false);
  const { landingId, landingLink } = useGetLandingLinkByAuthorizedUser();

  const handleSuccess = () => {
    onSuccessCb && onSuccessCb();
    iswithVerificationStage && setIsRegistrationConfirm(true);
    reset();
    start();
  };

  const hanldeError = (err: any) => {
    setSubmitError(responsePathOr(err));
  };

  const onSubmit = async (values: IFormInput) => {
    try {
      window.gtag && window.gtag("event", "click_sign_up");
      await register({
        ...values,
        email: values.email.toLowerCase(),
        landingId: landingId,
      }).unwrap();
      handleSuccess();
    } catch (err) {
      hanldeError(err);
    }
  };

  const handleChangeCaptcha = (value: string | null) => {
    setCaptchaIsPassed(true);
  };
  const onExpired = () => {
    setCaptchaIsPassed(false);
  };
  const onErrored = () => {
    setCaptchaIsPassed(false);
  };

  const updateEmail = () => {
    onErrored();
    setIsRegistrationConfirm(false);
    reset();
  };

  const resendSubmit = () => {
    // request ?
    form.submit();
  };

  const disabled = formValid || !isFocused || !captchaIsPassed || isLoading;
  const resendButtonDisabled = !!count || isLoading;
  return (
    <Form
      form={form}
      className={cn("register-form", className)}
      onFinish={onSubmit}
      onFieldsChange={() => {
        if (submitError) setSubmitError("");
      }}
      layout="vertical"
      autoComplete="off"
      onValuesChange={() => {
        setIsFocused(true);
        form
          .validateFields()
          .then((values) => {
            const valuesAvailable = Object.values(values).every(
              (value) => value
            );
            setFormValid(!valuesAvailable);
          })
          .catch((errorInfo) => {
            const valuesAvailable = Object.values(errorInfo.values).every(
              (value) => value
            );
            if (valuesAvailable && !errorInfo.errorFields.length) {
              setFormValid(false);
            } else {
              setFormValid(true);
            }
          });
      }}
    >
      {title ? (
        title
      ) : (
        <Form.Item>
          <Typography.Title level={5}>
            {isRegistrationConfirm
              ? formatMessage(intl, REGISTRATION_CONFIRM_PAGE_TITLE)
              : formatMessage(intl, TITLE)}
          </Typography.Title>
          <p className="register-form_subtitle">
            {isRegistrationConfirm
              ? formatMessage(intl, REGISTRATION_CONFIRM_PAGE_DESCRIPTION)
              : formatMessage(intl, SUBTITLE)}
          </p>
        </Form.Item>
      )}
      <Form.Item
        label={formatMessage(intl, EMAIL_LABEL)}
        name={"email"}
        rules={[
          {
            type: "email",
          },
        ]}
      >
        <Input readOnly={isRegistrationConfirm} />
      </Form.Item>
      <Form.Item
        label={formatMessage(intl, PASSWORD_LABEL)}
        rules={[
          {
            min: 8,
          },
        ]}
        name={"password"}
      >
        <InputPassword
          readOnly={isRegistrationConfirm}
          autoComplete="new-password"
          iconRender={openCloseIcons}
        />
      </Form.Item>
      <Form.Item
        label={formatMessage(intl, REPEAT_PASSWORD_LABEL)}
        rules={[
          {
            min: 8,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Passwords don't match"));
            },
          }),
        ]}
        name={"repeatPassword"}
      >
        <Input.Password
          readOnly={isRegistrationConfirm}
          iconRender={openCloseIcons}
        />
      </Form.Item>
      {key && !isRegistrationConfirm && (
        <Form.Item>
          <ReCAPTCHA
            theme="dark"
            className="register-form_captcha"
            size="normal"
            ref={captchaRef}
            sitekey={key}
            onChange={(e: string | null) => handleChangeCaptcha(e)}
            onExpired={() => onExpired()}
            onErrored={() => onErrored()}
          />
        </Form.Item>
      )}
      <div className="register-form_checkbox_block_block">
        {!isRegistrationConfirm && (
          <Form.Item>
            <Form.Item
              className="register-form_checkbox"
              name="accept"
              valuePropName="checked"
            >
              <Checkbox name="accept"></Checkbox>
            </Form.Item>
          </Form.Item>
        )}
        <div>
          {formatMessage(intl, ACCEPT)}{" "}
          <Link
            className="register-form_link"
            to={`${landingLink}/docs/terms`}
            target="_blank"
            rel="noreferrer"
          >
            {formatMessage(intl, LANDING_FOOTER_TERMS_OF_SERVICE)}
          </Link>{" "}
          and
          <br />
          <Link
            className="register-form_link"
            data-testid={"history-link"}
            to={`${landingLink}/docs/data-policy`}
            target="_blank"
            rel="noreferrer"
          >
            {formatMessage(intl, LANDING_FOOTER_PRIVACY_POLICE)}
          </Link>
        </div>
      </div>
      {renderButtons ? (
        renderButtons({ disabled, submitError })
      ) : (
        <Form.Item>
          {isRegistrationConfirm ? (
            <>
              <Button
                style={{ margin: "11px 0 27px 0" }}
                disabled={resendButtonDisabled}
                type="primary"
                className="register-form__submit"
                onClick={resendSubmit}
              >
                {formatMessage(intl, REGISTRATION_CONFIRM_PAGE_RE_SEND_EMAIL)} (
                {formattedTime})
              </Button>
              <span
                onClick={updateEmail}
                className="register-form__update_email"
              >
                {formatMessage(intl, REGISTRATION_CONFIRM_PAGE_UPDATE_EMAIL)}
              </span>
            </>
          ) : (
            <>
              <Button
                disabled={disabled}
                style={{ margin: "0 0 20px 0" }}
                type="primary"
                htmlType="submit"
                className="register-form__submit"
              >
                {formatMessage(intl, CREATE_ACCOUNT)}
              </Button>

              <GoogleButton
                onSuccess={handleSuccess}
                onError={hanldeError}
                landingId={landingId}
              />
              <Space className="register-form__bottom_block">
                <Typography
                  style={{
                    fontSize: 14,
                  }}
                >
                  {formatMessage(intl, HAVE_AN_ACCOUNT)}
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                  }}
                >
                  <Link to={`${ROUTE_PATH.LOGIN}?landing-id=${landingId}`}>
                    {formatMessage(intl, SIGNIN)}
                  </Link>
                </Typography>
              </Space>
            </>
          )}
        </Form.Item>
      )}
    </Form>
  );
};

export default injectIntl(RegisterForm);
