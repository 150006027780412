import { TSubscription } from "../entities/subscription";

const usePlanParameters = (
  subscription: Omit<TSubscription, "discount"> & { endDate?: number },
  nextBilledPrice: string
) => {
  return [
    {
      title: "Billing Cycle",
      content: "30 days",
    },
    {
      title: "Next Payment Amount",
      content:
        nextBilledPrice && nextBilledPrice !== "-"
          ? `${nextBilledPrice}$`
          : "-",
    },
    {
      title: "Next Payment Due",
      content:
        subscription.nextBilledAt &&
        subscription.status !== "past_due" &&
        subscription.nextBilledAt !== "doNotDisplay"
          ? new Date(subscription.nextBilledAt).toLocaleDateString()
          : "-",
    },
  ];
};

export default usePlanParameters;
