import React, { useState } from "react";
import styles from "./UpgradeToPro.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Packages from "./Packages";
import useGetSelectedPlan from "../../../hooks/useGetSelectedPlan";
import CheckoutModal from "../../../components/CheckoutModal";
import {
  regServiceApi,
  useGetDiscountCodesByLandingIdQuery,
} from "../../../api/reg-service.api";
import useGetLandingLinkByAuthorizedUser from "../../../hooks/useGetLandingLinkByAuthorizedUser";
import useQueryWithPolling from "../../../hooks/useQueryWithPolling";
import {
  PLAN_TS_STORAGE_KEY,
  SUBSCRIPTION_TS_STORAGE_KEY,
} from "../../../constants";
import { pick } from "ramda";
import { TPlan } from "../../../entities/plan";
import { TSubscription } from "../../../entities/subscription";
import { useDispatch } from "react-redux";
import TailoredCard from "../../../components/TailoredCard";

const UpgradeToPro: React.FC = () => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const { landingId } = useGetLandingLinkByAuthorizedUser();
  const {
    selectedPlan,
    selectedPaidTariffOption,
    setSelectedPaidTariffOption,
  } = useGetSelectedPlan();

  const { data: discountCodes } = useGetDiscountCodesByLandingIdQuery({
    landingId,
  });
  const comparePlan = (data?: TPlan, prevData?: TPlan) => {
    return !!(
      data?.id !== prevData?.id || data?.validUntil !== prevData?.validUntil
    );
  };

  const { startPolling: startPollingCurrentPlan } = useQueryWithPolling(
    regServiceApi.endpoints.getCurrentPlan,
    PLAN_TS_STORAGE_KEY,
    pick(["id", "validUntil"]),
    comparePlan
  );

  const compareSubscription = (
    data: TSubscription,
    prevData: TSubscription
  ) => {
    return !!(
      data?.id !== prevData?.id ||
      data?.status !== prevData?.status ||
      data?.nextBilledAt !== prevData?.nextBilledAt
    );
  };

  const { startPolling: startPollingCurrentSubscription } = useQueryWithPolling(
    regServiceApi.endpoints.getCurrentSubscription,
    SUBSCRIPTION_TS_STORAGE_KEY,
    pick(["id", "status", "nextBilledAt"]),
    compareSubscription
  );

  const checkoutModalComplete = () => {
    startPollingCurrentPlan("creation");
    startPollingCurrentSubscription("creation");
  };

  const showModal = () => {
    dispatch(regServiceApi.util.invalidateTags(["priceItem"]));
    setVisible(true);
  };

  return (
    <>
      <Packages
        className={styles.packages}
        onUpdateClick={showModal}
        selectedPlan={selectedPlan}
        selectedPaidTariffOption={selectedPaidTariffOption}
        setSelectedPaidTariffOption={setSelectedPaidTariffOption}
      />
      {/* <Faq /> */}
      <TooltipGlodal />
      <CheckoutModal
        selectedPlan={selectedPlan}
        discountCodes={discountCodes}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        onComplete={checkoutModalComplete}
      />
      <TailoredCard />
    </>
  );
};

export default UpgradeToPro;
