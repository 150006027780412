import { FC } from "react";
import { TPlan } from "../../entities/plan";
import cn from "classnames";
import {
  convertBigNumber,
  convertBigNumberToNumber,
} from "../../utils/convertBigNumberToNumber";
import { useGetPaymentTokensQuery } from "../../api/reg-service.api";
// import formatMessage from "../../utils/formatMessage";
import { IBaseComponentProps } from "../../types";
// import {
//   PLANS_LIST_DISCOUNT,
//   PLANS_LIST_DISCOUNT_FIRST,
//   PLANS_LIST_TAX,
//   PLANS_LIST_PLANCARD_MONTH,
// } from "../PlansList";
import { injectIntl } from "react-intl";

type TProps = {
  selectedPlan: TPlan;
} & IBaseComponentProps;

const StandartPlanRenderPrice: FC<TProps> = ({ selectedPlan, className }) => {
  const { data: tokens } = useGetPaymentTokensQuery();
  const paymentToken = tokens ? tokens[0] : undefined;

  return (
    <>
      {selectedPlan?.discountPercentage ? (
        <div className={cn("plans-list__price", className)}>
          <span className="plans-list__discount_price">
            {convertBigNumberToNumber(
              selectedPlan?.price,
              paymentToken?.decimals
            )}
            $
          </span>
          <span className="h1 plans-list__price_additionally">
            {selectedPlan?.discountPercentage
              ? convertBigNumber(selectedPlan?.price, paymentToken?.decimals)
                  .times(1 - selectedPlan?.discountPercentage / 100)
                  .integerValue()
                  .toString()
              : null}
            $
          </span>
        </div>
      ) : (
        <div className={cn("plans-list__price", className)}>
          <span className="h1 plans-list__price_additionally">
            {convertBigNumberToNumber(
              selectedPlan?.price,
              paymentToken?.decimals
            )}
            $
          </span>
        </div>
      )}
    </>
  );
};

export default injectIntl(StandartPlanRenderPrice);
