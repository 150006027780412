import { useMemo } from "react";
import usePlanType from "./usePlanType";
import { TSubscription } from "../entities/subscription";
import { checkAvailabilityTs, mapCycleToMs } from "../utils/utils";
import { IGetCurrentPlanResponse } from "../api/types/get-current-plan";
import { IGetCurrentSubscriptionResponse } from "../api/types/get-subscription";
import { TPlan } from "../entities/plan";
import {
  createCancelledView,
  createDefaultView,
  createFallbackView,
  createTailoredView,
  isCancelledSubscription,
  isDefaultPlan,
  isResponseFirstLoading,
  isTailoredPlan,
} from "../utils/subscriptions";
import useSubscriptionBadge from "./useSubscriptionBadge";

export const HOME_PAGE_BUTTON_SUBSCRIBE = { id: "home-page.button-subscribe" };
export const HOME_PAGE_YOUR_ACTIVE_SUBSCRIPTION = {
  id: "plans-list.your-active-subscription",
};
export function getExpireSubscriptionDate(currentSubscription: TSubscription) {
  const billingCycle = currentSubscription.billingCycle;
  if (!billingCycle) return 0;
  const fromTime = currentSubscription.firstBilledAt
    ? new Date(currentSubscription.firstBilledAt).getTime()
    : 0;
  const nextDate =
    fromTime +
    mapCycleToMs[billingCycle.interval] * billingCycle.frequency * 1000;
  return nextDate;
}

export function checkPendingPayment(
  planPendingData:
    | ({ ts: number; trigger: string } & Record<string, any>)
    | undefined
) {
  return (
    planPendingData &&
    planPendingData.trigger === "creation" &&
    checkAvailabilityTs(planPendingData.ts, {
      frequency: 10,
      interval: "minute",
    })
  );
}

export function checkTooLongPaymentPending(
  planPendingData:
    | ({ ts: number; trigger: string } & Record<string, any>)
    | undefined
) {
  return (
    planPendingData &&
    planPendingData.trigger === "creation" &&
    checkAvailabilityTs(planPendingData.ts, {
      frequency: 1,
      interval: "day",
    })
  );
}

const useSubscriptionDetails = (
  currentPlanQuery: {
    data?: IGetCurrentPlanResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentSubscriptionQuery: {
    data?: IGetCurrentSubscriptionResponse;
    error?: unknown;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
  },
  currentPlanFromSubscription?: TPlan
) => {
  const planType = usePlanType(currentPlanQuery.data);

  const badge = useSubscriptionBadge(
    currentPlanQuery,
    currentSubscriptionQuery
  );

  const subscription = useMemo<
    Omit<TSubscription, "discount"> | undefined
  >(() => {
    if (
      !currentPlanQuery.data ||
      isResponseFirstLoading(currentPlanQuery) ||
      isResponseFirstLoading(currentSubscriptionQuery)
    )
      return undefined;
    else if (isDefaultPlan(planType)) {
      return createDefaultView(
        currentSubscriptionQuery,
        currentPlanQuery,
        currentPlanFromSubscription
      );
    } else if (
      isCancelledSubscription(currentPlanQuery, currentSubscriptionQuery)
    ) {
      return createCancelledView(
        currentPlanQuery,
        currentSubscriptionQuery,
        currentPlanFromSubscription
      );
    } else if (isTailoredPlan(planType)) {
      return createTailoredView(currentPlanQuery, currentPlanFromSubscription);
    }

    // Add case for tailored and another subscription

    return createFallbackView(
      currentSubscriptionQuery,
      currentPlanFromSubscription
    );
  }, [
    currentPlanQuery,
    currentSubscriptionQuery,
    planType,
    currentPlanFromSubscription,
  ]);

  // const subscribeIsAvailable = useMemo(() => {
  //   if (subscription?.status === "canceled") return true;
  //   else if (
  //     planType === EPlanType.DEFAULT &&
  //     (planPendingData || subscriptionPendingData)
  //   )
  //     return true;
  //   else if (
  //     checkTooLongPaymentPending(planPendingData) ||
  //     checkTooLongPaymentPending(subscriptionPendingData)
  //   )
  //     return true;
  //   return false;
  // }, [
  //   planPendingData,
  //   planType,
  //   subscription?.status,
  //   subscriptionPendingData,
  // ]);
  /*
    DEFAULT:
    disabled false
    button subscribe

    Have active subscription:
    disabled: true
    button: Your active plan

    Pending subscription:
    disabled: true
    button: subscribe

    Over due pending subscription:
    disabled: false
    button: subscribe

    Over due:
    disabled: true
    button: Your active plan

    Beginner and Active subscription:
    disabled: true
    button: subscribe

    Tailored:
    button: undefined
    disabled: true
  */
  return {
    subscription,
    badge,
  };
};

export default useSubscriptionDetails;
