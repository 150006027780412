import { Popover } from "antd";
import { FC, useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import exit from "../../resources/images/exit.svg";
import pencil from "../../resources/images/pencil.svg";
import { Link } from "react-router-dom";
import { ROUTE_PATH } from "../../constants";
import { useLogoutMutation } from "../../api/reg-service.api";
import useScreenWidth from "../../hooks/useScreenWidth";
import { ReactComponent as Home } from "../../resources/images/home.svg";
import "./AccountMenu.sass";
import { TUser } from "../../entities/user";
import formatMessage from "../../utils/formatMessage";
import { IBaseComponentProps } from "../../types";

const LANDING_HEADER_LOGOUT = { id: "landing-header.logout" };

type TProps = {
  intl: IntlShape;
  profile: TUser;
} & IBaseComponentProps;

const AccountMenu: FC<TProps> = ({ profile, intl }) => {
  const [logout] = useLogoutMutation();
  const isSmallScreen = useScreenWidth();
  const [isHover, setIsHover] = useState(false);
  const [isPopupShow, setIsPopupShow] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setIsPopupShow(newOpen);
  };

  return (
    <Popover
      placement="bottomLeft"
      content={
        <div className="account_menu__popup_content">
          <div
            style={{ cursor: "default" }}
            className="account_menu__popup_content_block"
          >
            <div className="account_menu__user_icon"></div>
            <span className="account_menu__popup_content_block_email">
              {profile?.email}
            </span>
          </div>
          <div className="account_menu__popup_content_line"></div>
          <Link onClick={() => handleOpenChange(false)} to={ROUTE_PATH.ROOT}>
            <div className="account_menu__popup_content_block">
              <Home className="account_menu__popup_content_icon" />
              <span className="account_menu__popup_content_block_text">
                Home
              </span>
            </div>
          </Link>
          {profile.oauthSub ? null : (
            <Link
              onClick={() => handleOpenChange(false)}
              to={ROUTE_PATH.PROFILE}
            >
              <div className="account_menu__popup_content_block">
                <img src={pencil} alt="" />
                <span className="account_menu__popup_content_block_text">
                  Edit Profile
                </span>
              </div>
            </Link>
          )}

          <div
            className="account_menu__popup_content_block"
            onClick={() => {
              handleOpenChange(false);
              logout();
            }}
          >
            <img src={exit} alt="" />
            <span className="account_menu__popup_content_block_text">
              {formatMessage(intl, LANDING_HEADER_LOGOUT)}
            </span>
          </div>
        </div>
      }
      className="account_menu__popup"
      rootClassName="account_menu__popup_root"
      arrow={false}
      trigger="click"
      open={isPopupShow}
      onOpenChange={handleOpenChange}
    >
      {isSmallScreen ? (
        <div className="account_menu__user_icon"></div>
      ) : (
        <div
          onClick={() => handleOpenChange(!isPopupShow)}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className={`account_menu__user ${
            (isHover || isPopupShow) && "account_menu__hover"
          }`}
        >
          <div className="account_menu__user_icon"></div>
          <div className="account_menu__lineBlock">
            <span
              className={`account_menu__line ${
                (isHover || isPopupShow) && "account_menu__hover"
              }`}
            ></span>
            <span
              className={`account_menu__line ${
                (isHover || isPopupShow) && "account_menu__hover"
              }`}
            ></span>
          </div>
        </div>
      )}
    </Popover>
  );
};

export default injectIntl(AccountMenu);
