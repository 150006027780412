import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
//classDropdownLabel, label, tooltip, upBody

interface IProps {
  className?: string;
  classDropdownHead?: string;
  classDropdownLabel?: string;
  value: string;
  setValue: (value: string) => void;
  options: string[];
  label?: string;
  tooltip?: string;
  small: boolean;
  upBody?: boolean;
}

const Dropdown: React.FC<IProps> = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
}) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => setVisible(false));

  const handleClick = (value: string, index: number) => {
    setValue(value);
    setVisible(false);
  };

  return (
    <>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)} ref={ref}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}>{value}</div>
        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
          {options.map((x, index) => (
            <div
              className={cn(styles.option, {
                [styles.selectioned]: x === value,
              })}
              onClick={() => handleClick(x, index)}
              key={index}
            >
              {x}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Dropdown;
