import { injectIntl } from "react-intl";
import cn from "classnames";
import { IBaseComponentProps } from "../../types";
import styles from "./Billing.module.sass";
import Card from "../../ui-kit/components/Card";
import PurchasedPlan from "../PurchasedPlan";
import useSubscriptionDetails from "../../hooks/useSubscriptionDetails";
import useGetPlanFromSubscriptions from "../../hooks/useGetPlanFromSubscriptions";
import {
  useGetCurrentPlanQuery,
  useGetCurrentSubscriptionQuery,
  useGetPaymentTokensQuery,
} from "../../api/reg-service.api";
import useNextBilledPrice from "../../hooks/useNextBilledPrice";
import { CONTACT_EMAIl } from "../../constants";

interface IProps extends IBaseComponentProps {}

const Billing: React.FC<IProps> = () => {
  const { currentPlanFromSubscription } = useGetPlanFromSubscriptions();
  const currentPlanQuery = useGetCurrentPlanQuery();
  const currentSubscriptionQuery = useGetCurrentSubscriptionQuery();

  const { data: tokens } = useGetPaymentTokensQuery();
  const { subscription } = useSubscriptionDetails(
    currentPlanQuery,
    currentSubscriptionQuery,
    currentPlanFromSubscription
  );

  const nextBilledPrice = useNextBilledPrice(
    currentPlanQuery,
    currentSubscriptionQuery,
    tokens,
    currentPlanFromSubscription
  );
  return (
    <div className={styles.root}>
      <div className={styles.plans}>
        <Card
          classTitle={cn("title-purple")}
          title={"Pricing plan"}
          className={styles.cardWr}
        >
          {subscription &&
          currentSubscriptionQuery.data &&
          currentSubscriptionQuery.data.status !== "canceled" ? (
            <PurchasedPlan
              subscription={subscription}
              nextBilledPrice={nextBilledPrice}
            />
          ) : (
            <div className={styles.card}>
              You have no active pricing plans yet.
            </div>
          )}
        </Card>
      </div>
      <div className={styles.packages}>
        <Card
          classTitle={cn("title-purple")}
          title={"Resource packages"}
          className={styles.cardWr}
        >
          <div className={styles.card}>You have no active packages yet.</div>
        </Card>
      </div>

      <div className={styles.transactions}>
        <Card
          classTitle={cn("title-blue")}
          title={"Transaction History"}
          className={styles.cardWr}
        >
          <div className={styles.card}>
            Information will be available later. You can email{" "}
            <a href={`mailto:${CONTACT_EMAIl}`}>{CONTACT_EMAIl}</a> for
            inquiries.
          </div>
        </Card>
      </div>
    </div>
  );
};

export default injectIntl(Billing);
