import useToken from "antd/es/theme/useToken";
import {
  useGetCurrentPlanQuery,
  useGetCurrentSubscriptionQuery,
} from "../../api/reg-service.api";
// import Dashboard, { HOME_PAGE_DASHBOARD } from "../../components/Dashboard";
// import Title from "../../components/Title/Title";

import "./home.page.sass";
import DashboardNew from "../../components/DashboardNew/DashboardNew";
import Title from "../../components/Title";
import { HOME_PAGE_DASHBOARD } from "../../components/Dashboard";

const HomePage: React.FC = () => {
  const [, token] = useToken();

  const currentPlanQuery = useGetCurrentPlanQuery((() => { })(), {
    pollingInterval: 60000,
  });
  const currentSubscriptionQuery = useGetCurrentSubscriptionQuery(
    (() => { })(),
    {
      pollingInterval: 60000,
    }
  );
  return (
    <div
      style={{
        color: token.colorPrimary,
      }}
      className="home-page"
    >
      <Title className="home-page__title" text={HOME_PAGE_DASHBOARD} />
      {/* <Dashboard
        currentPlanQuery={currentPlanQuery}
        currentSubscriptionQuery={currentSubscriptionQuery}
      /> */}
      <DashboardNew currentPlanQuery={currentPlanQuery}
        currentSubscriptionQuery={currentSubscriptionQuery} />
    </div>
  );
};

export default HomePage;
