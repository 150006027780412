import { injectIntl } from "react-intl";
import { IBaseComponentProps } from "../../types";
import logo from "../../resources/images/logo.svg";
import arrowLeft from "../../resources/images/arrow-left.svg";
import "./PaymentSummary.sass";
import cn from "classnames";
import formatMessage from "../../utils/formatMessage";
import useScreenWidth from "../../hooks/useScreenWidth";
import {
  CheckoutEventsTimePeriod,
  CheckoutEventsTotals,
} from "@paddle/paddle-js";
import { TPlan } from "../../entities/plan";
import useSavingPastDueStatus from "../../hooks/useSavingPastDueStatus";

interface IProps extends IBaseComponentProps {
  currency?: string;
  cycle?: CheckoutEventsTimePeriod;
  totals?: CheckoutEventsTotals;
  onClose: VoidFunction;
  planPrice?: number;
  plan?: Partial<TPlan>; // replaced standartPlan with selectedPlan
  withoutBack?: boolean;
  isLoading?: boolean;
}

const PAYMENT_SUMMARY_TITLE = { id: "payment-summary.title" };
const PAYMENT_SUMMARY_TAX = { id: "payment-summary.tax" };
const PAYMENT_SUMMARY_DISCOUNT = { id: "payment-summary.discount" };
const PAYMENT_SUMMARY_ENTER_ADDRESS = { id: "payment-summary.enter-address" };
const PAYMENT_SUMMARY_TOTAL = { id: "payment-summary.total" };
const PAYMENT_SUMMARY_BACK = { id: "payment-summary.back" };

const PaymentSummary: React.FC<IProps> = ({
  className,
  intl,
  totals,
  cycle,
  onClose,
  withoutBack,
  currency,
  planPrice,
  plan,
  isLoading,
}) => {
  const isSmallScreen = useScreenWidth();
  const { data: status, isLoading: isSavingPastDueStatusLoading } = useSavingPastDueStatus()

  if (isLoading || isSavingPastDueStatusLoading || planPrice === undefined) return (
    <div className={cn("payment-summary", className)}>
      <div className="payment-summary__inner">
        <img className="payment-summary__logo-img" src={logo} alt="logo" />
      </div>
    </div>
  )
  if (status === "past_due" || planPrice === 0)
    return (
      <div className={cn("payment-summary", className)}>
        <div className="payment-summary__inner">
          <img className="payment-summary__logo-img" src={logo} alt="logo" />
          <div className="payment-summary__text">
            {plan && `${plan?.name} plan`}
          </div>
        </div>
      </div>
    );

  return (
    <div className={cn("payment-summary", className)}>
      <div className="payment-summary__inner">
        {!withoutBack && !isSmallScreen && (
          <div className="payment-summary__header">
            <button onClick={onClose}>
              <div className="payment-summary__logo">
                <img src={arrowLeft} alt="arrow" />
                <img
                  className="payment-summary__logo-img"
                  src={logo}
                  alt="logo"
                />
                <div className="payment-summary__logo-text">
                  {formatMessage(intl, PAYMENT_SUMMARY_BACK)}
                </div>
              </div>
            </button>
          </div>
        )}
        <div className="payment-summary__body">
          <div className="payment-summary__title">
            {formatMessage(intl, PAYMENT_SUMMARY_TITLE)}
          </div>

          <div className="payment-summary__list-row">
            <div className="payment-summary__text">
              {plan?.name && `${plan.name} plan`}
            </div>
            <div className="payment-summary__text">
              {planPrice &&
                `${totals ? totals.subtotal : planPrice || ""} ${currency || ""
                } / ${cycle?.frequency || ""} ${cycle?.interval || ""}${(cycle?.frequency || 0) > 1 ? "s" : ""
                }`}
            </div>
          </div>

          {totals?.discount || plan?.discountPercentage ? (
            <div className="payment-summary__list-row">
              <div className="payment-summary__subtext">
                {formatMessage(intl, PAYMENT_SUMMARY_DISCOUNT)}
              </div>
              <div className="payment-summary__subtext">
                {totals
                  ? `-${totals?.discount} ${currency}`
                  : formatMessage(intl, PAYMENT_SUMMARY_ENTER_ADDRESS)}
              </div>
            </div>
          ) : null}

          <div className="payment-summary__list-row">
            <div className="payment-summary__subtext">
              {formatMessage(intl, PAYMENT_SUMMARY_TAX)}
            </div>
            <div className="payment-summary__subtext">
              {/* {intent
              ? `$${convertBigNumberToNumber(intent.taxAmount, 2)}`
              : formatMessage(intl, PAYMENT_SUMMARY_ENTER_ADDRESS)} */}
              {totals
                ? `${totals?.tax} ${currency}`
                : formatMessage(intl, PAYMENT_SUMMARY_ENTER_ADDRESS)}
            </div>
          </div>

          <div className="payment-summary__line" />

          <div className="payment-summary__list-row">
            <div className="payment-summary__text">
              {formatMessage(intl, PAYMENT_SUMMARY_TOTAL)}
            </div>
            <div className="payment-summary__subtext">
              {/* {totals ? `$${totals?.total}` : "-"} */}
              {totals
                ? `${totals?.total} ${currency}`
                : formatMessage(intl, PAYMENT_SUMMARY_ENTER_ADDRESS)}
            </div>
          </div>
        </div>
        {/* {!isSmallScreen && <StripeMarks className="payment-summary__footer" />} */}
      </div>
    </div>
  );
};

export default injectIntl(PaymentSummary);
