import { useMemo } from "react";
import { additionalDataAboutPaidTariffs, beginnerCheckItems, tailoredCheckItems, TariffKeys } from "../components/PlansList";
import { TPlan } from "../entities/plan";

const useGetCheckItemsFromTariff = (selectedPlan: TPlan) => {
  const dataBySelectedTariff =
    additionalDataAboutPaidTariffs[selectedPlan?.name as TariffKeys];

  const standartCheckItems = useMemo(() => {
    const result = [
      "Solana dedicated nodes",
      `Up to ${dataBySelectedTariff?.requestsPerMonth || "..."
      } million requests per month`,
      `Up to ${dataBySelectedTariff?.requestsPerDay || "..."} ${selectedPlan?.name === "HybridN125" ? "thousand" : "million"} requests per day`,
      `Up to ${dataBySelectedTariff?.requestsPerSecond || "..."
      } requests per second`,
      `${dataBySelectedTariff?.transferIncluded || "..."
      } TB data transfer included`,
      `${dataBySelectedTariff?.webSocketsConnections || "..."
      } WebSocket connections`,
      "Heavy Requests (x1000 faster)",
      "Medium Request (x10 faster)",
      "Light Requests (x2.5 faster)",
      "Full Historical Archive Data",
      "Auto-failover redirects traffic during issues",
      {
        text: "Geyser Internal Boost",
        hint: "We do not sell Geyser as an extra service. You get even better boost when using our Hybrid solution for free.",
      },
    ];

    if (
      selectedPlan?.name === "HybridN225" ||
      selectedPlan?.name === "HybridN425"
    ) {
      return [...result, "Dedicated support on Telegram"]
    }
    if (selectedPlan?.name === "HybridN1500") {
      return [...result, "Personal manager on Telegram"]
    }

    return result;
  }, [dataBySelectedTariff, selectedPlan?.name]);


  return { beginnerCheckItems, standartCheckItems, tailoredCheckItems }
}

export default useGetCheckItemsFromTariff;