/* eslint-disable no-new-object */
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FC } from "react";
import "./ButtonGroupsSmooth.sass";
import ButtonGroupSmooth from "./ButtonGroupSmooth/ButtonGroupSmooth";
import cn from "classnames";
import useWindowWidth from "../../hooks/useWindowWidth";

export type ButtonGroupsSmoothOption = {
  title: string;
  value: string;
  image?: string;
};

export type Props = {
  className?: string;
  value: string;
  options: ButtonGroupsSmoothOption[];
  onChange: (arg0: string) => void;
  style?: React.CSSProperties;
};

type TButtonRefs = {
  [key: string]: React.RefObject<HTMLButtonElement>;
};

type TDisabledButton = {
  ref: React.RefObject<HTMLButtonElement>;
  value: string;
}

const ButtonGroupsSmooth: FC<Props> = ({
  options,
  className = "",
  value,
  onChange,
  style,
}) => {
  const [buttonRefs, setButtonRefs] = useState<TButtonRefs>({});
  const [disabledButton, setDisabledButton] =
    useState<TDisabledButton>();
  const [screenTitle, setScreenTitle] = useState<string>("");
  const parentRef = useRef<HTMLDivElement>(null);
  const screenRef = useRef<HTMLDivElement>(null);
  const width = useWindowWidth();

  useEffect(() => {
    // const isOne = (options.length > 1 && disabledButton?.value === options[0].value) ? true : false
    if (
      screenRef &&
      screenRef.current &&
      disabledButton &&
      disabledButton.ref.current &&
      parentRef &&
      parentRef.current
    ) {
      screenRef.current.className = `buttonGroupSmooth ${screenRef.current.className}`;
      screenRef.current.setAttribute(
        "style",
        `
          position: absolute;
          cursor: default;
          left: ${disabledButton.ref.current.getBoundingClientRect().left -
        parentRef.current.getBoundingClientRect().left -
        4
        // (isOne ? (-12) : 4)
        }px; 
          height: ${disabledButton.ref.current.getBoundingClientRect().height}px;
          width: ${disabledButton.ref.current.getBoundingClientRect().width}px;
           `
      );
      //  height: ${disabledButton.current.getBoundingClientRect().height}px;
      // screenRef.style.top = disabledButton.top + 100 + 'px';
    }
  }, [disabledButton, options, width]);

  const processPressedTab = (value: string) => {
    onChange(value);
  };

  const getButtonRef = useCallback(
    (title: string, ref: MutableRefObject<HTMLButtonElement>, value: string) => {
      const newButtonRefs = new Object(buttonRefs) as TButtonRefs;
      newButtonRefs[ref.current.name] = ref;
      setButtonRefs({ ...newButtonRefs } as TButtonRefs);
      if (ref.current.disabled) {
        setDisabledButton({ ref, value });
        setScreenTitle(title);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      ref={parentRef}
      className={cn({
        buttonGroupsSmooth: true,
        // buttonGroupsSmoothSeveral: options.length === 1,
        [className]: className,
      })}
      style={style}
    >
      {options.map((item: ButtonGroupsSmoothOption) => (
        <ButtonGroupSmooth
          key={item.title + item.value}
          title={item.title}
          value={item.value}
          checked={value === item.value}
          onPress={processPressedTab}
          getButtonRef={getButtonRef}
        />
      ))}
      <div
        className={cn({
          buttonGroupsSmooth_screen: true,
        })}
        ref={screenRef}
      >
        {screenTitle}
      </div>
    </div>
  );
};

export default ButtonGroupsSmooth;
